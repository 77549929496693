import * as tradingPairActionTypes from '../actionTypes/tradingPairActionTypes'

const initialState = {
  currentHPoolToken: null,
  hPoolTokensList: null,
  latestTradePrice: 0,
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case tradingPairActionTypes.SET_HPOOL_TOKENS_LIST:
      return {
        ...state,
        hPoolTokensList: payload,
      }
    case tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN:
      return {
        ...state,
        currentHPoolToken: payload,
      }
    case tradingPairActionTypes.SET_LATEST_TRADE_PRICE:
      return {
        ...state,
        latestTradePrice: payload,
      }
    default:
      return state
  }
}
