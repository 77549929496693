import Web3 from 'web3'
import networksConf from '../config/networks.json'
import { eventSetup } from './events'

export const NETWORK_SWITCH_REQUIRED = 'NETWORK_SWITCH_REQUIRED'

export const requireNetworkSwitch = (newNetId) => eventSetup.trigger(NETWORK_SWITCH_REQUIRED, newNetId)

const { networks = [] } = networksConf
const networkWithWeb3Instances = networks.map((network) => ({
  ...network,
  web3Instance: new Web3(network.rpcUrl),
}))

export const findNetworkById = (id) => {
  const network = networkWithWeb3Instances.find((network) => +network.id === id)
  return network
}
