import * as inputActionTypes from '../actionTypes/inputActionTypes'

const initialState = {
  buyPrice: '',
  buyAmount: '',
  sellPrice: '',
  sellAmount: '',
  sellTokenPrice: '',
  buyTokenPrice: '',
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case inputActionTypes.SET_INPUT:
      return {
        ...state,
        [payload.inputName]: payload.inputValue,
      }
    case inputActionTypes.CLEAR_INPUTS:
      return {
        sellTokenPrice: state.sellTokenPrice,
        buyTokenPrice: state.buyTokenPrice,
        buyPrice: '',
        buyAmount: '',
        sellPrice: '',
        sellAmount: '',
      }
    default:
      return state
  }
}
