import * as transactionActionTypes from '../actionTypes/transactionActionTypes'

const initialState = {
  openOrders: [],
  tradeHistory: false,
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case transactionActionTypes.SET_OPEN_ORDERS:
      return {
        ...state,
        openOrders: [...payload],
      }
    default:
      return state
  }
}
