const { min, max } = Math

const _min = (arr, prop) =>
  min.apply(
    Math,
    arr.map((e) => e?.[prop]),
  )

const _max = (arr, prop) =>
  max.apply(
    Math,
    arr.map((e) => e?.[prop]),
  )

export const findMinMax = (arr = [], prop, isMax = false) => (isMax ? _max(arr, prop) : _min(arr, prop))

const formatPrices = (prices, numOfDigits = 4) => prices.map((price) => +price?.toFixed(numOfDigits))

export const getCandelInfo = (arr, prop) => {
  if (!arr.length) return []
  const open = arr[0]?.[prop]
  const close = arr[arr.length - 1]?.[prop]
  const low = findMinMax(arr, prop)
  const high = findMinMax(arr, prop, true)
  return formatPrices([open, high, low, close])
}
export const getTs = (date) => new Date(date).getTime()

export interface ICondition {
  filterAt: boolean
  filterFunc: (record: any) => boolean
}

export const multiFilterArray = (array: any[], conditions: ICondition[] = []) => {
  let result = array
  conditions.forEach((conditionObj) => {
    if (conditionObj.filterAt) {
      result = result.filter(conditionObj.filterFunc)
    }
  })
  return result
}
