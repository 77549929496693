import React from 'react'

export interface IconProps {
  name?: string
  url?: string
  className?: string
  ext?: string
  folder?: string
  onClick?: () => void
  style?: React.CSSProperties
}

const Icon = ({ name, url, folder = 'svgs', ext = 'svg', ...rest }: IconProps) => {
  const [path, setPath] = React.useState('')

  React.useEffect(() => {
    if (url) return
    const importIcon = async () => {
      try {
        const { default: _path } = await import(`../../../assets/${folder}/${name}.${ext}`)
        setPath(_path)
      } catch (err) {
        console.error(err)
      }
    }
    importIcon()
  }, [name])

  const showIcon = url || path

  return <>{showIcon && <img className="one-one-ratio" src={url || path} alt=" " {...rest} />}</>
}

export default Icon
