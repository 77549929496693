import styled from 'styled-components'

// Order Box
export const WrapperOrder = styled.div`
  background: #2C203B;
  padding: 2rem 2rem 3rem 2rem;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2.4rem;
  height: 100%;
`

export const ContainerWallet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.8rem;
  background: #6C6F7C;
  padding: 1.2rem;
  border-radius: 1.2rem;
`
export const WrapperBalance = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
export const Span = styled.span`
  font-size: 1.6rem;
  font-weight: Medium;
  color: #fff;
`
export const ContainerForm = styled.div``

export const Form = styled.form``

export const InputContainer = styled.div`
  margin-bottom: 1rem;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 2.8rem;

  @media only screen and (max-width: 1200px) {
    flex: 1;
    justify-content: space-between;
  }

  & div {
    flex: 1;
  }

  @media only screen and (max-width: 350px) {
    flex-direction: column;

    & div {
      margin-right: 0;
    }
  }
`

export const WrapperActions = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-bottom: 1.8rem;
`

export const FeeLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FeeLabel = styled.p`
  font-size: 1.4rem;
  opacity: 0.6;
`

export const FeeWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Fee = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  opacity: 0.5;
`

export const FeeCoin = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.5rem;
  opacity: 0.3;
`
