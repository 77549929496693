import styled, { css } from 'styled-components'

export const Section = styled.section`
  z-index: 0;
  & .react-tabs__tab--selected {
    ${({ theme }) => css`
      color: ${theme.colors.green};
    `}
  }

  @media only screen and (max-width: 1300px) {
    grid-column: 1 / -1;
    display: flex;
    grid-gap: 2rem;
  }

  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`
// Header
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
`
