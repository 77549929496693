import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { setBalance } from 'store/actions/balanceActions'

interface IBalanceUpdater {
  account: string
  busdContract: any
  setBalance: (contract: any, balanceType: string) => void
}

const BUSD_NAME = 'busdBalance'

const BalanceUpdater = ({ account, busdContract, setBalance }: IBalanceUpdater) => {
  useEffect(() => {
    if (busdContract) {
      setBalance(busdContract, BUSD_NAME)
    }
  }, [account, busdContract])

  return <></>
}

const mapStateToProps = ({ wallet, contracts }) => ({
  account: wallet.account,
  busdContract: contracts.BUSD,
})

const mapDispatchToProps = { setBalance }

export default connect(mapStateToProps, mapDispatchToProps)(BalanceUpdater)
