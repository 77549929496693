import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleInputChange as handleInputChangeAction } from 'store/actions/inputActions'
import useCurrentStableCoin from 'hooks/useCurrentStableCoin'
import { IOrderBookData } from '../../../views/dashboard/components/blocks/Graph/IGraph'
import './index.scss'

import * as S from './styles'
import OrderBookTableBody from '../OrderBookTableBody'
import Table, { ITable } from '../Table/Table'
import { orderbookTableCols } from './cols'
import LatestTx from './LatestTx'

export type OrderBookTableProps = {
  orderBookAsks: IOrderBookData[]
  orderBookBids: IOrderBookData[]
  latestTransaction: string
  latestTransactionType: string
  actionTabProps: any
}

const OrderBookTable = ({
  latestTransaction,
  latestTransactionType,
  orderBookAsks,
  orderBookBids,
  actionTabProps,
}: OrderBookTableProps) => {
  const dispatch = useDispatch()
  const [{ symbol: stableCoinSymbol }] = useCurrentStableCoin()

  const { executedOrdersSrc, pendingOrders, pendingOrdersLoading } = useSelector((state: any) => ({
    pendingOrders: state.orderbook.pendingOrder,
    executedOrdersSrc: state.orderbook.executedOrdersSrc,
    pendingOrdersLoading: state.orderbook.loadingsState.pendingOrders,
  }))

  const appendAdditionalInfo = (record) => {
    const price = record.totalStableCoin / record.originalAmt
    const totalInStableCoin = price * record.total
    const leftAmt = record.total
    return {
      ...record,
      price,
      totalInStableCoin,
      leftAmt,
      stableCoinSymbol,
    }
  }

  const mappedBids = useMemo(() => orderBookBids.map(appendAdditionalInfo), [stableCoinSymbol, orderBookBids])
  const mappedAsks = useMemo(() => orderBookAsks.map(appendAdditionalInfo), [stableCoinSymbol, orderBookAsks])

  const handleInputChange = (...prarms) => dispatch(handleInputChangeAction(...prarms))
  const { tabs, setSelectedTab, setActionType } = actionTabProps || {}

  const handleClick = useCallback(
    (record) => {
      const tabId = record?.side === 'buy' ? 1 : 0
      handleInputChange('amount', record.leftAmt)
      handleInputChange('price', record.price)
      handleInputChange('focusedOrderType', record.side)
      setActionType(tabs[tabId].key)
      setSelectedTab(tabs[tabId])
    },
    [handleInputChange, executedOrdersSrc, pendingOrders?.length],
  )

  const sharedTableProps = {
    skeletonWrapperProps: { minHeight: '2vh', loading: pendingOrdersLoading },
    className: 'orderbook-table',
    loading: pendingOrdersLoading,
    onRecordClick: handleClick,
    defaultSortProp: 'price',
    defaultIsAsc: false,
    noDataText: '',
  }

  return (
    <S.Wrapper>
      <Table
        hideHeader={!mappedAsks?.length && !mappedBids?.length}
        dataSourse={mappedAsks}
        cols={orderbookTableCols}
        {...sharedTableProps}
      />
      <LatestTx latestTransaction={latestTransaction} latestTransactionType={latestTransactionType} />

      <Table hideHeader dataSourse={mappedBids} cols={orderbookTableCols} {...sharedTableProps} />
    </S.Wrapper>
  )
}

export default OrderBookTable
