import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { Flex, Text } from 'styles/layouts'
import { usePromise } from 'hooks/usePromise'
import { useSelector } from 'react-redux'
import { newGetContractAddress } from 'utils/contractReader'
import { IStoreState } from 'store/configureStore'
import Tooltip from 'components/Tooltip'

import './TokenBalance.scss'
import { formatNumber } from 'services/format'
import Icon from '../Icon'

const getHordLink = 'https://www.hord.fi/token'

export default function TokenBalance({
  tokenIcon = 'hord-min-icon',
  tokenName = 'Hord',
  tooltipText = 'Click to get more HORD',
}: {
  tokenIcon?: string
  tokenName?: string
  tooltipText?: string
}) {
  const network = useSelector((state: any) => state.wallet.network)
  const [getHordTokenAddress, hordTokenAddress] = usePromise(() => newGetContractAddress('HordToken'))
  const [hordBalance] = useTokenBalance({ tokenAddress: hordTokenAddress })
  const account = useSelector((state: IStoreState) => state.wallet?.account)
  const tokenText =
    hordTokenAddress && account && hordBalance > 0 ? formatNumber(hordBalance) : `Get ${tokenName.toUpperCase()}`
  // TODO: there's no deployed HordToken contract on BSC Testnet

  useEffect(() => {
    getHordTokenAddress()
  }, [network])

  return (
    <Tooltip text={tooltipText} show={!!account && !!hordBalance} className="d-flex gap-sm flex-center-x align-center">
      <a className="get-hord-wrapper" href={getHordLink} target="_blank" rel="noreferrer">
        <Flex
          fullWidth={false}
          alignItems="center"
          className={clsx('network-type-wrapper d-flex flex-center-x header-token-box --hord')}
          px="2rem"
          py="0.5rem"
          borderRadius={100}
          gap="1rem"
        >
          <Icon style={{ width: 30 }} name={tokenIcon} />
          <Text reg bold>
            {tokenText}
          </Text>
        </Flex>
      </a>
    </Tooltip>
  )
}
