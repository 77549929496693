import React from 'react'
import * as S from './styles'
import CircularProgress from '../CircularProgress'

export type ButtonProps = {
  title: string
  size?: 'Default' | 'Small' | 'Medium' | 'Large'
  darkMode?: boolean
  fullWidth?: boolean
  type?: 'submit' | 'button'
  className?: string
  click?: () => void
  loading?: boolean
  loadingText?: string
  disabled?: boolean
  style?: object
}

const Button = ({
  title = 'Button',
  size = 'Default',
  darkMode = false,
  fullWidth = false,
  className = '',
  loading = false,
  loadingText = '',
  type = 'button',
  click,
  disabled = false,
  style,
}: ButtonProps) => (
  <S.Wrapper
    type={type}
    size={size}
    darkMode={darkMode}
    fullWidth={fullWidth}
    onClick={click}
    className={className}
    disabled={disabled || loading}
    style={style}
  >
    {loading ? loadingText : title}
    {loading && <CircularProgress />}
  </S.Wrapper>
)

export default Button
