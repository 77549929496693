import React from 'react'

const HordLogoWide = ({ className }: { className?: string }) => (
  <svg
    width="110"
    height="53"
    viewBox="0 0 132 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.8361 35.6014H63.2202V29.8424H57.6813V35.6014H53.0654V20.664H57.6813V25.8831H63.2202V20.664H67.8361V35.6014ZM87.9611 33.6218C86.2994 35.0615 84.453 35.7814 82.0528 35.7814C79.6526 35.7814 77.6216 35.0615 76.1446 33.6218C74.4829 32.182 73.7443 30.3823 73.7443 28.0427C73.7443 25.7031 74.4829 23.9034 76.1446 22.4637C77.8063 21.0239 79.6526 20.304 82.0528 20.304C84.453 20.304 86.484 21.0239 87.9611 22.4637C89.6228 23.9034 90.3613 25.7031 90.3613 28.0427C90.3613 30.3823 89.6228 32.182 87.9611 33.6218ZM109.748 35.6014H104.393L102.362 30.9222H100.701V35.6014H96.0849V20.664H104.209C105.87 20.664 107.348 21.2039 108.271 22.1037C109.194 23.0036 109.748 24.2633 109.748 25.7031C109.748 26.7829 109.563 27.8627 109.009 28.5826C108.455 29.3025 107.901 29.8424 106.978 30.2023L109.748 35.6014ZM103.655 26.9629C103.724 26.9629 103.793 26.9566 103.862 26.9452H104.038C104.739 26.9452 105.307 26.3771 105.307 25.6762C105.307 24.9753 104.739 24.4071 104.038 24.4071H100.877V26.9452H103.655V26.9629ZM122.672 35.6014H115.841V20.664H122.672C125.072 20.664 127.103 21.3838 128.765 22.6436C130.242 23.9034 131.165 25.7031 131.165 28.0427C131.165 30.3823 130.427 32.182 128.765 33.4418C127.103 34.8816 125.072 35.6014 122.672 35.6014ZM122.672 31.6421C123.78 31.6421 124.703 31.2822 125.442 30.5623C126.18 29.8424 126.365 29.1225 126.365 28.0427C126.365 26.9629 125.995 26.0631 125.442 25.5231C124.888 24.8033 123.965 24.6233 122.672 24.6233H120.456V31.6421H122.672ZM81.9296 31.862C84.0335 31.862 85.7391 30.137 85.7391 28.0091C85.7391 25.8813 84.0335 24.1563 81.9296 24.1563C79.8258 24.1563 78.1202 25.8813 78.1202 28.0091C78.1202 30.137 79.8258 31.862 81.9296 31.862Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5551 47.2978C12.5551 47.2978 13.4782 48.9175 14.586 52.6969C10.3395 48.0177 4.61583 46.7579 4.61583 46.7579C9.78555 46.398 0 41.3588 0 41.3588C3.87729 40.8189 -0.369269 38.8392 3.87729 38.1193C9.41628 37.0395 2.2156 33.0802 4.61583 32.5403C8.67776 31.4604 8.67775 24.6216 10.3394 20.6622C5.90826 16.8829 2.03097 7.88435 6.0929 0.685547C8.12386 7.88435 10.5241 11.6637 16.4323 13.1035C19.5711 10.9438 23.2638 10.224 26.7718 10.9439C27.8796 10.4039 29.5413 10.9438 30.6491 11.4838C31.5723 12.0237 33.0493 12.9235 33.0493 14.1833C33.234 14.3633 33.4186 14.5433 33.6032 14.7232C37.1113 13.6434 40.25 10.9438 42.8349 6.26462C44.1273 12.0237 42.281 17.0628 37.8498 20.6622C38.9576 23.3618 39.1422 28.041 38.2191 30.7406C40.0654 32.1803 40.9885 37.5794 38.4037 38.1193C38.4037 38.4793 38.4037 38.8392 38.4037 39.0192V39.3791L38.5883 39.5591C38.9576 39.7391 39.3268 40.099 39.5115 40.4589C39.8807 40.9989 40.0654 41.7187 39.8807 42.4386C39.6961 42.9785 39.5115 43.3385 39.1422 43.6984L38.9576 43.8784V44.0583C39.5115 47.8377 40.8039 48.9175 43.2041 51.6171L43.758 52.157L43.0195 52.337C40.0654 53.0569 36.5573 52.8769 34.1571 50.7172L33.7879 50.3573L33.234 51.4371H32.1262C30.0952 51.6171 28.0642 51.2572 26.2179 50.1773C25.8486 49.9974 25.2947 49.6374 24.9255 49.4574L24.1869 48.9175L24.3716 49.9974C24.3716 50.1773 24.3716 50.3573 24.3716 50.5373L24.5562 51.2571L23.8177 50.8972C21.4174 49.9974 18.8326 48.7376 16.9863 46.7579C16.9496 46.7186 16.9475 46.7205 16.8016 46.7579C16.8016 46.7579 13.4782 47.2978 12.5551 47.2978Z"
      fill="#27282D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3574 28.9402C31.9423 28.2203 36.9274 27.6804 37.112 29.3002C36.9274 30.9199 32.1269 28.7603 29.3574 28.9402Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3564 28.9422C30.6489 28.5823 32.2583 28.3349 33.9723 28.2223C35.6545 28.1628 36.0032 28.5823 36.0032 29.3021C36.0032 29.6621 35.8377 29.8645 35.4685 29.8645C33.8068 29.6846 31.1325 28.8277 29.3564 28.9422Z"
      fill="#FF9D9C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.296 27.4999C26.0334 25.8802 22.71 26.4201 14.0322 27.14C23.4485 25.1603 31.0185 24.9803 34.8958 25.5202C34.7111 19.0413 32.4955 15.8018 29.1721 13.4622C33.4187 15.4419 37.1114 19.5812 37.296 27.4999Z"
      fill="#8D6752"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.635 24.0819C31.7577 23.542 29.1729 23.722 26.2188 24.0819C29.5421 22.4622 33.6041 22.2822 35.635 22.4622V24.0819Z"
      fill="#8D6752"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8476 32.7199C17.9095 33.7997 21.9715 32.3599 26.218 28.7605C22.3407 26.6009 17.3556 27.5007 11.0781 31.4601C14.0323 31.82 17.171 31.1002 20.1251 29.3004C18.2788 31.2801 16.2478 32.5399 13.8476 32.7199Z"
      fill="#A84337"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8341 35.239C22.1575 38.8384 23.8191 37.7586 23.8191 31.8196C22.5267 33.6193 20.865 34.6991 18.6494 35.239H18.8341Z"
      fill="#A84337"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3718 33.9793C9.23302 35.4191 7.01742 39.9183 4.61719 42.2579C7.38668 44.7775 11.4486 45.4974 15.1413 45.3174C14.5874 41.718 14.5874 37.9387 15.3259 34.5192C13.2949 36.3189 11.264 39.5584 11.4486 42.4379C10.1562 39.9183 10.8947 36.3189 12.3718 33.9793Z"
      fill="#A84337"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2782 14.7239C19.2013 14.9039 20.3091 14.7239 21.4169 14.9039C22.1555 14.9039 22.7094 15.0839 23.4479 15.0839C26.5867 15.9837 27.8791 19.5831 25.2942 21.7428C24.5557 22.4626 23.6325 22.8226 22.5247 23.0025C21.4169 23.3625 20.1245 23.3625 18.8321 23.1825C14.7701 22.6426 11.2621 19.5831 8.86188 16.3437C7.56944 14.544 6.64628 12.3843 6.09238 10.0447C5.53848 7.88506 5.53848 6.26532 5.90775 4.28564C6.64628 6.26532 7.20017 7.88505 8.4926 9.50478C10.8928 12.5643 14.2162 14.184 18.2782 14.7239Z"
      fill="#A7A2A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0791 15.9821C37.8486 14.9023 40.0642 13.2826 41.9105 10.583C41.7259 13.6425 40.4335 16.522 37.8486 18.5017C37.664 18.6817 37.2947 18.8616 37.1101 19.0416C36.5562 17.9618 36.0023 17.0619 35.0791 15.9821Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0791 15.9821C37.8486 14.9023 40.0642 13.2826 41.9105 10.583C40.8027 13.2826 38.6841 15.6777 36.0992 17.2974C35.7839 16.8688 35.5682 16.5736 35.0791 15.9821Z"
      fill="#A7A2A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5244 11.6635C17.5405 17.4226 18.279 13.4633 22.1563 19.4023C16.4327 15.6229 14.0324 19.7622 10.5244 11.6635Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7856 21.0221C24.3705 21.0221 26.5861 18.5026 26.5861 18.5026C28.0632 23.9017 20.6778 24.6216 17.5391 24.4416C20.1239 23.7217 21.601 22.6419 21.7856 21.0221Z"
      fill="#A7A2A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1096 17.4226C23.6325 17.4226 22.3401 16.7028 21.6016 15.8029C26.5867 13.2833 28.8022 17.4226 27.3252 20.3022C26.2174 18.1425 25.2942 18.1425 23.0786 18.6824C23.6325 18.3225 25.1096 17.4226 25.1096 17.4226Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1865 27.3433C36.9358 27.4478 37.2965 27.5006 37.2965 27.5006C37.2965 22.8214 35.8194 18.862 32.6807 15.8025C35.0809 19.222 36.3733 23.1813 36.004 27.3206L36.1865 27.3433Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.90672 4.46478C7.75305 19.2223 17.5386 19.2224 21.7852 19.9422C23.8161 20.3022 23.2622 23.3617 20.1235 23.3617C11.815 23.1817 4.24502 12.7434 5.90672 4.46478Z"
      fill="#979399"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.0941 12.7428C31.3865 13.4627 32.1251 14.3625 31.7558 14.7225C31.3865 15.2624 30.0941 15.0824 28.8017 14.3625C27.5092 13.6426 26.7707 12.7428 27.14 12.3828C27.3246 12.0229 28.8017 12.2029 30.0941 12.7428Z"
      fill="#8D6752"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.0176 13.8219C30.833 14.0019 30.0944 14.0019 29.5405 13.6419C28.802 13.282 28.4327 12.922 28.6174 12.5621C29.0856 12.1057 31.379 13.1174 31.0176 13.8219Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7578 43.3392C33.4195 42.6193 35.2658 42.2594 37.2968 42.7993C39.1431 43.1592 39.1431 40.9996 37.6661 40.9996C36.189 40.8196 33.7888 41.5395 31.7578 43.3392Z"
      fill="#FF9D9C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4955 41.3597C32.4955 41.1797 32.1262 40.9998 31.5723 40.8198C32.3109 40.8198 33.234 40.8198 33.9726 40.9998C33.4187 41.5397 32.4955 41.8996 31.3877 42.2596C31.757 41.8996 32.1262 41.5397 32.4955 41.3597Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2342 37.9392C34.8959 37.5792 36.1883 37.5792 36.9268 38.1191C36.9268 38.839 35.819 39.3789 33.6034 39.019C34.1573 38.659 34.7112 38.4791 35.2651 38.4791C33.7881 38.1191 31.3878 38.659 28.0645 40.2787C31.5725 37.5792 32.4956 37.0393 34.1573 37.3993C33.7881 37.5792 33.4188 37.7592 33.2342 37.9392Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5117 40.8192C27.7811 40.5039 27.9895 40.3377 28.2688 40.1445C31.6241 38.4796 33.9739 38.1197 35.2663 38.4796C34.7124 38.4796 34.1585 38.6595 33.6046 39.0195C35.6356 39.3794 36.928 38.8395 36.928 38.1196C37.4819 38.4796 37.4819 39.1995 36.928 39.9193C37.1126 39.5594 36.928 39.3794 36.928 39.0195C36.7434 40.0993 35.6356 40.2793 33.2353 39.7394C33.6046 39.1995 33.9739 39.1995 34.5278 39.3794C33.9739 38.6596 32.6814 39.0195 30.6505 40.6392C30.8351 40.2793 31.0197 39.9194 31.2044 39.7394C30.2812 40.4593 28.9888 40.6392 27.5117 40.8192Z"
      fill="#EB453F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.188 43.6975C36.7419 43.8774 37.037 43.9299 37.4805 44.0574C37.8497 47.2969 39.1421 49.8165 41.3577 51.4362C37.6651 51.6162 35.2648 50.1764 34.1571 47.4769C33.4185 48.0168 32.8646 48.7366 32.4954 49.9964C27.5103 50.1764 24.3715 47.6568 22.7098 42.2577C22.5252 44.9573 22.5252 47.1169 23.0791 48.5567C16.4323 45.8571 16.8015 38.8383 16.8015 35.5988C20.3096 38.8383 24.7408 41.1779 27.5103 41.8978C28.0642 44.4174 28.4334 45.4972 28.4334 45.4972C29.5412 44.5973 29.9105 44.0574 29.9105 44.0574C31.3876 44.4174 32.8646 44.4174 34.3417 44.2374C34.7109 45.1372 35.2649 45.8571 35.8188 46.397C35.8188 45.3172 35.8187 44.4174 36.188 43.6975Z"
      fill="#EB453F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4738 42.8306C39.1355 43.0164 39.1355 40.9728 37.6584 40.9728C37.4738 40.9728 37.2891 40.9728 37.1045 40.9728C38.2123 41.1586 38.3969 42.4591 37.4738 42.8306Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3582 51.4377C38.2194 50.5378 36.5577 48.0183 36.1885 43.699C36.7424 43.8789 36.9037 43.9081 37.4809 44.0589C37.8502 47.1184 39.1426 49.638 41.3582 51.4377Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4656 32.7198C34.5276 28.7604 39.5127 32.7198 38.5895 36.3192C38.4049 36.8591 36.7432 35.4193 35.82 36.1392C33.789 37.579 33.789 34.8794 35.0815 34.8794C34.1583 34.5195 33.6044 35.4193 33.2351 36.1392C32.8659 36.8591 32.1273 37.0391 31.5734 36.1392C31.2042 35.4193 31.9427 33.2597 33.2351 32.8998C31.2042 32.7198 29.9118 35.0594 29.9118 36.6791C28.2501 35.2394 28.2501 34.1596 28.4347 33.0797C27.3269 33.6196 27.3269 36.3192 27.6961 37.399C26.5883 37.219 25.6652 35.5993 25.6652 33.9796C25.4806 30.3802 28.9886 28.4005 32.4966 30.3802C31.3888 30.9201 30.6503 31.8199 30.4656 32.7198Z"
      fill="#FF9D9C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.8203 31.3895C37.8513 32.2701 39.1437 34.2073 38.5898 36.3207C38.5898 36.4968 38.2205 36.4968 37.8513 36.3207C37.6666 34.5596 37.1127 32.7984 35.8203 31.3895Z"
      fill="#FEFEFE"
    />
  </svg>
)

export default HordLogoWide
