// eslint-disable-next-line import/no-anonymous-default-export
export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '0.7rem',
    btnRadius: '1.2rem'
  },

  font: {
    family:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',Work Sans , Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    thicker: 500,
    bold: 600,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.5rem',
      reg: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  icon: {
    sizes: {
      xsmall: '0.3rem',
      small: '1.4rem',
      medium: '1.5rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  colors: {
    primary: '#7D4EE0',
    secondary: '#926CE3',
    green: '#16C79A',
    greeHover: '#44D8B2',
    red: '#FF5353',
    mainBg: '#0F0B14',
    white: '#FAFAFA',
    black: '#030517',
    lightGray: '#EBEEF4',
    walletBg: '#0d0d0d',
    gray: '#2E303C',
    inputBorder: '#ffffff10',
    darkGray: '#212227',
    none: 'none',
    chartBg: '#1E1628',
  },
  spacings: {
    xxxsmall: '0.5rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
    one: '1rem'
  },
  padding: {
    xxsmall: '0.1rem',
    xsmall: '0.2rem',
    small: '0.3rem',
    medium: '0.4rem',
    large: '0.5rem',
    xlarge: '0.6rem',
    xxlarge: '0.7rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
}
