import configJson from 'config/config.json'
import { handleResponse } from './fetch'

export const getLatestValidatorStats = () => {
  const config = {
    method: 'GET',
    headers: { Authorization: `Bearer GUEST` },
  }

  return fetch(`${configJson.apiPath}/validators/stats/latest`, config)
    .then(handleResponse)
    .then((res: any) => res?.stats || {})
}