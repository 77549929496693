export const LS_ACCOUNT = '555cg03294173ace0a7d925d8rc9921513e333ad'
export const CH_ACCOUNT = '285cg03294173ace907d925d8rc99215139299kl'

export const MESSAGE_BOX_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
}

export const MAX_SAFE_VIEWED_DECIMALS = 14

export const EMPTY_BYTE_ARRAY = '0x00000000000000000000000000000000'
export const EMPTY_HEX = '0000000000000000000000000000000000000000000000000000000000000000'

export const HORD_SYMBOL = 'HORD'

export const twitterLink = 'https://twitter.com/HordApp'
export const telegramLink = 'https://t.me/hordnews'
export const mediumLink = 'https://medium.com/hord-app'
export const hordAppLink = 'https://www.hord.app/'
export const docsLink = 'https://docs.hord.app/'

// export const FOOTER_LINKS = [
//   { name: 'Hord.app', link: hordAppLink },
//   { name: 'Telegram', link: telegramLink },
//   { name: 'Twitter', link: twitterLink },
//   { name: 'Medium', link: mediumLink },
//   { name: 'Docs', link: docsLink },
// ]

export const FOOTER_LINKS = [{ name: 'About Hord', link: 'https://hord.app/about' }]

export const FOOTER_TERMS_POLICY = [
  { name: 'common.terms', link: '' },
  { name: 'common.policy', link: '' },
]
