import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { updateNotifications } from '../../../store/actions/uiActions'
import './Notifications.scss'
// import closeIcon from '../../../images/notifications/x.png';

type NotificationListData = {
  id: number
  title: string
  description: string
  icon: string
  deleteNotificationInMs: number
  type: string
}

type NotificationsProps = {
  notificationsList: NotificationListData[]
  position: string
  updateNotifications: Function
  autoDelete?: boolean
  autoDeleteTime?: number
}

const Notifications = ({ notificationsList, position, updateNotifications }: NotificationsProps) => {
  const deleteToast = useCallback(
    (id) => {
      const updatedOfNotificationsList = notificationsList.filter((notification) => notification.id !== id)

      updateNotifications(updatedOfNotificationsList)
    },
    [notificationsList, updateNotifications],
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (notificationsList.length > 0) {
        deleteToast(notificationsList[0].id)
      }
    }, notificationsList[0]?.deleteNotificationInMs)

    return () => {
      clearInterval(interval)
    }
  }, [notificationsList, deleteToast])

  return (
    <div className={`notification-container ${position}`}>
      {notificationsList.map((toast) => (
        <div key={toast.id} className={`notification ${toast.type} toast ${position}`}>
          <div>
            <p className="notification-title">{toast.title}</p>
            <p className="notification-message">{toast.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => ({
  notificationsList: state.ui.notificationsList,
})

export default connect(mapStateToProps, { updateNotifications })(Notifications)
