import React from 'react'
import { ThemeProvider } from 'styled-components'
import Footer from 'views/dashboard/components/blocks/Footer/Footer'
import { setupWeb3, setupWssWeb3Provider } from 'utils/web3Service'
import { Flex } from 'styles/layouts'
import Header from 'components/general/Header/Header'
import GlobalStyles from './styles/global'
import theme from './styles/theme'
import Dashboard from './views/dashboard/components'
import Notifications from './components/general/Notifications'
import * as S from './views/landing/styles'

setupWssWeb3Provider()
setupWeb3()

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <S.Page>
        <Flex>
          <Header />
          <Dashboard blockchainApi="BSC" />
        </Flex>
        <Notifications position="top-right" autoDelete autoDeleteTime={2000} />
        <Footer />
      </S.Page>
    </ThemeProvider>
  )
}
