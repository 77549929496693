import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Flex, Text } from 'styles/layouts'
import './index.scss'

interface IPairTab {
  onTabClick: (id: number) => void
  id: number
  label: string
  isActive: boolean
}

const PairTab = ({ onTabClick, id, label, isActive }: IPairTab) => (
  <Flex
    borderRadius={8}
    className={clsx('pair-tab', isActive ? 'pair-tab-active' : 'pair-tab-inactive')}
    justifyContent="center"
    alignItems="center"
    onClick={() => onTabClick(id)}
  >
    <Text className="pair-tab-text" reg>
      {label}
    </Text>
  </Flex>
)

// TODO: add TS type for actionTabProps
const PairFilter = ({ actionTabProps }: { actionTabProps: any }) => {
  const { tabs, setSelectedTab, selectedTab, setActionType } = actionTabProps || {}

  const handleTabClick = useCallback(
    (id) => {
      const tab = tabs[id]
      setSelectedTab(tab)
      setActionType(tab?.key)
    },
    [tabs, selectedTab],
  )

  return (
    <Flex mb="3.4rem" className="pairs-filter" gap="0rem" fullWidth={false} borderRadius={8}>
      {tabs &&
        tabs.map((tab) => (
          <PairTab
            key={tab.id}
            onTabClick={handleTabClick}
            id={tab.id}
            label={tab.label}
            isActive={selectedTab.id === tab.id}
          />
        ))}
    </Flex>
  )
}

export default PairFilter
