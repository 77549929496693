import Web3 from 'web3'

export const parseParamsInput = (signature = '') => signature.substring(10, signature.length)
export const parseMathodInput = (signature = '') => signature.substring(0, 10)

export const defaultWeb3Instance = new Web3()

export const parseInputsByAbi = async (Abi = [], input) => {
  const values = await defaultWeb3Instance.eth.abi.decodeLog(Abi, input, [])
  return values
}

export const parseInputs = async (Abi, input) => {
  try {
    const paramsInput = parseParamsInput(input)
    const values = await parseInputsByAbi(Abi, paramsInput)
    return values
  } catch (error) {
    console.error()
  }
}
