// import HordTokenIcon from '../components/Common/Icons/HordTokenIcon';

const HORD_TOKEN_ADDRESS = ''

export const APPROVE_TYPES = {
  STAKING: 'Staking',
}

export const DEFAULT_ASSET = {
  symbol: '?',
  label: '',
  decimals: 18,
  address: '0x0',
  icon: () => {},
}

export const SINGLE_ASSETS = [
  {
    ...DEFAULT_ASSET,
    symbol: 'ETH',
    label: 'ETH',
  },
  {
    ...DEFAULT_ASSET,
    symbol: 'HORD',
    label: 'HORD',
    // icon: HordTokenIcon,
    address: HORD_TOKEN_ADDRESS,
  },
]

export const ASSETS = [...SINGLE_ASSETS]

export const nullAddress = '0x0000000000000000000000000000000000000000'
