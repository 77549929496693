import React from 'react'

const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M1 13.13V7.16459C1 6.58752 1.26642 6.04278 1.72193 5.6885L6.60193 1.89294C7.27721 1.36773 8.22279 1.36773 8.89807 1.89294L13.7781 5.6885C14.2336 6.04278 14.5 6.58752 14.5 7.16459V13.13C14.5 14.1628 13.6628 15 12.63 15H10.5C10.2239 15 10 14.7761 10 14.5V11.8987C10 11.6355 9.95695 11.3693 9.81766 11.146C9.63545 10.8539 9.39228 10.6023 9.10303 10.4095C8.70253 10.1425 8.23167 10 7.75 10C7.26833 10 6.79747 10.1425 6.39697 10.4095C6.10772 10.6023 5.86455 10.8539 5.68234 11.146C5.54305 11.3693 5.5 11.6355 5.5 11.8987V14.5C5.5 14.7761 5.27614 15 5 15H2.87C1.83723 15 1 14.1628 1 13.13Z"
      stroke="#928F9C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HomeIcon
