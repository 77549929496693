export const getTimeLeft = (contractTimeLeft = 0) => {
  const now: number = +(Date.now() / 1000).toFixed(0)
  return contractTimeLeft - now
}

export const wait = (ms = 150): Promise<any> =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(null), ms)
  })
export interface ITimeStage {
  date: Date
  ts: number
  formattedDate: string
}

const handleMobileView = (length: number) => {
  // for mobile view, there's no much space so number of stages will restricted by max viewed (6)
  const maxViewed = 6
  const isMobileView = window.innerWidth < 1000
  return isMobileView && length > maxViewed ? maxViewed : length
}

export const getDateTime = (_date, hideMins = true) => {
  const date = new Date(_date)
  let [timeString] = date.toTimeString().split(' ')
  // eslint-disable-next-line prefer-const
  let [hour, mins] = timeString.split(':')

  // if (+mins > 30) hour = (+hour + 1).toString()

  timeString = [hour, mins].join(':')
  return timeString
}

const removeDuplicateDateTitlte = (elems: ITimeStage[]): ITimeStage[] => {
  const seenStrings = []
  const diffToRemoveDuplicates = 10 * 60 * 1000 // in case of two adjacent stages have very low ts diff (10 min push)
  const filteredByTs = elems.filter((elem, index) => {
    if (elems?.[index + 1] && elems?.[index + 1].ts - elems?.[index].ts > 1000) return true
    return false
  })

  return filteredByTs.map((elem, index: number) => {
    if (!!index && seenStrings.includes(elem.formattedDate))
      return {
        ...elem,
        formattedDate: getDateTime(elem.ts + diffToRemoveDuplicates, true),
      }
    seenStrings.push(elem.formattedDate)
    return elem
  })
}

const mapTimeStages = (index: number, timeDiff, now, dateFormatMethod) => {
  const diff = timeDiff * 1000 * index

  return {
    date: new Date(now - diff),
    ts: new Date(now - diff).getTime(),
    formattedDate: dateFormatMethod(new Date(now - diff)),
  }
}

const TimeStage = (dateTs: number, dateFormatMethod) => ({
  date: new Date(dateTs),
  ts: new Date(dateTs).getTime(),
  formattedDate: dateFormatMethod(new Date(dateTs)),
})

const handleFreshCreatedPoolDates = (
  createdStages: ITimeStage[],
  creationDateTs: number,
  genisisStage: ITimeStage,
  dateFormatMethod,
): ITimeStage[] => {
  let result = createdStages
  result = result.filter((stage) => stage.ts > creationDateTs) // clear any stage that has date before the creation date
  if (result.length > 5) return result

  result = [genisisStage, ...result] // append creation date stage in result stages are less than the expected length
  const lastElem = result?.[result.length - 1]
  const firstElem = result?.[0]

  const totalStagesLength = 5
  const timeFiller = Math.floor((lastElem.ts - firstElem.ts) / totalStagesLength) // fill all left stage with new dates (using previous stage level (if selected is 3M use 1D timeRange) so the max would be the creation date or any latest available date )
  const fillers = new Array(totalStagesLength)
    .fill(null)
    .map((stage, index) => TimeStage(creationDateTs + (timeFiller * index + 1), dateFormatMethod))

  result = [...result, ...fillers].sort((stageA, stageB) => stageA.ts - stageB.ts)
  return result
}

const fromDateToTimeStage = (dateInTs: number, dateFormatMethod) => ({
  date: new Date(dateInTs),
  ts: dateInTs,
  formattedDate: dateFormatMethod(new Date(dateInTs)),
})

export const createTimeStages = ({ now, creationDateTs, timeDiff, dateFormatMethod, length: numOfStages = 5 }) => {
  // const renderedStagesLength = handleMobileView(length)
  // const poolCreationStage = TimeStage(creationDateTs, dateFormatMethod)
  // let timeStages =
  //   new Array(renderedStagesLength)
  //     .fill(null)
  //     .map((timeStage, index) => mapTimeStages(index, timeDiff, now, dateFormatMethod))
  //     .reverse() || []
  // timeStages = handleFreshCreatedPoolDates(timeStages, creationDateTs, poolCreationStage, dateFormatMethod)
  // timeStages = removeDuplicateDateTitlte(timeStages)
  // return timeStages
  const renderedStagesLength = handleMobileView(numOfStages)
  const poolCreationStage = TimeStage(creationDateTs, dateFormatMethod)
  const newN = new Date(now)
  now += timeDiff * 1000
  const lastStageTime = now
  const firstStageTime = now - timeDiff * numOfStages

  const avgDiffBetweenStages = ((lastStageTime - firstStageTime) / renderedStagesLength) * 1000

  let newTimeStages: any = new Array(renderedStagesLength + 1)
    .fill(null)
    .map((el, index) => new Date(now - avgDiffBetweenStages * index))
    .reverse()

  newTimeStages = newTimeStages.map((date) => fromDateToTimeStage(date, dateFormatMethod))
  newTimeStages = handleFreshCreatedPoolDates(newTimeStages, creationDateTs, poolCreationStage, dateFormatMethod)
  newTimeStages = removeDuplicateDateTitlte(newTimeStages)

  return newTimeStages
}
const getDayMonthString = (_date: number | string) => {
  let dayMonthYearString: any = new Date(_date).toDateString().split(' ')
  dayMonthYearString.shift() // remove week day
  dayMonthYearString.pop() // remove year
  dayMonthYearString = dayMonthYearString.join(' ')
  return dayMonthYearString
}

export const getDayAndMonth = (_date) => {
  const dayMonthYearString: string = getDayMonthString(_date)
  return dayMonthYearString
}
