import React from 'react'

const HordPremium = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path
      d="M3.20015 1.85982L1.51546 3.88144C1.21453 4.24256 1.20574 4.76452 1.49433 5.13557L7.21065 12.4851C7.61101 12.9999 8.38899 12.9999 8.78935 12.4851L14.5057 5.13557C14.7943 4.76452 14.7855 4.24256 14.4845 3.88145L12.7998 1.85982C12.6099 1.63182 12.3284 1.5 12.0316 1.5H3.96837C3.6716 1.5 3.39015 1.63182 3.20015 1.85982Z"
      stroke="#85818B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HordPremium
