/* eslint-disable no-debugger */
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { wait } from 'utils/utilsService'

/**
 *  const promiseFunc = () => new Promise((resolve,reject) => resolove({ count : 1 }))
 *
 *  const [
 *  getData: fucntion, // initiate the request by calling this func.
 *  data: any,    // the resolved promise data => { count : 1 }
 *  loading: boolean,  // promise resolving loading
 *  error: Error,  // error object in case promise rejected
 * ] = usePromise(promiseFunction)
 *
 */

const DEFAULT_ERROR = 'Internal Server Error'

const sleep = (ms = 10) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(null)
    }, ms),
  )

interface IBaseConfig {
  initReq?: boolean
  defaultRes?: any
  showError?: boolean
  showSuccess?: string
  sleep?: number
  maxRetries?: number
  expectedMaxWaitingTime?: number // in ms
  onSuccess?: (res?: any) => void
  onError?: (err?: any) => void
}

export const usePromise = (promiseFunction = (..._params: any[]) => null, baseConfig: IBaseConfig = {}) => {
  const dispatch = useDispatch()
  const [hookState, setHookState] = useState({
    response: baseConfig?.defaultRes,
    loading: false,
    error: '',
    paramsArray: [],
    requestRetries: 0,
    executionTime: Date.now(),
    hadRetried: false,
  })
  // const canCheckTimeout = !!timerSeconds && timerSeconds % (baseConfig.expectedMaxWaitingTime / 1000) === 0

  const updateHookState = (newState = {}) => {
    setHookState({ ...hookState, ...newState })
  }

  const updateResponse = (res) => updateHookState({ response: res })

  // const alertError = (errorMsg: string) => dispatch(createNotification('error', errorMsg))
  // const showSuccessMsg = () => dispatch(createNotification('success', translate(baseConfig.showSuccess), 4000))

  function executePromise(...params) {
    updateHookState({
      loading: true,
      paramsArray: [...params],
      executionTime: Date.now(),
      response: baseConfig?.defaultRes,
    })
    return new Promise((resolve, reject) => {
      try {
        return promiseFunction(...(params as []))
          .then(async (value) => {
            updateHookState({ response: value, loading: false })
            // if (baseConfig.showSuccess) showSuccessMsg()
            resolve(value)
          })
          .catch((err) => {
            const errorMessage = err?.error?.message || err?.message || err || DEFAULT_ERROR
            // if (baseConfig.showError) alertError(errorMessage)

            updateHookState({ error: err, loading: false })
            reject(err)
          })
      } catch (error) {
        updateHookState({ error, loading: false })
        reject(error)
      }
    })
  }

  const retry = async () => {
    try {
      await executePromise(...hookState.paramsArray)
    } catch (error) {
      console.log()
    } finally {
      await wait(1000)
      updateHookState({ requestRetries: hookState.requestRetries + 1 })
    }
  }

  // RETRY REQUEST IN CASE OF FAILED REQUESTS
  useEffect(() => {
    const { requestRetries, loading, error } = hookState
    if (!error || requestRetries >= baseConfig.maxRetries || !baseConfig.maxRetries || loading) return
    retry()
  }, [hookState.error, hookState.requestRetries])

  // RETRY REQUEST IN CASE OF TIMEOUT REQUESTS (CONTRACTS SPECIFIC)
  // useEffect(() => {
  //   const { loading, response, hadRetried } = hookState
  //   if (!canCheckTimeout || !loading || !!response || hadRetried) return // check the execution every (3s) which is the expectedMaxWaitingTime
  //   executePromise(...hookState.paramsArray).then(() => updateHookState({ hadRetried: true })) // in case if request still loading that means it's going to be timeout request => trigger retry
  // }, [canCheckTimeout])

  useEffect(() => {
    if (baseConfig.initReq) executePromise()
  }, [])

  const { response, loading, error } = hookState
  return [executePromise, response, loading, error, updateResponse]
}
