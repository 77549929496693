import styled, { css } from 'styled-components'

import { DropdownProps } from '.'

export const Wrapper = styled.div`
  width: fit-content;
  position: relative;
  z-index: 10;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const Title = styled.span`
  font-size: 1.8rem;
  margin-right: 0.7rem;
`

export const WrapperImage = styled.div<Partial<DropdownProps>>`
  ${({ bg }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    width: 2rem;
    height: 2rem;
    border: 0.380952px solid rgba(255, 255, 255, 0.1);
    border-radius: 57px;
    background: ${bg};

    :hover {
      opacity: 0.8;
    }
  `}
`

export const Image = styled.img<Partial<DropdownProps>>`
  ${({ active }) => css`
    transform: ${active ? 'rotate(180deg)' : 'rotate(0deg)'};
  `}
  width: 0.8rem;
`

// Dropdown Content
export const WrapperContent = styled.div`
  position: absolute;
  background-color: #926CE3;
  color: white;
  border-radius: 2.4rem;
  padding: 1.8rem 2.2rem;
  max-height: 35rem;
  overflow-y: auto;
  width: 40rem;
  top: 35px;
  left: 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  z-index: 100;

  @media only screen and (max-width: 550px) {
    left: unset;
    right: 0;
  }

  @media only screen and (max-width: 375px) {
    width: 24rem;
  }
`
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
