import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { LangProvider } from '@dcentralab/unified-frontend'
import { Provider } from 'react-redux'
import langManager from 'services/translate/lang-manager'
import App from './App'
import reportWebVitals from './reportWebVitals'
import configureStore from './store/configureStore'

langManager.init()

const locales: any = {
  en: () => import('./services/translate/en.json'),
}

export const store = configureStore()

ReactDOM.render(
  <LangProvider locales={locales}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </LangProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
