import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { handleInputChange } from 'store/actions/inputActions'
import { connect, useSelector } from 'react-redux'
import { makeBuyOrder, makeSellOrder } from 'store/actions/orderbookActions'
import { newDisplayFloats } from 'services/format'
import { Flex, Text } from 'styles/layouts'
import { getValueByMultiProps } from 'utils/contractReader'
import useCurrentStableCoin from '../../../hooks/useCurrentStableCoin'
import { useTokenBalance } from '../../../hooks/useTokenBalance'
import Button from '../Button'
import Icon from '../Icon'
import Input from '../Input'
import { MarketOrderActionProps } from './MarketOrderActionProps'
import Skeleton from '../Skeleton/Skeleton'
import PairFilter from './ActionTabs'
import PercentageTabs, { percentTabs } from './ActionTabs/PercentageTabs'
import * as S from './styles'

const FEE_PERCENT = 0.025

const MarketOrderAction = ({
  amount: passedAmt,
  price: passedPrice,
  handleInputChange,
  account,
  buyLoading,
  sellLoading,
  orderType,
  hpoolStats,
  busdBalance: _stableCoinBalance,
  currentHPoolTokenBalance: _currentHPoolTokenBalance,
  currentHPoolTokenName,
  currentHPoolTokenSymbol,
  makeSellOrder,
  makeBuyOrder,
  actionTabProps,
}: MarketOrderActionProps) => {
  const { currentHPoolTokenContract, ethPrice, latestTradePrice } = useSelector((state: any) => ({
    currentHPoolTokenContract: state.tradingPair?.currentHPoolToken || {},
    ethPrice: state.ethStaking.hethInfo?.latestHethStat?.eth_price_usd,
    latestTradePrice: state.tradingPair?.latestTradePrice,
  }))

  const [{ symbol: stableCoinSymbol, address: stableCoinAddress = '' }] = useCurrentStableCoin()
  const { actionType: type } = actionTabProps || {}
  const [selectedTab, setSelectedTab] = useState(null)
  const isBuy = type === 'Buy'
  const [stableCoinBalance, gettingStableBalance] = useTokenBalance({
    tokenAddress: stableCoinAddress,
    disabled: !isBuy,
  })
  const [currentHPoolTokenBalance, gettingPoolTokenBalance] = useTokenBalance({
    tokenAddress: getValueByMultiProps(currentHPoolTokenContract, ['address', 'contract_address']),
    disabled: isBuy,
  })

  const avail = isBuy ? stableCoinBalance : currentHPoolTokenBalance || 0
  const max = isBuy ? stableCoinBalance : currentHPoolTokenBalance || 0

  passedAmt = passedAmt >= max ? max : passedAmt

  const [price, setPrice] = useState(0)
  const [amount, setAmount] = useState(0)

  const clearInputs = () => {
    setSelectedTab(null)
    setPrice(0)
    setAmount(0)
  }

  const _busdFee = FEE_PERCENT * price * amount

  const handleTabClick = useCallback(
    (id) => {
      if (selectedTab?.id === id) return setSelectedTab(null)
      setSelectedTab(percentTabs[id])
    },
    [selectedTab?.id],
  )

  // const numHasOneDecimal = (num = 0) =>
  //   num
  //     .toString()
  //     .split('')
  //     .filter((char) => char === '.').length === 1

  // const updateAmt = (amt = 0) => {
  //   // const decimalsRestricted = +newDisplayFloats(amt, 4)
  //   // const stringfidAmt = amt.toString()
  //   // const hasOneDotAtLastChar = stringfidAmt.charAt(stringfidAmt.length - 1) === '.' && numHasOneDecimal(amt)
  //   setAmount(amt)
  // }

  // For updating inputs when user clicks on percentage tabs
  useEffect(() => {
    if (!selectedTab || !price) return
    const tokenBalance = isBuy ? stableCoinBalance : currentHPoolTokenBalance
    const fillAmount = newDisplayFloats((tokenBalance / price) * selectedTab?.value, 8)

    setAmount(fillAmount)
  }, [price, stableCoinBalance, selectedTab, currentHPoolTokenBalance, isBuy])

  useEffect(() => {
    setAmount(passedAmt || 0)
  }, [passedAmt])

  useEffect(() => {
    setPrice(passedPrice || 0)
  }, [passedPrice])

  const { price24Hr } = useMemo(
    () => ({
      price24Hr: newDisplayFloats(latestTradePrice),
    }),
    [latestTradePrice],
  )

  useEffect(() => {
    handleInputChange('buyTokenPrice', price24Hr)
    handleInputChange('sellTokenPrice', price24Hr)
    setPrice(price24Hr)
  }, [price24Hr])

  const startTransaction = async () => {
    if (isBuy) {
      return makeBuyOrder(price, amount).then(() => clearInputs())
    }
    makeSellOrder(price, amount, _busdFee).then(() => clearInputs())
  }

  return (
    <S.WrapperOrder>
      <S.ContainerWallet>
        <S.WrapperBalance>
          <Flex gap="0.6rem">
            <Icon name={isBuy ? 'WalletGreen' : 'WalletRed'} />
            <span style={{ opacity: '0.6' }}>Available balance</span>
          </Flex>
          <Skeleton minHeight="2vh" loading={isBuy ? gettingStableBalance : gettingPoolTokenBalance}>
            <Flex gap="0.6rem" alignItems="center">
              <S.Span>{newDisplayFloats(avail, 4)} </S.Span>
              <Text reg opacity={0.7}>
                {isBuy ? stableCoinSymbol : currentHPoolTokenName || 'HPool token'}
              </Text>
            </Flex>
          </Skeleton>
        </S.WrapperBalance>
      </S.ContainerWallet>
      <PairFilter actionTabProps={actionTabProps} />
      <S.ContainerForm>
        <S.Form onSubmit={() => console.log('subitting')}>
          <S.InputContainer>
            <S.InputWrapper>
              <Input
                label="Price"
                placeholder="0"
                value={price || 0}
                type="text"
                inputInfo={stableCoinSymbol}
                fullWidth
                setValue={(inputAmount) => setPrice(inputAmount)}
              />
              <Input
                label="Amount"
                placeholder="0"
                value={amount || 0}
                max={max}
                type="text"
                inputInfo={orderType === 'Market Order' && isBuy ? stableCoinSymbol : currentHPoolTokenSymbol}
                fullWidth
                setValue={(inputAmount) => setAmount(inputAmount)}
              />
            </S.InputWrapper>
          </S.InputContainer>
          <PercentageTabs selectedTab={selectedTab} onTabClick={handleTabClick} />
          <S.WrapperActions>
            <S.FeeLabelWrapper>
              <Flex fullWidth={false} gap="0.5rem" alignItems="center">
                <S.FeeLabel>Fees</S.FeeLabel>
                <Icon name="InfoIcon" />
              </Flex>
              <Flex fullWidth={false} alignItems="baseline">
                <S.Fee>{_busdFee ? +_busdFee.toFixed(2) : 0}</S.Fee>
                {_busdFee > 0 && <S.FeeCoin>{stableCoinSymbol}</S.FeeCoin>}
              </Flex>
            </S.FeeLabelWrapper>
          </S.WrapperActions>
          <Flex mb="3.6rem" justifyContent="space-between" alignItems="baseline">
            <Text md opacity={0.5}>
              Total
            </Text>
            <Text reg opacity={0.5}>
              {price && amount ? newDisplayFloats(price * amount, 2) : 0} {stableCoinSymbol}
            </Text>
          </Flex>
          <Button
            fullWidth
            title={`${type} ${currentHPoolTokenSymbol || ''}`}
            loading={isBuy ? buyLoading : sellLoading}
            loadingText="In Progress"
            className="mt-3"
            click={startTransaction}
            disabled={!account || !amount || !price}
          />
        </S.Form>
      </S.ContainerForm>
    </S.WrapperOrder>
  )
}

const mapStateToProps = (state) => ({
  amount: state.input.amount,
  price: state.input.price,
  focusedOrderType: state.input.focusedOrderType,
  buyPrice: state.input.buyPrice,
  sellPrice: state.input.sellPrice,
  buyAmount: state.input.buyAmount,
  sellAmount: state.input.sellAmount,
  buyTokenPrice: state.input.buyTokenPrice,
  sellTokenPrice: state.input.sellTokenPrice,
  account: state.wallet.account,
  currentHPoolTokenBalance: state.balances.currentHPoolTokenBalance,
  busdBalance: state.balances.busdBalance,
  currentHPoolTokenName: state.tradingPair.currentHPoolToken?.name,
  currentHPoolTokensymbol: state.tradingPair.currentHPoolToken?.symbol,
  currentHPoolTokenAddress: state.tradingPair.currentHPoolToken?.address,
  buyLoading: state.ui.buyLoading,
  sellLoading: state.ui.sellLoading,
  hpoolStats: state.orderbook?.chartStats?.stats,
})

export default connect(mapStateToProps, {
  handleInputChange,
  makeBuyOrder,
  makeSellOrder,
  // clearInputs,
})(MarketOrderAction)
