import { WEB_SOCKET_DISCONNECTED } from 'views/dashboard/components/blocks/Transactions/EventsListeners'
import Web3 from 'web3'
import { getStableCoinNetworkByAddress } from 'services/tokensService'
import { offerMethodAbi } from './constants/vpool'
import {
  createContract,
  createWssContract,
  getContractAddress,
  getValueByMultiProps,
  newContractReader,
  newGetContractAddress,
} from './contractReader'
import { eventPoolingGetter } from './dlabService'
import config from '../config/config.json'
import { parseInputs } from './txProcessing'
import { isMatched } from './validator'
import { assetAmountInEth } from './assetsService'
import { getAccount } from './walletService'
import { wait } from './utilsService'
import { getCurrentChainReq, getNetworkById, WeiToEth } from './web3Service'
import { eventSetup } from './events'

export const formatItemsUpdateEvents = async (passedEvents) => {
  let result = {}
  const events = passedEvents
  events.forEach((evt) => {
    const txHash = getValueByMultiProps(evt, ['transactionHash', 'transaction_hash'])
    const { id } = evt ?? {}
    result = { ...result, [txHash]: id }
  })

  return result
}

export const getIfActiveOrder = (orderId, matchingMarketContractAddress) => {
  if (!Number.isFinite(+orderId)) return true
  return newContractReader({
    contractName: 'MatchingMarket',
    contractAddress: matchingMarketContractAddress,
    functionName: 'isActive',
    params: [orderId],
  })
}
window.getIfActiveOrder = getIfActiveOrder
export const getTradeOrderFee = async (poolAddress, matchingMarketContractAddress) => {
  const result = await newContractReader({
    contractName: 'MatchingMarket',
    functionName: 'poolToPlatformFee',
    contractAddress: matchingMarketContractAddress,
    params: [poolAddress],
  })
  let { totalTradingFeesInStableCoin } = result ?? {}
  totalTradingFeesInStableCoin = assetAmountInEth(totalTradingFeesInStableCoin)
  return totalTradingFeesInStableCoin
}

export const isNotStableCoin = (tokenAddress, stableCoinAddress) => !isMatched(tokenAddress, stableCoinAddress)
export const isStableCoin = (tokenAddress, stableCoinAddress) => isMatched(tokenAddress, stableCoinAddress)

const findOrderType = (payedToken, stableCoinAddress) => {
  let isBuy = false
  isBuy = !isNotStableCoin(payedToken, stableCoinAddress) // if the payed token is busd (initially it's a buy order)
  // const userIsTheOrderOrigin = isMatched(from, account)
  // if (!userIsTheOrderOrigin && account) isBuy = !isBuy // if user was not the order origin order type is inverted (buy for you is sell for me), no account means no need to inverse
  return isBuy ? 'buy' : 'sell'
}

const getPrice = (orderType, payAmt, buyAmt) => {
  const isBuy = orderType === 'buy'
  const buyCase = payAmt / buyAmt
  const sellCase = buyAmt / payAmt
  return isBuy ? buyCase : sellCase
}

const getTotalPaid = (prasedInputs) => {
  let result
  const { pay_gem: payedToken, pay_amt: payAmt, buy_amt: buyAmt } = prasedInputs
  const isPayedInBusd = !isNotStableCoin(payedToken)
  if (isPayedInBusd) result = payAmt
  else result = buyAmt
  return assetAmountInEth(result)
}

const swap = (var1, var2) => {
  const temp = var2
  var2 = var1
  var1 = temp
  return [var1, var2]
}
window.swap = swap

const invertOrderType = (orderType) => (orderType === 'buy' ? 'sell' : 'buy')

export const getDetailedOrderInfo = async (event, matchingMarketContractAddress, account) => {
  try {
    const currenChain = await getCurrentChainReq()

    const eventType = getValueByMultiProps(event, ['event_name', 'event'])

    // Most of this logic apply perfectly for LogMakes but LogTakes have different login since it same order could be at two differnt sides (Buyer and Seller)
    let __side = ''
    let _price = 0

    const { timestamp, returnValues = {}, maker, taker } = event ?? {}
    const txHash = getValueByMultiProps(event, ['transactionHash', 'transaction_hash'])

    const { input: txInput, from, to } = (await window._web3.eth.getTransaction(txHash)) ?? {}
    const prasedInputs = await parseInputs(offerMethodAbi, txInput)

    let payAmt = getValueByMultiProps(event, ['pay_amt', 'give_amt'])
    let buyAmt = getValueByMultiProps(event, ['take_amt', 'buy_amt'])

    const payedToken = getValueByMultiProps(event, ['pay_gem', 'give_gem'])
    const boughtToken = getValueByMultiProps(event, ['take_gem', 'buy_gem'])

    const stableCoinInfo = getStableCoinNetworkByAddress(payedToken) || getStableCoinNetworkByAddress(boughtToken)
    const { address: stableCoinAddress } = stableCoinInfo.stableCoin ?? {}

    __side = isStableCoin(payedToken, stableCoinAddress) ? 'buy' : 'sell'

    const orderType = findOrderType(payedToken, stableCoinAddress)
    const isPaidWithBUSD = isStableCoin(payedToken, stableCoinAddress) // if it's not then it's a sell

    const _side = isPaidWithBUSD ? 'buy' : 'sell'

    _price = +WeiToEth(isPaidWithBUSD ? payAmt : buyAmt || 0)

    const [targetToken] = [boughtToken, payedToken].filter((tokenAddress) =>
      isNotStableCoin(tokenAddress, stableCoinAddress),
    )

    const date = new Date(+timestamp * 1000)
    const ts = timestamp * 1000
    const targetAmt = isMatched(targetToken, boughtToken) ? buyAmt : payAmt

    let amount = WeiToEth(targetAmt)

    if (eventType === 'LogTake') {
      const amTheMaker = isMatched(account, maker)
      if (!amTheMaker) {
        __side = invertOrderType(__side)
        const [newPayAmt, newbuyAmt] = swap(payAmt, buyAmt)
        payAmt = newPayAmt
        buyAmt = newbuyAmt
      }
      _price = __side === 'sell' ? payAmt / buyAmt : buyAmt / payAmt
      amount = WeiToEth(__side === 'buy' ? payAmt : buyAmt)
    }

    const fee = await getTradeOrderFee(targetToken, matchingMarketContractAddress)

    return {
      fee: +fee,
      ...prasedInputs,
      ...returnValues,
      prasedInputs,
      side: __side,
      from,
      to,
      price: +_price,
      totalStableCoin: +_price,
      hpool_token_price: _price,
      total: amount,
      ts,
      date,
      coinAddress: targetToken,
      stableCoin: stableCoinInfo.stableCoin,
    }
  } catch (error) {
    console.log({ getDetailedOrderInfoErr: error })
    return {}
  }
}

export const getExecutedOrders = async (account) => {
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
  const events = (await eventPoolingGetter(matchingMarketContractAddress, 'LogTake')) ?? []
  const formattedEvents = await Promise.all(
    events.map(async (event) => {
      const detailedInfo = await getDetailedOrderInfo(event, matchingMarketContractAddress, account)
      return { ...event, ...detailedInfo }
    }),
  )
  return formattedEvents
}

export const setupWeb3EventListener = async (eventName, onData = (data) => {}, onError = (err) => {}) => {
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
  const matchingMarketContract = await createWssContract('MatchingMarket', matchingMarketContractAddress)

  const subscribtionObj = matchingMarketContract.events?.[eventName]?.()
    .on('connected', (subscriptionId) => {
      console.log({ eventConnected: `${eventName} is connected, subscriptionId: ${subscriptionId}` })
    })
    .on('data', (event) => {
      onData(event)
    })
    .on('error', async (error, receipt) => {
      // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
      console.log({ setupWeb3EventListenerErr: error })
      await wait(400)
      eventSetup.trigger(WEB_SOCKET_DISCONNECTED)
      onError(error, receipt)
    })
  return subscribtionObj
}

export const getPastEvents = async (eventName) => {
  try {
    const latestBlockNumber = await window._web3.eth.getBlockNumber()
    const currenChain = await getCurrentChainReq()
    const { rpcUrl } = getNetworkById(currenChain)
    await wait(1500) // wait is added cuz getPastEvents has a limit (e.g 3 time per second)
    const fromBlock = latestBlockNumber - 500 // 2000 should cover last 15min blocks
    const web3Instance = new Web3(rpcUrl)

    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
    const matchingMarketContract = await createContract('MatchingMarket', matchingMarketContractAddress, web3Instance)
    const last15MinEvents =
      matchingMarketContract.getPastEvents(eventName, { fromBlock, toBlock: latestBlockNumber }) || []

    return last15MinEvents
  } catch (error) {
    console.log({ getPastEventsError: error })
    return []
  }
}

export const appendOrderId = async (evt, matchingMarketContractAddress, isLogMake) => {
  const txHash = getValueByMultiProps(evt, ['transaction_hash', 'transactionHash'])
  const { input: txInput } = await window._web3.eth.getTransaction(txHash)
  const prasedInputs = await parseInputs(offerMethodAbi, txInput)
  const id = window.ordersStatusSrc?.[txHash]
  const isActiveOrder = await getIfActiveOrder(id, matchingMarketContractAddress)
  return { ...evt, id, isActiveOrder, prasedInputs }
}

// DAPI response model is the standard => u gotta map web3 getPastEvents like DAPI's one
// 1- get from dapi
// 2- get from getPastEvents
// 3- map getPastEvents response to suit DAPI's model
// merge DAPI's and getPastEvents
// when logKill Evt happens just remove the targeted id from orderbook.pendingOrder

export const mapToFitDapiModel = (evt) => {
  const { returnValues } = evt
  if (!returnValues) return evt
  return { ...evt, ...returnValues, returnValues }
}

export const getTxHash = (tx) => getValueByMultiProps(tx, ['transactionHash', 'transaction_hash', 'h'])

const isDuplicateTx = (txToTest = '', foundHashes = []) =>
  foundHashes.find((duplicateHash) => isMatched(getTxHash(txToTest), duplicateHash))

export const removeDuplicatesTxs = (txs = [], foundHashes) => txs.filter((tx) => !isDuplicateTx(tx, foundHashes))

export const removeDuplicated = (txs = []) => {
  const result = []
  const foundHashes = []
  txs.forEach((tx) => {
    const hash = getTxHash(tx)
    if (!foundHashes.find((foundHashe) => isMatched(hash, foundHashe))) result.push(tx)
    foundHashes.push(hash)
  })
  return result
}

export const getBothDapiAndWeb3Events = async (evtName) => {
  try {
    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')

    let web3LibEvts = (await getPastEvents(evtName)) || [] // should be small array compared to dapiEvts
    web3LibEvts = web3LibEvts.map(mapToFitDapiModel)
    // web3LibEvts = removeDuplicated(web3LibEvts)
    const orderHashes = web3LibEvts.map(getTxHash)
    const dapiEvts = (await eventPoolingGetter(matchingMarketContractAddress, evtName)) || []
    // dapiEvts = removeDuplicatesTxs(dapiEvts, orderHashes)
    const result = removeDuplicated([...dapiEvts, ...web3LibEvts])
    return result
  } catch (error) {
    return []
  }
}

export const getOrderIdFromTxHash = (ev) => {
  const txHash = getValueByMultiProps(ev, ['transactionHash', 'transaction_hash'])
  return { ...ev, id: window.ordersStatusSrc?.[txHash] }
}

// TODO: make sure both records from DAPI and web3 have the same object model (remap when necessary)
export const getPrevOpenOrders = async () => {
  try {
    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')

    const [allPrevLogUpdates, allPrevLogMakes] = await Promise.all([
      getBothDapiAndWeb3Events('LogItemUpdate'),
      getBothDapiAndWeb3Events('LogMake'),
    ])
    const ordersStatusSrc = (await formatItemsUpdateEvents(allPrevLogUpdates)) || {}
    window.ordersStatusSrc = ordersStatusSrc

    // allPrevLogMakes = allPrevLogMakes.map(getOrderIdFromTxHash)

    const allOrders = await Promise.all(
      allPrevLogMakes.map((order) => appendOrderId(order, matchingMarketContractAddress, true)),
    )
    console.log({ allPrevLogUpdates, allPrevLogMakes, allOrders })

    const inActiveOrders = allOrders.filter((order) => order.isActiveOrder)
    return inActiveOrders
  } catch (error) {
    console.log({ getOpenOrdersError: error })
  }
}

export const getPrevCompletedOrders = async () => {
  try {
    const allPrevLogTakes = (await getBothDapiAndWeb3Events('LogTake')) || []
    // allPrevLogMakes = allPrevLogMakes.map(getOrderIdFromTxHash)
    const allOrders = await Promise.all(allPrevLogTakes)
    return allOrders
  } catch (error) {
    console.log({ getOpenOrdersError: error })
  }
}

export const cancelOrderRequest = async (orderId, account) =>
  newContractReader({
    contractName: 'MatchingMarket',
    functionName: 'cancel',
    params: [orderId],
    sendParams: {
      from: account,
    },
  })

window.cancelOrderRequest = cancelOrderRequest
window.getExecutedOrders = getExecutedOrders
