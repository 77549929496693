/* eslint-disable import/no-cycle */
import { store } from 'index'
import Web3 from 'web3'
import ERC20 from 'utils/contracts/ERC20.json'
import { createNotificationAction } from 'store/actions/uiActions'
import configJson from '../config/config.json'
import { getNetworkById } from './web3Service'
import { assetAmountInEth } from './assetsService'
import { loadContracts } from './contractsRegistryService'

let contracts = null

export const wait = (time = 500, val = true) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(val)
    }, time)
  })

export const fetchContracts = async () => fetch(`${configJson.apiPath}/config/contracts`).then((res) => res.json())

const getContracts = async () => {
  contracts = await fetchContracts()
  contracts = { ...contracts, ERC20 }
  window.rawContracts = contracts
}

getContracts()

const _loadContracts = async () => {
  if (!window.rawContracts) {
    await wait(150)
    return _loadContracts()
  }
  window.rawContracts.ERC20 = ERC20
  return window.rawContracts
}

const loadWssWeb3 = async () => {
  if (!window.wsWeb3) {
    await wait(150)
    return loadWssWeb3()
  }
  return window.wsWeb3
}

export const getValueByMultiProps = (value, possibleKeys = []) => {
  // not all tokens has valid imgs
  const validKey = possibleKeys.find((key) => value?.[key])
  return value?.[validKey]
}

export const newGetContractAddress = async (contractName, network = window.chainId) => {
  await _loadContracts()
  const contract = window.rawContracts?.[contractName]
  const contractAddress = getValueByMultiProps(contract?.networks?.[network], ['Proxy', 'address'])
  return contractAddress
}
window.newGetContractAddress = newGetContractAddress

const newFindInContracts = async (contractName) => {
  await _loadContracts()
  const contract = window.rawContracts?.[contractName]
  return contract
}

export const findInContracts = async (contractName) => {
  const _contracts = (await loadContracts()) || {}
  let newContractsObject = {}
  Object.entries(_contracts).forEach(([key, value]) => {
    const newKey = key.toLocaleLowerCase()
    newContractsObject = { ...newContractsObject, [newKey]: value }
  })
  const lowerCasedName = contractName.toLocaleLowerCase()
  return newContractsObject?.[lowerCasedName]
}

export const getContractAddress = async (contractName, network = configJson.network) => {
  if (!window.contracts) {
    await wait(150)
    return getContractAddress(contractName)
  }
  const contract = findInContracts(contractName)
  const contractAddress = getValueByMultiProps(contract?.networks?.[network], ['Proxy', 'address'])
  return contractAddress
}

export const createContract = async (name, address, passedWebInstance) => {
  // this method has been added to avoid search contracts names case-sensitivity
  await _loadContracts()
  const contract = window.rawContracts?.[name]
  const web3Instance = passedWebInstance || window._web3
  return new web3Instance.eth.Contract(contract.abi, address)
}

export const createWssContract = async (name, address) => {
  await _loadContracts()
  await loadWssWeb3()
  const contract = window.rawContracts?.[name]
  return new window.wsWeb3.eth.Contract(contract.abi, address)
}
window.createContract = createContract
window.createWssContract = createWssContract

export const contractReader = async (
  contract,
  contractFunction,
  parameters = [],
  callMethod = (contractInstance) => contractInstance?.[contractFunction](...parameters)?.call(),
) => {
  let result
  try {
    result = await callMethod(contract)
  } catch (error) {
    result = { error: error.message }
    // store.dispatch(createNotificationAction('error', 'Something went wrong reading data from the blockchain.', 4000))
    console.warn(error)
  }
  return result
}
window.contractReader = contractReader

export const newContractReader = async ({
  contractName,
  functionName = '',
  params = [],
  contractAddress: passedContractAddress, // could be passed in cases like HPool contract
  sendParams, // like from and value that are passed to send function
  chainId = window.chainId || configJson.network, // you only have to pass chain id in (pool list page) like cases when there is multible pools from different networks,
  passedRpc,
  // otherwise will enforce user to switch
}) =>
  new Promise(async (resolve, reject) => {
    await _loadContracts()

    const contractObj = (await newFindInContracts(contractName)) ?? {}
    const { abi, networks } = contractObj

    const rpcUrl = passedRpc || getNetworkById(chainId)?.rpcUrl
    const web3Instance = sendParams ? window._web3 : new Web3(rpcUrl)

    const targetAddress = passedContractAddress || getValueByMultiProps(networks[chainId], ['Proxy', 'address'])
    const contractInstance = new web3Instance.eth.Contract(abi, targetAddress)

    try {
      if (!sendParams) {
        const result = await contractInstance.methods?.[functionName]?.(...params).call()
        resolve(result)
      } else {
        contractInstance.methods?.[functionName]?.(...params)
          .send({ ...sendParams })
          .once('receipt', (rec) => resolve(rec))
          .on('error', (err) => reject(err))
      }
    } catch (error) {
      reject(error.message)
    }
  })

export const approvedAmountOnOrder = (account, amount, vpoolAddress) =>
  newContractReader({
    contractName: 'HordToken',
    functionName: 'approve',
    contractAddress: '0x5d2aa7650ba5d83ab8ed6789820ec05465cbacc1',
    params: [vpoolAddress, assetAmountInEth(amount)],
    sendParams: { from: account },
  })

export const getTokenDecimals = async (contractAddress) => {
  const result = await newContractReader({ contractName: 'ERC20', functionName: 'decimals', contractAddress })

  return result
}
