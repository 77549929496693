import React from 'react'
import clsx from 'clsx'
import { Flex, Text } from 'styles/layouts'

export interface IPercentTab {
  id: number
  shownValue: string
  isActive: boolean
  onTabClick: (id: number) => void
}

const PercentTab = ({ id, shownValue, isActive, onTabClick }: IPercentTab) => (
  <Flex
    key={id}
    className={clsx('pointer percent-tab', isActive ? 'percent-tab-active' : '')}
    justifyContent="center"
    p="0.6rem 1.2rem"
    border="1px solid #6C6F7C"
    borderRadius={7}
    onClick={() => onTabClick(id)}
  >
    <Text centered sm>
      {shownValue}%
    </Text>
  </Flex>
)

export default PercentTab
