import React from 'react'
import { Flex } from 'styles/layouts'
import './ChartLoading.scss'

interface IChartLoading {
  loading: boolean
  children?: JSX.Element
}

export default function ChartLoading({ loading, children }: IChartLoading) {
  const strokesArray = new Array(100).fill(null).map((elem, index) => index)
  return (
    <>
      {loading ? (
        <Flex className="wave-line-animation">
          {strokesArray.map((elem) => (
            <Flex key={elem}>
              <svg width="416" height="160" xmlns="http://www.w3.org/2000/svg">
                <path d="M 0 80 Q 104 10, 208 80 T 416 80" stroke="#99A1BD24" fill="transparent" strokeWidth="2" />
              </svg>
            </Flex>
          ))}
        </Flex>
      ) : (
        children
      )}
    </>
  )
}
