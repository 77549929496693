import styled from 'styled-components'

export const Wrapper = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #70648D;
  padding: 0.6rem 1.1rem;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    border: 1px solid #F1C3F2;
  }
`

export const Image = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  z-index: 1;
`

export const PairWrapper = styled.div`
  display: flex; 
  align-items: center;
  gap: 2.5rem;
`

export const PairIconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  position: absolute;
  left: 53px;
  background: #fff;
`

export const Pair = styled.span`
  font-size: 1.8rem;
`