export const displayFloats = (num = 0, maxDigits = 2) => +num.toFixed(maxDigits)

export const getPercentage = (num: number, total: number) => displayFloats((num / 100) * total)

export const howManyDigitsAfterDecimal = (num: number): number => {
  const stringfiedNum = num.toString()
  if (!stringfiedNum.includes('.')) return 0
  const [, afterDecimal] = stringfiedNum.split('.')
  return afterDecimal.length
}

export const safeMul = (first: number, second: number) => {
  const firstNumDecimals = howManyDigitsAfterDecimal(first)
  const secondNumDecimals = howManyDigitsAfterDecimal(second)
  const maxPossibleDecimals = firstNumDecimals + secondNumDecimals
  return +(first * second).toFixed(maxPossibleDecimals)
}

export const safeDiv = (first: number, second: number) => {
  const firstNumDecimals = howManyDigitsAfterDecimal(first)
  const secondNumDecimals = howManyDigitsAfterDecimal(second)
  const maxPossibleDecimals = firstNumDecimals + secondNumDecimals
  return +(first / second).toFixed(maxPossibleDecimals)
}
const _window = window as any

_window.safeMul = safeMul
_window.safeDiv = safeDiv
