import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Text } from 'styles/layouts'
import Table from '../../../../../../components/general/Table/Table'
import recentTradesData from './recentTradesData'
import * as S from './styles'
import './index.scss'

const RecentTrades = () => {
  const {
    executedOrders = [],
    latestHethStat = null,
    latestHethStatLoading,
    loadingsState,
  } = useSelector((state: any) => ({
    executedOrders: state.orderbook?.executedOrders,
    loadingsState: state.orderbook?.loadingsState,
    latestHethStat: state.ethStaking?.hethInfo?.latestHethStat,
    latestHethStatLoading: state.ethStaking?.hethInfo?.isLoading,
  }))

  const combinedLoadings = loadingsState?.executedOrder || latestHethStatLoading

  const { eth_price_usd: ethPrice } = latestHethStat || {}

  const dataSrc = useMemo(
    () =>
      executedOrders?.map((order) => ({
        price: order?.price,
        ts: order?.ts,
        ethValue: order?.price / ethPrice,
        side: order?.side,
      })),
    [ethPrice, executedOrders],
  )

  const sortedDataSrc = useMemo(() => dataSrc.sort((a, b) => b?.ts - a?.ts), [dataSrc])

  return (
    <S.Wrapper>
      <Text bold reg>
        Recent Trades
      </Text>
      <Table
        hideHeader={!sortedDataSrc.length}
        className="recent-trades-table"
        dataSourse={sortedDataSrc}
        cols={recentTradesData}
        loading={combinedLoadings}
      />
    </S.Wrapper>
  )
}

export default RecentTrades
