import configJson from '../config/config.json'

// TODO it should be pool address instaed of ID
export const fetchChartStats = async (poolAddress) => {
  // const urlSeachParams = new URLSearchParams({ from, to }).toString()

  const url = `${configJson.apiPath}/hpool/stats/${poolAddress}`
  const stats = await fetch(url).then((res) => res.json())
  return stats
}
