import { useCallback } from 'react'
import { stableCoinToNetworkSrc } from 'utils/constants/chains'
import Icon from '../Icon'
import DiscountTag from '../NavbarItem/DiscountTag'
import * as S from './styles'

export type DropdownItemProps = {
  title: number | string
  index: number
  searchTerm: number | string
  handleAction: (select: number | string, address: number | string, index: number) => void
  image?: string
  chainId?: string | number
  discount?: number
}
const DropdownItem = ({ title, handleAction, searchTerm, index, image, chainId, discount }: DropdownItemProps) => {
  const onHandleAction = useCallback(() => {
    handleAction(title, searchTerm, index)
  }, [searchTerm, title])

  const stableCoin = stableCoinToNetworkSrc?.[chainId]

  return (
    <S.Wrapper className="inner-dropdown" onClick={onHandleAction}>
      <S.PairWrapper>
        <S.PairIconWrapper>
          <Icon style={{ width: '100%', height: '100%' }} name={stableCoin} ext="png" folder="images" />
        </S.PairIconWrapper>
        <S.Image src={image} />
        <S.Pair>{`${title} / ${stableCoin}`}</S.Pair>
      </S.PairWrapper>
      {/* Hidden next line for now => TODO: Add discount for each individual token in the drop down list */}
      {/* <DiscountTag bg="#8878AA" title="Discount" value={discount} /> */}
    </S.Wrapper>
  )
}

export default DropdownItem
