import React from 'react'
import { formatNumericValues } from 'services/format'
import { Flex, Text } from 'styles/layouts'
import * as S from '../OrderBookOrder/styles'

export const orderbookTableCols = [
  {
    id: 'price',
    renderTitle: () => (
      <Flex gap="1rem" column>
        <Text sm color="#858585">
          Price
        </Text>
        <Text sm color="#858585">
          (USDT)
        </Text>
      </Flex>
    ),
    render: (record) => (
      <S.Span data={record} as="span">
        {/* Using formatNumericValues instead of newDisplayFloats because we need to show default decimals even when there are none */}
        {formatNumericValues(record.price, 2)}
      </S.Span>
    ),
  },
  {
    id: 'amount',
    renderTitle: () => (
      <Flex gap="1rem" column>
        <Text sm color="#858585">
          Amount
        </Text>
        <Text sm color="#858585">
          (hETH)
        </Text>
      </Flex>
    ),
    render: (record) => <S.Span>{formatNumericValues(record.leftAmt, 4)}</S.Span>,
  },
  {
    id: 'totalInStableCoin',
    renderTitle: () => (
      <Flex gap="1rem" column>
        <Text sm color="#858585">
          Amount
        </Text>
        <Text sm color="#858585">
          (USDT)
        </Text>
      </Flex>
    ),
    render: (record) => (
      <Flex gap="0.3rem">
        <S.Span>{formatNumericValues(record?.totalInStableCoin, 4)}</S.Span>
      </Flex>
    ),
  },
]
