import { useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import Dinero from 'dinero.js'
import { setLatestTradePrice, setTradingPairsInformation } from 'store/actions/tradingPairActions'
import { newGetContractAddress } from 'utils/contractReader'
import { findMinMax } from 'utils/array'
import { arraySort } from 'utils/arraySort'
import useCurrentStableCoin, { ETH_CHAINS } from 'hooks/useCurrentStableCoin'
import DiscountTag from 'components/general/NavbarItem/DiscountTag'
import PriceChangeTag from 'components/general/NavbarItem/NavbarItemTag'
import { usePromise } from 'hooks/usePromise'
import { getLatestValidatorStats } from 'utils/ethStakingService'
import { setHethLatestStat } from 'store/actions/ethStaklng/ethStakingActions'
import { getNetworkName } from 'utils/constants/chains'
import * as S from './styles'
import NavbarPair from '../../../../../components/general/NavbarPair'
import NavbarItem from '../../../../../components/general/NavbarItem'

type BlockProps = {
  volume: number
  high: number
  low: number
  blockPrice: string
}

export type IPairs = {
  name: string
  image: string
  address: string
  symbol: string
  id?: string
  isNotErc20?: boolean
  contractName?: string
  // eslint-disable-next-line camelcase
  chain_id?: number
}

const ETH_PAIRS = []

type Props = {
  blockValues: BlockProps
  lastTradePrice: number
  lastTradePriceType: 'AskLimit' | 'BidLimit'
  account: any
  chartStats: any
  tokenSymbol: string
  hPoolTokensList: IPairs[]
  HPoolFactory: object
  HPoolManager: object
  setTradingPairsInformation: () => void
  setLatestTradePrice: (price: number) => void
  loading: boolean
}
const Navbar = ({
  blockValues,
  lastTradePrice,
  lastTradePriceType,
  account,
  chartStats,
  tokenSymbol,
  hPoolTokensList,
  HPoolFactory,
  HPoolManager,
  setTradingPairsInformation,
  setLatestTradePrice,
  loading,
}: Props) => {
  const dispatch = useDispatch()
  const { network, hpoolsStats, executedOrders, poolTokenContract } = useSelector((state: any) => ({
    network: state.wallet.network,
    hpoolsStats: state.orderbook?.chartStats?.stats,
    executedOrders: state.orderbook?.executedOrders,
    poolTokenContract: state.tradingPair.currentHPoolToken?.address,
  }))
  const [{ symbol }, currentAppNetwork] = useCurrentStableCoin()
  const [, latestHethStat = null, latestValidatorStatsLoading] = usePromise(getLatestValidatorStats, {
    initReq: true,
  })

  const networkName = getNetworkName(network)
  const isEthNetwork = networkName === 'ethereum'

  const passedPairs = [...hPoolTokensList]
  const { hpool_total_assets_worth_usd: hpoolTokenWorth } = hpoolsStats?.[hpoolsStats?.length - 1] || {}

  const getPricePercentDiff = (initialPrice, finalPrice) => {
    const diff = finalPrice - initialPrice
    const percent = (diff / initialPrice) * 100
    return percent
  }

  const { dailyHigh, dailyLow, pricePercentageDifference, volume24hr, latestTradePrice, discount, assetValue } =
    useMemo(() => {
      const currentTokenExcecutedOrders =
        executedOrders?.filter((order) => order?.coinAddress === poolTokenContract) || []
      const sortedOrdersByTimestamp = arraySort(currentTokenExcecutedOrders, 'ts', { isAsc: false, isString: false }) // 1st item is latest order last item is oldest

      const oneDayInMs = 24 * 60 * 60 * 1000
      const yesterday = new Date().getTime() - oneDayInMs
      const latestTradePrice = sortedOrdersByTimestamp?.[0]?.price || 0
      const last24HrStats = sortedOrdersByTimestamp?.filter((stat) => stat?.ts >= yesterday)
      const volume24hr = last24HrStats?.reduce((acc, curr) => acc + curr?.price * +curr?.total, 0) || 0
      const dailyHigh = last24HrStats?.length ? findMinMax(last24HrStats, 'price', true) : 0
      const dailyLow = last24HrStats?.length ? findMinMax(last24HrStats, 'price') : 0
      const initialPrice24hr = last24HrStats?.[last24HrStats?.length - 1]?.price
      const finalPrice24hr = last24HrStats?.[0]?.price
      const pricePercentageDifference = getPricePercentDiff(initialPrice24hr, finalPrice24hr)
      const assetValue = isEthNetwork ? latestHethStat?.heth_to_eth * latestHethStat?.eth_price_usd : hpoolTokenWorth
      const discount = latestTradePrice && ((assetValue - latestTradePrice) / assetValue) * 100

      return {
        dailyHigh,
        dailyLow,
        pricePercentageDifference,
        volume24hr,
        latestTradePrice,
        discount,
        assetValue,
      }
    }, [executedOrders, poolTokenContract])

  useEffect(() => {
    dispatch(setHethLatestStat({ latestHethStat, isLoading: latestValidatorStatsLoading }))
  }, [latestHethStat, latestValidatorStatsLoading])

  useEffect(() => {
    if (latestTradePrice) setLatestTradePrice(latestTradePrice)
  }, [latestTradePrice])

  return (
    <S.Wrapper className="NavbarWrapper">
      <S.WrapperInfo>
        <S.ContainerPair>
          <NavbarPair coin={symbol} pairs={passedPairs || []} discount={discount} />
        </S.ContainerPair>
        <S.ContainerInfo>
          <NavbarItem
            isEth={isEthNetwork}
            loading={loading}
            label="Latest Price"
            info={Dinero({ amount: Math.round(+latestTradePrice * 100) || 0 }).toFormat('$0,0.00')}
            type={lastTradePriceType}
            tag={
              <PriceChangeTag
                icon="VerticalArrow"
                value={pricePercentageDifference}
                bg={pricePercentageDifference < 0 ? '#532c2c' : '#203B2C'}
              />
            }
            ethValue={+latestTradePrice / latestHethStat?.eth_price_usd}
          />
          <NavbarItem
            isEth={isEthNetwork}
            loading={loading}
            label="Asset Value"
            info={Dinero({
              amount: Math.round(assetValue * 100) || 0,
            }).toFormat('$0,0.00')}
            infoStyle={{ color: '#926CE3' }}
            tag={<DiscountTag title="Discount" value={discount} bg="#2C203B" />}
            ethValue={(+latestHethStat?.heth_to_eth * latestHethStat?.eth_price_usd) / latestHethStat?.eth_price_usd}
          />

          <NavbarItem
            isEth={isEthNetwork}
            loading={loading}
            label="24h High"
            info={Dinero({ amount: Math.round(+dailyHigh * 100) || 0 }).toFormat('$0,0.00')}
            type={lastTradePriceType}
            ethValue={+dailyHigh / latestHethStat?.eth_price_usd}
          />
          <NavbarItem
            isEth={isEthNetwork}
            loading={loading}
            label="24h Low"
            info={Dinero({ amount: Math.round(+dailyLow * 100) || 0 }).toFormat('$0,0.00')}
            type={lastTradePriceType}
            ethValue={+dailyLow / latestHethStat?.eth_price_usd}
          />
          <NavbarItem
            isEth={isEthNetwork}
            loading={loading}
            label="24h Volume"
            info={Dinero({ amount: Math.round(+volume24hr * 100) || 0 })
              .toFormat('$0,0.00')
              .toString()
              .slice(0, 6)}
            ethValue={+volume24hr / latestHethStat?.eth_price_usd}
          />
        </S.ContainerInfo>
      </S.WrapperInfo>
    </S.Wrapper>
  )
}

const mapStateToProps = (state) => ({
  hPoolTokensList: state.tradingPair.hPoolTokensList || [],
  HPoolFactory: state.contracts.HPoolFactory,
  HPoolManager: state.contracts.HordHpoolManager,
  tokenSymbol: state.tradingPair?.currentHPoolToken?.symbol,
  chartStats: state.orderbook?.chartStats?.stats,
  loading: state.orderbook?.chartStats?.loading,
})

export default connect(mapStateToProps, { setTradingPairsInformation, setLatestTradePrice })(Navbar)
