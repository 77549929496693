export const vpoolAddressUrlParam = 'pool-address'

export const BSC_SCAN_API_KEY = 'CENPJD7ASR5MXZMBWQ6KJ4ZN4KM8M1VFUV'

export const offerMethodAbi = [
  {
    internalType: 'uint256',
    name: 'pay_amt',
    type: 'uint256',
  },
  {
    internalType: 'contractIERC20',
    name: 'pay_gem',
    type: 'address',
  },
  {
    internalType: 'uint256',
    name: 'buy_amt',
    type: 'uint256',
  },
  {
    internalType: 'contractIERC20',
    name: 'buy_gem',
    type: 'address',
  },
  {
    internalType: 'uint256',
    name: 'pos',
    type: 'uint256',
  },
]
