import styled from 'styled-components'
import { generateMedia } from 'styled-media-query'

type Props = {
  marketActive: boolean
}

const customMedia = generateMedia({
  custom: '1199px',
  custom2: '1000px',
  custom3: '600px',
})

export const OrderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  border-radius: 5px;

  @media only screen and (max-width: 1200px) {
    margin-top: 2rem;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
  max-width: 200rem;
  margin: 12rem auto 0 auto;
  ${customMedia.lessThan('custom')`
    display: block;
  `}
`

export const PageTitle = styled.h1`
  font-size: 4rem;
  margin: 12rem 0 3rem 0;
  text-align: center;
  font-weight: 900;

  ${customMedia.lessThan('custom2')`
    margin: 9rem 0 3rem 0;
  `}
`

export const WrapperMain = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding: 0 3rem;
  margin-bottom: 12.5rem;

  ${customMedia.lessThan('custom3')`
    padding: 0 2rem;
  `}
`

export const WrapperSecondary = styled.div`
  padding: 3rem;
  background: #1E1628;
  border-radius: 2.4rem;
`;

export const WrapperGraph = styled.div<Props>`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  border-radius: 5px;
  gap: 1.2rem;
  @media only screen and (max-width: 1300px) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }

  @media only screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`
