import React from 'react'

const ChampionsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M1.25 14.75H14.75" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.75 11.75H1.25V3.75L5 5.5L8 1.25L11 5.5L14.75 3.75V11.75Z"
      stroke="#928F9C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChampionsIcon
