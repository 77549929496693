import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #1E1628;
  border-radius: 2.4rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (max-width: 960px) {
    height: 55rem;
  }
`

export const WrapperTitle = styled.div`
  margin-bottom: 1rem;
`
export const ContainerActions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0.5rem;
  margin-right: 1rem;
`
