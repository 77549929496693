/* eslint-disable camelcase */
export interface IHethState {
  id: number
  apy: number
  heth_to_eth: number
  total_eth_staked: number
  heth_market_cap: number
  total_stakers: number
  eth_price_usd: number
  validators_count: number
  daily_income: number
  weekly_income: number
  monthly_income: number
  all_time_income: number
  created_at: string
}

export interface IhethInfo {
  latestHethStat: IHethState | null
  isLoading: boolean
}

export interface IEthStakingReducer {
  hethInfo: IhethInfo
}

export const initialGeneralState: IEthStakingReducer = {
  hethInfo: {
    latestHethStat: null,
    isLoading: false,
  },
}