import React from 'react'

const DexIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path d="M1 7L1 11.5" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.25 1L6.25 11.5" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 4L11.5 11.5" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default DexIcon
