// events.js

function on(eventType, listener) {
  document.addEventListener(eventType, ({ detail }) => listener(detail))
}

function off(eventType, listener) {
  document.removeEventListener(eventType, listener)
}

function trigger(eventType, data?: any) {
  const event = new CustomEvent(eventType, { detail: data })
  document.dispatchEvent(event)
}
export const eventSetup = { on, off, trigger }
