import * as React from 'react'
import { Flex } from 'styles/layouts'
import PercentTab from './PercentTab'
import './index.scss'

export const percentTabs = [
  {
    id: 0,
    shownValue: '25',
    value: 0.25,
  },
  {
    id: 1,
    shownValue: '50',
    value: 0.5,
  },
  {
    id: 2,
    shownValue: '75',
    value: 0.75,
  },
  {
    id: 3,
    shownValue: '100',
    value: 1,
  },
]

interface IPercentageTabs {
  onTabClick: (id: number) => void
  selectedTab: { id: number; value: number; shownValue: string }
}

const PercentageTabs = ({ onTabClick, selectedTab }: IPercentageTabs) => (
  <Flex p="0 0 3.6rem 0" mb="3.6rem" className="percent-tabs" gap="1rem">
    {percentTabs.map((tab) => (
      <PercentTab key={tab.id} {...tab} isActive={selectedTab?.id === tab.id} onTabClick={onTabClick} />
    ))}
  </Flex>
)

export default PercentageTabs
