import { FETCH_APP_CONFIG } from 'store/actionTypes/generalActionsType'

const initialGeneralState = {
  config: {},
}

export default (state = initialGeneralState, action) => {
  switch (action?.type) {
    case FETCH_APP_CONFIG: {
      return {
        ...state,
        config: action.payload,
      }
    }
    default:
      return state
  }
}
