import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #1E1628;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2.5rem;
  padding: 3.6rem 2rem;
  max-width: 22.9rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 30rem;
`