import React, { ReactElement } from 'react'
import ChartLoading from '../ChartLoading/ChartLoading'
import CircularProgress from '../CircularProgress'
import './index.scss'

interface ILoadableContent {
  children: ReactElement | ReactElement[]
  renderNoData?: ReactElement | ReactElement[]
  noData?: boolean
  loading: boolean
  size?: number
}

export default function LoadableContent({ children, loading, size = 25, renderNoData, noData }: ILoadableContent) {
  return (
    <div className="loadable-content-wrapper">
      {(noData || loading) && (
        <div className="middle-content">
          {noData ? <div className="no-data-content">{renderNoData}</div> : <ChartLoading loading />}
        </div>
      )}
      <>{children}</>
    </div>
  )
}
