import config from 'config/config.json'

export const CHAINS = {
  ETH: {
    mainnet: 1, // eth mainnet
    testnet: 5, // eth goerli testnet
  },
  BSC: {
    mainnet: 56, // bsc mainnet
    testnet: 97, // bsc testnet
  },
}

export const chianIdToNetworkCoinSrc = {
  1: 'ethereum',
  5: 'ethereum',
  56: 'binancecoin',
  97: 'binancecoin',
}

export const getChainByConfig = (chainName) => CHAINS?.[chainName]?.[config.networkType] // based on the network type (testnet or mainnet it returns the desired chain id)

export const getNetworkName = (chainId) => chianIdToNetworkCoinSrc?.[chainId]

export const stableCoinToNetworkSrc = {
  1: 'USDT',
  5: 'USDT',
  56: 'BUSD',
  97: 'BUSD',
}

export const getStableCoin = (chainId) => stableCoinToNetworkSrc?.[chainId]
