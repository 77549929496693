import config from '../config/config.json'

export const eventPoolingGetter = (contractAddress, eventName) => {
  const params = {
    collection_name: 'Hord-FE',
    contract_addresses: contractAddress,
    user_web3_addresses: contractAddress,
    event_names: eventName,
    env: 'DEVELOP',
  }
  const stringfiedUrlParams = new URLSearchParams(params).toString()
  const url = `${config.dlabBaseUrl}/event_polling/contracts_events_info?${stringfiedUrlParams}`
  const headers = {
    Authorization: `Hord-FE,${config.dlabApiKey}`, // TODO: refactor this to env var
  }
  return fetch(url, { headers }).then((res) => res.json())
}

window.eventPoolingGetter = eventPoolingGetter
