import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { useTxFinder } from 'hooks/useTxFinder'
import { offerMethodAbi } from 'utils/constants/vpool'
import { useSelector } from 'react-redux'
import { newGetContractAddress } from 'utils/contractReader'
import { isMatched } from 'utils/validator'
import { eventPoolingGetter } from 'utils/dlabService'
import { getExecutedOrders as getExecutedOrdersReq } from 'utils/tradeOrdersService'
import { WeiToEth } from 'utils/web3Service'
import { usePromise } from 'hooks/usePromise'
import useCurrentStableCoin from 'hooks/useCurrentStableCoin'
import Dropdown from 'components/general/Dropdown'
import ShowAt from 'components/general/ShowAt/ShowAt'
import config from '../../../../../config/config.json'
import TransactionTable from '../../../../../components/general/TransactionTable'
import { ITransactions } from './ITransactions'
import * as S from './styles'
import OpenOrderTable from '../../../../../components/general/OpenOrderTable'
import RecentTrades from './RecentTrades'
import './index.scss'

const initialFilters = {
  hiddenPairs: false,
  onlyBuy: false,
  onlySell: false,
  status: 'All Transactions',
}

export const invertOrderType = (orderType) => (orderType === 'buy' ? 'sell' : 'buy')

const Transactions = (
  { data, openOrderData, newTradeData, remove, activeIndex, setActiveIndex }: ITransactions,
  pair = 'DOT',
) => {
  const { account, poolsList } = useSelector((state: any) => ({
    account: state.wallet.account,
    poolsList: state.tradingPair.hPoolTokensList || [],
  }))
  const [{ address: stableCoinAddress }] = useCurrentStableCoin()
  const [dropdownState, setDropdownState] = useState(false)
  const [select, setSelect] = useState('All Pairs')

  const [filters, setFilters] = useState(initialFilters)
  const [, matchingContractAddress] = usePromise(() => newGetContractAddress('MatchingMarket'), { initReq: true })

  const getPoolContractAddress = (pool) => pool?.contract_address
  const poolsAddresses: string[] = useMemo(() => poolsList.map(getPoolContractAddress), [poolsList])

  const disableSearch = !account || !poolsAddresses.length || !matchingContractAddress

  const isAddressIncluded = (address: string) =>
    poolsList.find((pool: any) => isMatched(getPoolContractAddress(pool), address))

  const isNotStableCoin = (tokenAddress: string) => !isMatched(tokenAddress, stableCoinAddress)

  const processExecutedOrders = (order) => {
    const { transaction_hash: txHash, prasedInputs, taker, side } = order ?? {}
    const { buy_gem: boughtToken, pay_gem: payedToken } = prasedInputs ?? {}

    const [targetToken] = [boughtToken, payedToken].filter(isNotStableCoin)

    const matchedPool = isAddressIncluded(targetToken) ?? {}

    const { symbol: coin, hpool_token_icon: coinImage } = matchedPool
    return { ...order, coinImage, coin, txHash, completed: true, side }
  }

  const onlyUsersTxs = (tx) => isMatched(tx.maker, account) || isMatched(tx.taker, account)

  // Filters Actions
  const handleChangeHidden = () => setFilters({ ...filters })
  const handleChangeBuy = () => setFilters({ ...filters, onlyBuy: !filters.onlyBuy })
  const handleChangeSell = () => setFilters({ ...filters, onlySell: !filters.onlySell! })

  const handleDropdownClick = useCallback(
    (newDropdownState) => {
      setDropdownState(newDropdownState)
    },
    [setDropdownState],
  )

  return (
    <S.Section className="@@">
      <Tabs selectedIndex={activeIndex} onSelect={(index) => setActiveIndex(index)}>
        <S.Header>
          <TabList style={{ display: 'flex', listStyleType: 'none' }}>
            <Tab style={{ fontSize: '1.6rem', fontWeight: 700 }}>My Open Orders</Tab>
            <Tab style={{ fontSize: '1.6rem', fontWeight: 700 }}>My Trade History</Tab>
          </TabList>
          <S.WrapperActions>
            {/* <Dropdown title={select} active={dropdownState} setDropdownState={handleDropdownClick} bg="#403650">
              <>
                <span onChange={handleChangeBuy}>Buy</span>
                <span onChange={handleChangeSell}>Sell</span>
              </>
            </Dropdown> */}
            {/* <S.ContainerActions>
              <ShowAt at={poolsList?.length >= 1}>
                <Checkbox
                  backgroundColor="green"
                  title="Filter by current pair"
                  checked={filters.hiddenPairs}
                  action={handleChangeHidden}
                />
              </ShowAt>
              <S.BuySellWrapper>
                <Checkbox backgroundColor="green" title="Buy" checked={filters.onlyBuy} action={handleChangeBuy} />
                <Checkbox backgroundColor="red" title="Sell" checked={filters.onlySell} action={handleChangeSell} />
              </S.BuySellWrapper>
            </S.ContainerActions> */}
          </S.WrapperActions>
        </S.Header>
        <TabPanel>
          <OpenOrderTable data={openOrderData} remove={remove} filters={filters} />
        </TabPanel>
        <TabPanel>
          <TransactionTable remove={remove} filters={filters} />
        </TabPanel>
      </Tabs>
      {/* <Toast /> */}
      <RecentTrades />
    </S.Section>
  )
}

export default Transactions
