import {
  contractReader,
  createContract,
  getTokenDecimals,
  getValueByMultiProps,
  newContractReader,
  newGetContractAddress,
} from 'utils/contractReader'
import { ethToWei, WeiToEth } from 'utils/web3Service'
import moment from 'moment'
import { getTokenAllownce, isAddressApprovedOnAsset, newApproveSpendOnAsset } from 'utils/assetsService'
import ERC20 from 'utils/contracts/ERC20.json'
import callTx from 'utils/txService'
import { UPDATE_BALANCE } from 'hooks/useTokenBalance'
import { eventSetup } from 'utils/events'
import { fetchChartStats } from 'utils/orderbookService'
import {
  getDetailedOrderInfo,
  getPrevOpenOrders,
  appendOrderId,
  getPrevCompletedOrders,
} from 'utils/tradeOrdersService'
import { createStableCoinContract } from 'utils/contractsRegistryService'
import { newDisplayFloats } from 'services/format'
import { MAX_SAFE_VIEWED_DECIMALS } from 'utils/constants/general'
import { safeMul } from 'utils/math'
import * as orderbookActionTypes from '../actionTypes/orderbookActionTypes'
import { clearInputs } from './inputActions'
import { createNotification, setBuyLoading, setSellLoading } from './uiActions'
import { setBalance } from './balanceActions'

export const updateLoadingState = (loadingKey, isLoading) => (dispatch) =>
  dispatch({ type: orderbookActionTypes.UPDATE_LOADING_STATE, payload: { key: loadingKey, isLoading } })

export const setBuyOrders = (HPoolToken) => async (dispatch, getState) => {
  const { MatchingMarket } = getState().contracts
  const { BUSD } = getState().contracts
  const { MakerOtcSupportMethods } = getState().contracts

  const sellTokenAddress = BUSD.address
  const buyTokenAddress = HPoolToken.address

  const buyOffers = await contractReader(MakerOtcSupportMethods, 'getOffers(address,address,address)', [
    MatchingMarket.address,
    sellTokenAddress,
    buyTokenAddress,
  ])
  if (!buyOffers.error) {
    const buyOrders = []
    let currentId = buyOffers.ids[0] // to check if we have a valid tradeOrder
    let currentIndex = 0

    while (currentId !== '0') {
      const buyAmt = Number(WeiToEth(buyOffers.buyAmts[currentIndex]))
      const payAmt = Number(WeiToEth(buyOffers.payAmts[currentIndex]))
      const id = buyOffers.ids[currentIndex]
      const owner = buyOffers.owners[currentIndex]

      const timestamp = buyOffers.timestamps[currentIndex]
      const date = moment.unix(timestamp).format('MMM DD YYYY hh:mm:ss')
      const buyOrder = {
        buyAmt,
        payAmt,
        id,
        owner,
        date,
        coin: HPoolToken.name,
        coinImage: HPoolToken.image,
        price: payAmt / buyAmt,
        totalStableCoin: payAmt / buyAmt,
        amount: buyAmt,
        total: payAmt,
        side: 'buy',
        loading: false,
        contractAddress: HPoolToken.address,
      }
      buyOrders.push(buyOrder)

      currentIndex += 1
      currentId = buyOffers.ids[currentIndex]
    }

    dispatch({
      type: orderbookActionTypes.SET_BUY_ORDERS,
      payload: buyOrders,
    })
  } else {
    // dispatch(createNotification('error', 'Something went wrong with the tx', 4000))
    console.warn(buyOffers.error)
  }
}

export const fetchExecutedOrders = () => async (dispatch, getState) => {
  try {
    dispatch(updateLoadingState('executedOrder', true))
    const { account } = getState().wallet

    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
    const allPrevLogTakes = (await getPrevCompletedOrders()) || []

    let formattedOpenOrders = await Promise.all(
      allPrevLogTakes.map((ev) => getDetailedOrderInfo(ev, matchingMarketContractAddress, account)),
    )
    formattedOpenOrders = formattedOpenOrders.map((detailedInfo, index) => ({
      ...allPrevLogTakes[index],
      ...detailedInfo,
    }))
    const allOrders = await Promise.all(
      formattedOpenOrders.map((order) => appendOrderId(order, matchingMarketContractAddress)),
    )
    dispatch(updateLoadingState('executedOrder', false))

    dispatch({ type: orderbookActionTypes.SET_EXECUTED_ORDERS, payload: allOrders })
  } catch (error) {
    console.log({ fetchExecutedOrdersError: error })
  }
}

const clearPrevOrders = () => (dispatch) => {
  dispatch({ type: orderbookActionTypes.SET_PENDING_ORDERS, payload: [] })
  dispatch({ type: orderbookActionTypes.SET_EXECUTED_ORDERS, payload: [] })
}

export const fetchOpenOrders = (network) => async (dispatch) => {
  dispatch(clearPrevOrders())

  dispatch(updateLoadingState('pendingOrders', true))
  const openOrders = (await getPrevOpenOrders()) ?? []
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket', network)

  let formattedOpenOrders = await Promise.all(
    openOrders.map((ev) => getDetailedOrderInfo(ev, matchingMarketContractAddress)),
  )
  formattedOpenOrders = formattedOpenOrders.map((detailedInfo, index) => ({ ...openOrders[index], ...detailedInfo }))
  dispatch(updateLoadingState('pendingOrders', false))
  dispatch({ type: orderbookActionTypes.SET_PENDING_ORDERS, payload: formattedOpenOrders })
}

export const fetchAllOrders = (network) => async (dispatch) => {
  try {
    dispatch(fetchOpenOrders(network))
    dispatch(fetchExecutedOrders(network))
  } catch (error) {
    console.log({ fetchAllOrdersErr: error })
  }
}

export const setSellOrders = (HPoolToken) => async (dispatch, getState) => {
  const { MatchingMarket } = getState().contracts
  const { BUSD } = getState().contracts
  const { MakerOtcSupportMethods } = getState().contracts

  const sellTokenAddress = HPoolToken.address
  const buyTokenAddress = BUSD.address

  const sellOffers = await contractReader(MakerOtcSupportMethods, 'getOffers(address,address,address)', [
    MatchingMarket.address,
    sellTokenAddress,
    buyTokenAddress,
  ])
  if (!sellOffers.error) {
    const sellOrders = []
    let currentId = sellOffers.ids[0] // to check if we have a valid tradeOrder
    let currentIndex = 0

    while (currentId !== '0') {
      const buyAmt = Number(WeiToEth(sellOffers.buyAmts[currentIndex]))
      const payAmt = Number(WeiToEth(sellOffers.payAmts[currentIndex]))
      const id = sellOffers.ids[currentIndex]
      const owner = sellOffers.owners[currentIndex]
      const timestamp = sellOffers.timestamps[currentIndex]
      const date = moment.unix(timestamp).format('MMM DD YYYY hh:mm:ss')

      const sellOrder = {
        buyAmt,
        payAmt,
        id,
        owner,
        date,
        coin: HPoolToken.name,
        coinImage: HPoolToken.image,
        price: buyAmt / payAmt,
        amount: payAmt,
        total: buyAmt,
        side: 'sell',
        loading: false,
        contractAddress: HPoolToken.address,
      }

      sellOrders.push(sellOrder)

      currentIndex += 1
      currentId = sellOffers.ids[currentIndex]
    }

    dispatch({
      type: orderbookActionTypes.SET_SELL_ORDERS,
      payload: sellOrders,
    })
  } else {
    // dispatch(createNotification('error', 'Something went wrong with the tx', 4000))
    console.warn(sellOffers.error)
  }
}

const createOffer = async (
  signerAddress,
  baseTokenAmount, // in case of buy will be the stable coin otherwise it's targeted pool token
  baseTokenAddress,
  toTokenAmount, // in case of buy will be the targeted pool token otherwise it's stable coin
  tokTokenAddress,
) => {
  const baseTokenDecimals = await getTokenDecimals(baseTokenAddress)
  baseTokenAmount = ethToWei(baseTokenAmount, baseTokenDecimals)

  const toTokenDecimals = await getTokenDecimals(tokTokenAddress)
  toTokenAmount = ethToWei(toTokenAmount, toTokenDecimals)

  return newContractReader({
    contractName: 'MatchingMarket',
    functionName: 'offer',
    params: [baseTokenAmount, baseTokenAddress, toTokenAmount, tokTokenAddress, 0],
    sendParams: { from: signerAddress },
  })
}

const upadteBalances = (stableCoinAddress, currentHPoolTokenContract) => {
  eventSetup.trigger(UPDATE_BALANCE, { updatedTokenAddress: stableCoinAddress })
  eventSetup.trigger(UPDATE_BALANCE, { updatedTokenAddress: currentHPoolTokenContract })
}

export const makeBuyOrder = (stableCoinAmount, hPoolTokenAmount) => async (dispatch, getState) => {
  if (stableCoinAmount <= 0 || hPoolTokenAmount <= 0)
    return dispatch(createNotification('error', 'Please enter an amount greater than 0', 4000))
  try {
    dispatch(setBuyLoading())
    // check allowance of matching market address on busd token
    const { stableCoinInfo } = getState().wallet
    const { symbol, address: stableCoinAddress } = stableCoinInfo
    const stableCoinContract = new window._web3.eth.Contract(ERC20.abi, stableCoinAddress)
    window.stableCoinContract = stableCoinContract

    const signerAddress = getState().wallet.account
    const busdContract = getState().contracts.BUSD
    const currentHPoolTokenContract = getState().tradingPair?.currentHPoolToken

    const currentHPoolTokenContractAddress = getValueByMultiProps(currentHPoolTokenContract, [
      'address',
      'contract_address',
    ])
    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')

    const totalCharged = safeMul(stableCoinAmount, hPoolTokenAmount)
    // Approving Stable coint spending
    await newApproveSpendOnAsset(signerAddress, matchingMarketContractAddress, stableCoinAddress, totalCharged)

    const busdAmountInWei = ethToWei(stableCoinAmount)
    const hPoolTokenAmountInWei = ethToWei(hPoolTokenAmount)

    const busdBalanceFromWalletAddress = await contractReader(busdContract, 'balanceOf', [signerAddress])
    // If user tries to make a tx with more BUSD than they have in their wallet, throw an error
    if (+busdBalanceFromWalletAddress < +busdAmountInWei) {
      throw new Error('You do not have enough BUSD to complete this transaction')
    }

    // if not enough allowance approve matching market address on busd contract
    // await callTx(busdContract, 'approve', [matchingMarketContract.address, busdAmountInWei], { from: signerAddress })
    // await newApproveSpendOnAsset(signerAddress, matchingMarketContract.address, address, busdAmount)
    await createOffer(
      signerAddress,
      totalCharged,
      stableCoinAddress,
      hPoolTokenAmount,
      currentHPoolTokenContractAddress,
    )
    upadteBalances(stableCoinAddress, currentHPoolTokenContractAddress)

    // update tradingPairs
    setTimeout(() => {
      const { currentHPoolToken } = getState().tradingPair
      dispatch(setBuyOrders(currentHPoolToken))
      dispatch(setSellOrders(currentHPoolToken))
      dispatch(setBalance(stableCoinContract, 'busdBalance'))
      dispatch(setBalance(currentHPoolTokenContract, 'currentHPoolTokenBalance'))
      dispatch(setBuyLoading())
      dispatch(clearInputs())
    }, 1000)
  } catch (error) {
    // CATCH
    console.log(error)
    dispatch(setBuyLoading())
    dispatch(clearInputs())
    dispatch(createNotification('error', error.message, 4000))
  }
}

export const makeSellOrder = (stableCoinAmount, hPoolTokenAmount, stableCoinFee) => async (dispatch, getState) => {
  if (stableCoinAmount <= 0 || hPoolTokenAmount <= 0)
    return dispatch(createNotification('error', 'Please enter an amount greater than 0', 4000))
  try {
    dispatch(setSellLoading())
    // check allowance of matching market address on busd token
    const { stableCoinInfo } = getState().wallet
    const { symbol, address: stableCoinAddress } = stableCoinInfo
    const stableCoinContract = new window._web3.eth.Contract(ERC20.abi, stableCoinAddress)

    const signerAddress = getState().wallet.account

    const busdAmountInWei = ethToWei(stableCoinAmount)
    const hPoolTokenAmountInWei = ethToWei(hPoolTokenAmount)

    // const stableCoinContract = getState().contracts.BUSD
    const currentHPoolTokenContract = getState().tradingPair?.currentHPoolToken

    const currentHPoolTokenContractAddress = getValueByMultiProps(currentHPoolTokenContract, [
      'address',
      'contract_address',
    ])
    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')

    await newApproveSpendOnAsset(
      signerAddress,
      matchingMarketContractAddress,
      currentHPoolTokenContractAddress,
      hPoolTokenAmount,
    )
    const totalCharged = safeMul(stableCoinAmount, hPoolTokenAmount)

    await newApproveSpendOnAsset(signerAddress, matchingMarketContractAddress, stableCoinAddress, totalCharged)
    await createOffer(
      signerAddress,
      hPoolTokenAmount,
      currentHPoolTokenContractAddress,
      totalCharged,
      stableCoinAddress,
    )
    upadteBalances(stableCoinAddress, currentHPoolTokenContractAddress)

    // const isCurrentTokenApproved = await isAddressApprovedOnAsset(
    //   signerAddress,
    //   matchingMarketContract.address,
    //   currentHPoolTokenContract,
    //   hPoolTokenAmount,
    // )

    // const currentHpoolTokenSymbol = await contractReader(currentHPoolTokenContract, 'symbol')
    // const hpoolTokenBalanceFromWalletAddress = await contractReader(currentHPoolTokenContract, 'balanceOf', [
    //   signerAddress,
    // ])

    // // convert values to wei
    // const stableCoinAmount = ethToWei(busdAmount)
    // const busdFeeInWei = ethToWei(stableCoinFee)
    // const hPoolTokenAmountInWei = ethToWei(hPoolTokenAmount)

    // // If user doesnt have enough hpool tokens, throw error and notify them
    // if (+hpoolTokenBalanceFromWalletAddress < +hPoolTokenAmountInWei) {
    //   throw new Error(`You do not have enough ${currentHpoolTokenSymbol} tokens`)
    // }

    // // if not enough allowance approve matching market address on busd contract
    // await callTx(currentHPoolTokenContract, 'approve', [matchingMarketContract.address, hPoolTokenAmountInWei], {
    //   from: signerAddress,
    // })

    // if (!isBusdFeeApproved) {
    //   await callTx(stableCoinContract, 'approve', [matchingMarketContract.address, busdFeeInWei], {
    //     from: signerAddress,
    //   })
    // }

    // await callTx(
    //   matchingMarketContract,
    //   'offer(uint256,address,uint256,address,uint256)',
    //   [hPoolTokenAmountInWei, currentHPoolTokenContract.address, stableCoinAmount, stableCoinContract.address, 0],
    //   { from: signerAddress },
    // )

    // update tradingPairs
    setTimeout(() => {
      const { currentHPoolToken } = getState().tradingPair
      dispatch(setBuyOrders(currentHPoolToken))
      dispatch(setSellOrders(currentHPoolToken))
      dispatch(setBalance(stableCoinContract, 'busdBalance'))
      dispatch(setBalance(currentHPoolTokenContract, 'currentHPoolTokenBalance'))
      dispatch(setSellLoading())
      dispatch(clearInputs())
    }, 3000)
  } catch (error) {
    // CATCH ERROR
    console.log({ sellOrderError: error })
    dispatch(setSellLoading())
    dispatch(clearInputs())
    dispatch(createNotification('error', error.message, 4000))
  }
}

export const getChartStatsAction = (poolAddress) => async (dispatch) => {
  try {
    dispatch({ type: orderbookActionTypes.SET_CHART_STATS_REQUEST })
    const statsRes = await fetchChartStats(poolAddress)
    dispatch({ type: orderbookActionTypes.SET_CHART_STATS_SUCCESS, payload: statsRes.stats })
  } catch (error) {
    dispatch({ type: orderbookActionTypes.SET_CHART_STATS_FAILURE, payload: error })
  }
}

export const setAvailableOpenOrders =
  (orders = []) =>
  async (dispatch) => {
    dispatch({ type: orderbookActionTypes.SET_AVAILABLE_OPEN_ORDERS, payload: orders })
  }
