/* eslint-disable no-debugger */
import React, { useMemo, useState, useEffect } from 'react'
import trans from 'services/translate/translate'
import { Box, Text } from 'styles/layouts'
import { arraySort } from 'utils/arraySort'
import clsx from 'clsx'
import './Table.scss'
import SkeletonWrapper, { ISkeletonWrapper } from '../SkeletonWrapper/SkeletonWrapper'
import ShowAt from '../ShowAt/ShowAt'

const Up = () => <span className="font-white">&#11014;</span>
const Down = () => <span className="font-white">&#11015;</span>

export interface ITable {
  dataSourse: any[]
  cols: any[]
  rowClass?: string
  hiddenCols?: string[]
  hiddenTitles?: string[]
  hideHeader?: boolean
  loading?: boolean
  sortable?: boolean
  noDataText?: string
  onSort?: (value: boolean) => void
  renderNoData?: any
  defaultSortProp?: string
  defaultIsAsc?: boolean
  expandable?: boolean
  showPerPage?: number
  idProp?: string
  renderExpand?: (record: any) => JSX.Element
  expandAllRef?: React.MutableRefObject<HTMLButtonElement>
  className?: string
  skeletonWrapperProps?: ISkeletonWrapper
  onRecordClick?: (record) => any
  paginated?: boolean
}

export default function Table({
  dataSourse = [],
  showPerPage = 5,
  cols = [],
  rowClass = 'table-row',
  hiddenCols = [],
  hiddenTitles = [],
  hideHeader = false,
  idProp = 'id',
  noDataText = 'Nothing to show here yet',
  sortable,
  defaultSortProp,
  defaultIsAsc,
  loading,
  onSort,
  expandable,
  renderExpand,
  expandAllRef,
  className,
  skeletonWrapperProps,
  paginated,
  onRecordClick: passedOnRecordClick = () => {},
}: ITable) {
  const [sortProp, setSortProp] = useState(defaultSortProp || '')
  const [isAsc, setIsAsc] = useState<boolean>(defaultIsAsc || false)
  const [selectedRecord, setSelectedRecord] = useState<string[]>([])
  const [shownRecords, setShownRecords] = useState(7)

  const isSelectedRecord = (record) => selectedRecord.includes(record?.[idProp])
  const removeFromSelectedRecords = (reocrd) => selectedRecord.filter((id) => id !== reocrd?.[idProp])
  const addToSelectedRecords = (reocrd) => [...selectedRecord, reocrd?.[idProp] || '']

  const onRecordClick = (record) => {
    passedOnRecordClick(record)
    const isIncluded = isSelectedRecord(record)
    const newSelectedRecords = isIncluded ? removeFromSelectedRecords(record) : addToSelectedRecords(record)
    setSelectedRecord(newSelectedRecords)
  }
  const toggleSort = () => setIsAsc(!isAsc)

  const onColClick = (col) => {
    setSortProp(col?.[idProp])
    onSort(!isAsc)
    toggleSort()
  }
  const getAllRecords = () => dataSourse.map((record) => record?.[idProp])
  const areAllExpanded = selectedRecord.length === dataSourse.length

  useEffect(() => {
    if (!expandAllRef?.current) return
    expandAllRef.current.onclick = () => {
      if (areAllExpanded) {
        setSelectedRecord([])
        expandAllRef.current.textContent = 'Expand All'
      } else {
        setSelectedRecord(getAllRecords())
        expandAllRef.current.textContent = 'Collapse All'
      }
    }
  }, [expandAllRef?.current, areAllExpanded])

  const isStr = typeof dataSourse?.[0]?.[sortProp] === 'string'

  const sortedDataSrc = useMemo(
    () =>
      sortProp
        ? arraySort(dataSourse, sortProp, {
            isAsc,
            isString: isStr,
          } as any)
        : dataSourse,
    [dataSourse, sortProp, isAsc],
  )

  const renderedCols = useMemo(() => cols.filter((col) => !hiddenCols.includes(col?.[idProp])), [hiddenCols, cols])
  const isHiddenTitle = (col) => hiddenTitles.includes(col?.title)
  const renderedTitle = (col) => (isHiddenTitle(col?.[idProp]) ? '' : col.title && trans(col.title, false, false))

  const expandIcon = (record) => (isSelectedRecord(record) ? 'arrow-up' : 'arrow-down')

  const colsLength = expandable ? renderedCols.length + 1 : renderedCols.length

  const hintClass = 'd-flex'

  const createKey = (idProp: string, index: number) => `${idProp} ${index}`

  const handleShowMore = () => setShownRecords((c) => c + showPerPage)

  const renderedRecords = useMemo(
    () => (!paginated ? sortedDataSrc : [...sortedDataSrc]?.slice(0, shownRecords)),
    [sortedDataSrc, shownRecords],
  )

  return (
    <div className={clsx('table-wrapper', className)}>
      <ShowAt at={!loading}>
        <table>
          {!hideHeader && (
            <thead className={clsx({ 'hide-at-m-screen': expandable })}>
              <tr>
                {renderedCols.map((col, index) => (
                  <th key={col.title || index} className={col.thClass}>
                    <div
                      className={clsx('align-center d-flex gap-sm', {
                        'justify-end': col.thClass?.includes('text-right'),
                      })}
                      onClick={() => onColClick(col)}
                    >
                      <span className="font-white">{col.renderTitle ? col.renderTitle() : renderedTitle(col)}</span>
                      {sortable && sortProp === col?.[idProp] && <>{isAsc ? <Up /> : <Down />}</>}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          {!!renderedRecords.length && (
            <tbody>
              {renderedRecords.map((record, index) => (
                <>
                  <tr
                    onClick={() => onRecordClick(record)}
                    key={createKey(idProp, index)}
                    className={clsx(rowClass, { expanded: isSelectedRecord(record) })}
                  >
                    {renderedCols.map((col: any, index) => (
                      <td className={col.tdClass} key={col?.[idProp] || index}>
                        {col.render?.(record, record?.[col?.[idProp]]) || record?.[col?.[idProp]] || col.default}
                      </td>
                    ))}
                  </tr>
                  {/* TODO: add hidden elem (absolute) to cover gap between expanded and it's parent */}
                </>
              ))}
            </tbody>
          )}
        </table>
      </ShowAt>

      <ShowAt at={!!noDataText && !renderedRecords.length && !loading}>
        <Text sm className="d-block text-center my-12 font-light">
          {trans(noDataText)}
        </Text>
      </ShowAt>
      <ShowAt at={dataSourse?.length > renderedRecords.length && !loading && paginated}>
        <Text onClick={handleShowMore} centered reg className="mt-3">
          <Text opacity={0.7} underline className="link-hover-animation pointer" reg>
            Show More
          </Text>
        </Text>
      </ShowAt>

      <div className="flex flex-center-x w-100 text-light text-center">
        <SkeletonWrapper
          marginTop="0.5rem"
          loading={loading}
          count={3}
          wrapperClass="d-flex flex-column gap-md"
          minHeight="3vh"
          {...skeletonWrapperProps}
        />
      </div>
    </div>
  )
}
