import useCurrentStableCoin from 'hooks/useCurrentStableCoin'
import { usePromise } from 'hooks/usePromise'
import { UPDATE_BALANCE } from 'hooks/useTokenBalance'
import moment from 'moment'
import React, { useCallback } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { setBalance } from 'store/actions/balanceActions'
import { cancelOpenOrder } from 'store/actions/transactionActions'
import { createNotification } from 'store/actions/uiActions'
import { Flex } from 'styles/layouts'
import { getValueByMultiProps } from 'utils/contractReader'
import { eventSetup } from 'utils/events'
import { cancelOrderRequest } from 'utils/tradeOrdersService'
import CircularProgress from '../CircularProgress'
import Icon from '../Icon'
import { OpenOrderProps } from '../OpenOrderTable'
import * as S from './styles'

type Props = {
  cancelOpenOrder: (orderId: number | string) => void
  order?: OpenOrderProps
  index: number
  loading: boolean
  findPoolByAddress: (address: string) => any
}

const OpenOrder = ({ findPoolByAddress, order, index, cancelOpenOrder, loading }: Props) => {
  const dispatch = useDispatch()
  const { account, busdContract, poolTokenContract } = useSelector((state: any) => ({
    account: state.wallet.account,
    busdContract: state.contracts?.BUSD,
    poolTokenContract: state.contracts?.currentHPoolTokenContract,
  }))
  const [{ symbol: stableCoinSymbol, address: stableCoinAddress }] = useCurrentStableCoin()
  const poolContractAddress = getValueByMultiProps(poolTokenContract, ['address', 'contract_address'])
  // const cancelOrder = useCallback(() => cancelOpenOrder(order.id), [order])
  const [cancelOrder, , isCanceling] = usePromise(cancelOrderRequest)

  const handleCancelOrder = () =>
    cancelOrder(+order.id, account).then(() => {
      eventSetup.trigger(UPDATE_BALANCE, { updatedTokenAddress: stableCoinAddress })
      eventSetup.trigger(UPDATE_BALANCE, { updatedTokenAddress: poolContractAddress })
      dispatch(setBalance(busdContract, 'busdBalance'))
      dispatch(setBalance(poolTokenContract, 'currentHPoolTokenBalance'))
      dispatch(createNotification('success', 'Order cancelled successfully', 4000))
    })

  const price = order.totalStableCoin / order.originalAmt
  const leftAmt = order.total

  return (
    <S.Tr key={index}>
      <S.Td>
        <S.Tag>Date</S.Tag>
        <S.Status>{moment(order.date).fromNow()}</S.Status>
      </S.Td>

      <S.Td>
        <S.Tag>Pair</S.Tag>
        <S.ContainerFlex>
          <span>{`${order?.coin}/ ${order?.stableCoin?.symbol}`}</span>
        </S.ContainerFlex>
      </S.Td>

      <S.Td>
        <S.Tag>Side</S.Tag>
        <S.ContainerFlex>
          <span
            style={{
              marginRight: '1rem',
              fontWeight: 500,
              color: order.side === 'sell' ? '#FF5353' : '#16C79A',
            }}
          >
            {order.side === 'sell' ? 'Sell' : 'Buy'}
          </span>
        </S.ContainerFlex>
      </S.Td>

      <S.Td>
        <S.Tag>Price</S.Tag>
        <span>${price.toFixed(2)}</span>
      </S.Td>

      <S.Td>
        <S.Tag>Amount</S.Tag>
        <span>{leftAmt.toFixed(2)}</span>
      </S.Td>

      <S.Td>
        {isCanceling ? (
          <CircularProgress />
        ) : (
          <Flex className="pointer" onClick={handleCancelOrder}>
            <div>
              <Icon name="Cancel" />
            </div>
            <S.Button type="button">Cancel</S.Button>
          </Flex>
        )}
      </S.Td>
    </S.Tr>
  )
}

const mapDispatchToProps = {
  cancelOpenOrder,
}

export default connect(undefined, mapDispatchToProps)(OpenOrder)
