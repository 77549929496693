import React from 'react'
import * as S from './styles'

export default function LatestTx({
  latestTransactionType,
  latestTransaction,
}: {
  latestTransactionType: string
  latestTransaction: string
}) {
  return <S.LastTransaction>{latestTransaction}</S.LastTransaction>
}
