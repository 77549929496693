import {
  storeNetworkId,
  setupWeb3,
  setupWssWeb3Provider,
  storedNetworkInfo,
  getCurrentChainReq,
} from 'utils/web3Service'
/* eslint-disable import/no-cycle */
import configJson from '../../config/config.json'
import { web3WalletConnector } from '../../utils/walletService'
import {
  CONNECT_WALLET_START,
  CONNECT_WALLET_END,
  CONNECT_WALLET_PROVIDER,
  CONNECT_WALLET_PROVIDER_SUCCESS,
  CONNECT_WALLET_PROVIDER_FAILURE,
  CLEAR_ACCOUNT,
  UPDATE_BALANCE,
  SET_STABLE_COIN_INFO,
} from '../actionTypes/walletActionTypes'
import { LS_ACCOUNT } from '../../utils/constants/general'
// import { closeModal } from './modalActions';
import { wait } from '../../utils/utilsService'

const postLogin = () => () => {}

export const onWalletConnectRequestAction = (accountType) => (dispatch) => {
  dispatch({ type: CONNECT_WALLET_START, payload: accountType })
  dispatch({ type: CONNECT_WALLET_PROVIDER })
}

export const setStableCoinInfo = (stableCoinInfo: { symbol: string; address: string }) => (dispatch) => {
  dispatch({ type: SET_STABLE_COIN_INFO, payload: stableCoinInfo })
}

export const onWalletConnectSuccessAction =
  ({ provider, account, connectionType }) =>
  async (dispatch, getState) => {
    setupWeb3(provider)
    const chainId = await getCurrentChainReq()
    ;(window as any).chainId = chainId
    setupWssWeb3Provider(chainId)
    localStorage.setItem(LS_ACCOUNT, connectionType)
    dispatch({
      type: CONNECT_WALLET_PROVIDER_SUCCESS,
      payload: {
        account,
        accountType: connectionType,
        network: chainId,
      },
    })

    if (getState().wallet.account) await dispatch(postLogin())

    dispatch({ type: CONNECT_WALLET_END })
  }

export const onWalletConnectErrorAction = (err) => (dispatch) => {
  dispatch({ type: CONNECT_WALLET_PROVIDER_FAILURE, payload: err.message })
  setupWeb3()
}

export const onWalletDisconnectAction = () => (dispatch) => {
  dispatch({ type: CLEAR_ACCOUNT })
  localStorage.removeItem(LS_ACCOUNT)
}

export const normalLogin =
  (accountType?: any, passedNetworkInfo?: any): Function =>
  async (dispatch, getState) => {
    dispatch({ type: CONNECT_WALLET_START, payload: accountType })
    dispatch({ type: CONNECT_WALLET_PROVIDER })

    try {
      if (!web3WalletConnector.mounted) {
        await wait(500)
      }
      const networkInfo = passedNetworkInfo
      const usedChain = networkInfo?.networkId || configJson.network

      const { provider, account } = await web3WalletConnector.connect({
        rpcUrl: networkInfo.rpcUrl,
        networkId: networkInfo.network,
        connectionType: accountType,
      })

      setupWeb3(provider)
      setupWssWeb3Provider(usedChain)

      localStorage.setItem(LS_ACCOUNT, web3WalletConnector.connectionType)

      dispatch({
        type: CONNECT_WALLET_PROVIDER_SUCCESS,
        payload: {
          account,
          accountType: web3WalletConnector.connectionType,
          network: networkInfo.network,
        },
      })
    } catch (err) {
      dispatch({ type: CONNECT_WALLET_PROVIDER_FAILURE, payload: err.message })
      setupWeb3()
    }

    // dispatch(setLsValuesToReducer());
    if (getState().wallet.account) await dispatch(postLogin())

    dispatch({ type: CONNECT_WALLET_END })
  }

/**
 * If the user has already once successfully added an account this will
 * try a silent login for that account type.
 *
 * @return {Function}
 */
export const silentLogin = () => async (dispatch, getState) => {
  const { accountType, connectingWallet, account } = getState().wallet
  console.log('SILENT_LOGIN', accountType)
  if (!accountType || connectingWallet || account) return

  dispatch({ type: CONNECT_WALLET_START, payload: accountType })

  try {
    if (accountType) {
      await dispatch(normalLogin(accountType))
    }
  } catch (err) {
    setupWeb3()
  }

  if (getState().wallet.account) await dispatch(postLogin())

  // dispatch(setLsValuesToReducer());
  dispatch({ type: CONNECT_WALLET_END })
}

export const logOut = () => async (dispatch, getState) => {
  const { modalType } = getState().modal
  dispatch({ type: CLEAR_ACCOUNT })
  if (modalType !== '') {
    // dispatch(closeModal());
  }
  dispatch(normalLogin())
  localStorage.removeItem(LS_ACCOUNT)
}

/**
 * Use it to connect wallet on not farm pages.
 * @param payload
 * @return {(function(*, *): Promise<void>)|*}
 */
export const switchNetwork = (payload) => async (dispatch, getState) => {
  const { accountType, additions = {} } = getState().wallet
  storeNetworkId(payload.networkId)

  await web3WalletConnector.connect({
    rpcUrl: payload?.rpcUrl,
    networkId: payload?.networkId,
    connectionType: accountType,
    ...additions,
  })
}

export const updateBalance = (address: string, balance: number) => ({
  type: UPDATE_BALANCE,
  payload: {
    address,
    balance,
  },
})
