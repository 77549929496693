import { getValueByMultiProps } from "./contractReader"

const errorStatuses = [400, 401, 403, 500, 503]

export const handleResponse = (response, reload = true, config = { notifyError: false, returnStatus: false }) =>
  new Promise((resolve, reject) => {
    response.text().then((text) => {
      const { status } = response
      const data = text && JSON.parse(text)
      if (errorStatuses.includes(status)) {
        const error =
          getValueByMultiProps(data?.error || data, ['error_message', 'message', 'code']) || 'Failed To Fetch'
        reject(error)
        const errorPayload = config.returnStatus ? { status, data } : data
        return Promise.reject(errorPayload)
      }
      resolve(data)
    })
  })
