import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

export default function useAvailablePairs() {
  const { poolsList = [] } = useSelector((state: any) => ({
    poolsList: state.tradingPair.hPoolTokensList || [],
  }))
  const poolAddresses = poolsList.map((pool) => pool?.address) ?? []

  /**
   * isAvailablePool is created to handle tokens addresses changes after deployments (hide orders that were on previous addresses)
   */
  const isAvailablePool = useCallback(
    (tokenAddress: string) => poolAddresses.includes(tokenAddress),
    [poolAddresses.length],
  )
  return [isAvailablePool]
}
