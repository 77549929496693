import { assetAmountInEth } from 'utils/assetsService'
import { newContractReader } from 'utils/contractReader'
import { networks } from 'config/networks.json'
import { isMatched } from 'utils/validator'

export const getTokenBalance = async (wallet, tokenAddress, chain) => {
  if (!wallet) return 0
  // @ts-ignore
  let result = await newContractReader({
    contractName: 'ERC20',
    functionName: 'balanceOf',
    params: [wallet],
    contractAddress: tokenAddress,
    chainId: chain,
  })
  result = +assetAmountInEth(result) || 0
  // result = formatNumber(result)
  return result
}
;(window as any).getTokenBalance = getTokenBalance

export const getStableCoinNetworkByAddress = (tokenAddress) =>
  networks.find((network) => isMatched(network.stableCoin.address, tokenAddress))
