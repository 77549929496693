interface ISortConf {
  isAsc: boolean
  isString: boolean
  process?: (A: any, B: any, isAsc: boolean) => void
}

const isNotStringNorNimber = (value) => !(typeof +value === 'number' || typeof value === 'string')

export const arraySort = (arr = [], compareKey = '', { isAsc, isString, process }: ISortConf) =>
  arr.sort((elemA, elemB) => {
    const B = elemB?.[compareKey] || 0
    const A = elemA?.[compareKey] || 0
    if (isNotStringNorNimber(A) || isNotStringNorNimber(B)) return arr

    if (isString) {
      return isAsc ? A.toLowerCase().localeCompare(B.toLowerCase()) : B.toLowerCase().localeCompare(A.toLowerCase())
    }
    if (process) {
      return process(A, B, isAsc)
    }
    return isAsc ? A - B : B - A
  })
