import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { WalletConnector } from '@dcentralab/web3-wallet-connector'
import { Header as DUFHeader } from '@dcentralab/unified-frontend'
import { getNetworkById, storedNetworkInfo, storeNetworkId } from 'utils/web3Service'
import { eventSetup } from 'utils/events'
import { NETWORK_SWITCH_REQUIRED } from 'utils/networks'
import LogoIcon from './LogoIcon'
import CreatePoolButton from './CreatePoolButton/CreatePoolButton'
import CustomLink from './CustomLink/CustomLink'
import HordButtons from './HordButtons/HordButtons'
import {
  onWalletConnectSuccessAction,
  onWalletDisconnectAction,
  onWalletConnectRequestAction,
  onWalletConnectErrorAction,
  switchNetwork,
} from '../../../store/actions/walletActions'
import { connectToContracts } from '../../../store/actions/contractActions'
import config from '../../../config/config.json'
import { LS_ACCOUNT } from '../../../utils/constants/general'
import { networks } from '../../../config/networks.json'
import TokenBalance from '../TokenBalance/TokenBalance'
import './Header.scss'
import LogoIconWide from './LogoIconWide'
import ChampionsIcon from './SidebarIcons/Champions'
import EthStake from './SidebarIcons/EthStake'
import DexIcon from './SidebarIcons/Dex'
import HomeIcon from './SidebarIcons/Home'
import PortfolioIcon from './SidebarIcons/Portfolio'
import HordPremium from './SidebarIcons/HordPremium'
import VikingDao from './SidebarIcons/VikingDao'
import HordLogoWide from './HordLogoWide'
import { isHiddenRoute } from '../ProtectedRoute/ProtectedRoute'

interface IHeaderProps {
  championData?: object
  isChampionVerified?: boolean
  account?: string
  MatchingMarket?: object
}
interface IHeaderActionProps {
  onWalletConnectSuccess: () => void
  onWalletDisconnect: () => void
  onWalletConnectRequest: () => void
  onWalletConnectError: () => void
  connectToContracts: () => void
  switchNetwork: (payload: any) => void
}

const Header = ({
  onWalletConnectSuccess,
  onWalletDisconnect,
  onWalletConnectRequest,
  onWalletConnectError,
  connectToContracts,
  account,
  switchNetwork,
}: IHeaderProps & IHeaderActionProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [checked, setChecked] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState(storedNetworkInfo || networks[0])

  const currentAppNetwork = useSelector((state: any) => state.wallet.network)

  const onNetworkSelect = useCallback((networkId) => {
    const matchedNetworkInfo = getNetworkById(networkId)
    storeNetworkId(networkId)
    setSelectedNetwork(getNetworkById(networkId))
    switchNetwork({ rpcUrl: matchedNetworkInfo.rpcUrl, networkId: matchedNetworkInfo.networkId })
  }, [])

  const redirectTo = (route) => window.location.replace(`${config.vdaoApp}/${route}`)

  const sidebarLinks = useMemo(
    () =>
      [
        {
          id: 'home',
          text: 'Home',
          icon: <HomeIcon />,
          onClick: () => redirectTo(''),
        },
        {
          id: 'eth-hord',
          text: 'ETH Staking',
          icon: <EthStake />,
          onClick: () => redirectTo('eth-staking'),
        },
        {
          id: 'viking-dao',
          text: 'Viking DAO',
          icon: <VikingDao />,
          onClick: () => redirectTo('pools'),
        },
        {
          id: 'champion-pool',
          text: 'Champions Pool',
          icon: <ChampionsIcon />,
          onClick: () => redirectTo('champion'),
          to: '/champion',
        },
        {
          separator: true,
        },
        {
          id: 'dex',
          text: 'DEX',
          icon: <DexIcon />,
          to: '/',
        },
        {
          id: 'hord-premium',
          text: 'HORD Premium',
          icon: <HordPremium />,
          onClick: () => redirectTo('token'),
          to: '/token',
        },
        {
          separator: true,
        },
        {
          id: 'portfolio',
          text: 'My Portfolio',
          icon: <PortfolioIcon />,
          onClick: () => redirectTo('portfolio'),
        },
      ].filter((link) => !isHiddenRoute(link.to)),
    [],
  )

  const networkSelectorProps = useMemo(
    () => ({
      networks,
      networkId: selectedNetwork.network,
      account,
      onNetworkSelect,
    }),
    [account, onNetworkSelect, selectedNetwork],
  )

  const logo = useMemo(
    () => ({
      onClick: () => window.location.replace(config.vdaoApp),
      img: <LogoIconWide />,
      imgWide: <LogoIcon />,
    }),
    [],
  )

  const emptyLogo = {
    img: '',
  }

  useEffect(() => {
    if (!account || !currentAppNetwork) return
    const currentNetworkInfo = getNetworkById(currentAppNetwork)
    setSelectedNetwork(currentNetworkInfo)
  }, [currentAppNetwork, account])

  // re added account until i figure out why we need it
  useEffect(() => {
    connectToContracts()
  }, [connectToContracts, account])

  useEffect(() => {
    eventSetup.on(NETWORK_SWITCH_REQUIRED, (requiredNet) => {
      onNetworkSelect(requiredNet)
    })
    return () => {
      eventSetup.off(NETWORK_SWITCH_REQUIRED, () => null)
    }
  }, [])

  useEffect(() => {
    // const accountType = localStorage.getItem(LS_ACCOUNT)
    // if (!accountType) {
    //   switchNetwork({ rpcUrl: selectedNetwork.rpcUrl, networkId: selectedNetwork.networkId })
    // }
  }, [])

  const closeMenu = () => {
    if (checked) setChecked(false)
  }

  return (
    <DUFHeader
      logo={logo}
      projectLogo={emptyLogo}
      sidebarLinks={sidebarLinks}
      networkSelectorProps={networkSelectorProps}
      topContent={
        <>
          <div className="sidebar-mobile-logo">
            <HordLogoWide className="scale-on-hover" />
          </div>
        </>
      }
      walletComponent={
        <WalletConnector
          intl={intl}
          networkId={selectedNetwork.network}
          rpcUrl={selectedNetwork.rpcUrl}
          onClick={closeMenu}
          onConnectRequest={onWalletConnectRequest}
          onConnect={onWalletConnectSuccess}
          onConnectError={onWalletConnectError}
          onDisconnect={onWalletDisconnect}
          autoConnectInPageProvider
          showBalance
        />
      }
    >
      <TokenBalance />
    </DUFHeader>
  )
}

const mapStateToProps = (state) => ({
  championData: state.champion?.championData,
  isChampionVerified: state.champion?.championData?.is_verified_champion === 'VERIFIED',
  account: state.wallet.account,
  MatchingMarket: state.contracts.MatchingMarket,
})

const mapDispatchToProps = {
  onWalletConnectSuccess: onWalletConnectSuccessAction,
  onWalletDisconnect: onWalletDisconnectAction,
  onWalletConnectRequest: onWalletConnectRequestAction,
  onWalletConnectError: onWalletConnectErrorAction,
  connectToContracts,
  switchNetwork,
}

// @ts-ignore
export default connect<IHeaderProps, IHeaderActionProps>(mapStateToProps, mapDispatchToProps)(Header)
