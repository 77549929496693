import { isMatched } from 'utils/validator'
import { eventSetup } from 'utils/events'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTokenBalance } from '../services/tokensService'
import { usePromise } from './usePromise'

interface IUseTokenBalance {
  tokenAddress?: string
  disabled?: boolean
}

export const UPDATE_BALANCE = 'UPDATE_BALANCE'

export const useTokenBalance = ({ tokenAddress, disabled }: IUseTokenBalance) => {
  const { account, network } = useSelector((state: any) => ({
    account: state.wallet.account,
    network: state.wallet.network,
  }))
  const [prevTokenAddress, setPrevTokenAddress] = useState('')

  const [refreshTokenBalance, tokenBalance = 0, fetchingBalance, , updateResponse] = usePromise(getTokenBalance)

  const manualUpdateBalance = (newBalance) => updateResponse(newBalance)

  useEffect(() => {
    if (!tokenAddress) manualUpdateBalance(0)
  }, [tokenAddress])

  useEffect(() => {
    if (!tokenAddress || !account || !network || disabled) return
    if (tokenBalance) return // tokenBalance validation is added to prevent duplicate
    setPrevTokenAddress(tokenAddress)
    refreshTokenBalance(account, tokenAddress)
  }, [account, tokenAddress, disabled])

  useEffect(() => {
    if (!tokenBalance) return
    refreshTokenBalance(account, tokenAddress)
  }, [account])

  useEffect(() => {
    if (!account || !tokenAddress || disabled) return
    eventSetup.on(UPDATE_BALANCE, ({ updatedTokenAddress } = { updatedTokenAddress: '' }) => {
      if (isMatched(updatedTokenAddress, tokenAddress)) refreshTokenBalance(account, tokenAddress)
    })
    return () => {
      eventSetup.off(UPDATE_BALANCE, () => { })
    }
  }, [account, tokenAddress, disabled])

  return [tokenBalance, fetchingBalance]
}
