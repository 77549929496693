import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background: #1E1628;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 2.4rem;
`
export const WrapperGraph = styled.section`
  padding: 2rem;
  background-color: #22232d;
`
// Header
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`
export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const List = styled.div`
  display: flex;
  justify-content: space-space-between;
  align-items: center;
`
export const WrapperFilters = styled.div``

// Graph
export const Graph = styled.div``

type ItemProps = {
  selected?: boolean
}

export const Item = styled.div<ItemProps>`
  cursor: pointer;

  :first-child {
    margin-left: 1rem;
  }

  :not(:first-child) {
    margin-right: 1rem;
  }

  :not(:last-child) {
    margin-left: 1rem;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: #ff0087;
      height: 3.6rem;
      width: 3.6rem;
      padding: 0.8rem;
      border-radius: 0.7rem;
    `}
`
