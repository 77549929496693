import { storedNetworkInfo } from 'utils/web3Service'
import * as walletActionTypes from '../../actionTypes/walletActionTypes'
import { networks } from '../../../config/networks.json'

import { LS_ACCOUNT } from '../../../utils/constants/general'

export const defaultNetwork = storedNetworkInfo.networkId || networks[0].networkId

const initialState = {
  connectingWallet: false,
  connectingWalletAccountType: '',

  connectingWalletProvider: false,
  connectingWalletProviderError: '',

  account: '',
  accountType: localStorage.getItem(LS_ACCOUNT) || '',
  stableCoinInfo: {
    symbol: 'BUSD',
    address: '',
  },
  network: defaultNetwork,
}
window.chainId = defaultNetwork

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case walletActionTypes.CONNECT_WALLET_START:
      return {
        ...state,
        connectingWallet: true,
        connectingWalletAccountType: payload,
      }

    case walletActionTypes.CONNECT_WALLET_END:
      return {
        ...state,
        connectingWallet: false,
        connectingWalletAccountType: '',
      }

    case walletActionTypes.CONNECT_WALLET_PROVIDER:
      return {
        ...state,
        connectingWalletProvider: true,
        connectingWalletProviderError: '',
      }

    case walletActionTypes.CONNECT_WALLET_PROVIDER_SUCCESS:
      return {
        ...state,
        connectingWalletProvider: false,
        connectingWalletProviderError: '',
        ...payload,
      }

    case walletActionTypes.SET_STABLE_COIN_INFO:
      return {
        ...state,
        stableCoinInfo: action.payload,
      }

    case walletActionTypes.CONNECT_WALLET_PROVIDER_FAILURE:
      return {
        ...state,
        connectingWalletProvider: false,
        connectingWalletProviderError: payload,
      }

    case walletActionTypes.CLEAR_ACCOUNT:
      return {
        ...state,
        account: '',
        accountType: '',
      }

    default:
      return state
  }
}
