export const SET_BUY_LOADING = 'SET_BUY_LOADING'
export const SET_SELL_LOADING = 'SET_SELL_LOADING'
export const START_ACTION = 'START_ACTION'
export const STOP_ACTION = 'STOP_ACTION'
export const SET_CONNECTION = 'SET_CONNECTION'
export const SET_ERROR = 'SET_ERROR'
export const SET_CONTRACTS_LOADED_ALREADY = 'SET_CONTRACTS_LOADED_ALREADY'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
