import { ETH_CHAINS } from 'hooks/useCurrentStableCoin'
import { vpoolAddressUrlParam } from 'utils/constants/vpool'
import { newGetContractAddress } from 'utils/contractReader'
import { getSearchParam } from 'utils/urlParams'
import { loadHPools } from 'views/dashboard/components/blocks/Navbar/loadHPools'
import { loadPoolList, loadVPools } from 'views/dashboard/components/blocks/Navbar/loadVPools'
import * as tradingPairActionTypes from '../actionTypes/tradingPairActionTypes'
import { startAction, stopAction } from './uiActions'

export const getHethPairsAction = () => async (dispatch, getState) => {
  const currentAppNetwork = getState().wallet.network
  const hethStakingContractAddress = await newGetContractAddress('HordETHStakingManager')
  const isEthChain = ETH_CHAINS.includes(+currentAppNetwork)

  const hethPair = {
    id: '1',
    symbol: 'hETH',
    name: 'hETH',
    address: hethStakingContractAddress,
    image: 'https://hpool-nfts-develop.s3.amazonaws.com/champion/367/f32790616a2ad759c52ea393a59b47b5-1673252752',
    isNotErc20: true,
    contractName: 'HordETHStakingManager',
    chain_id: 5,
  }
  return [hethPair]
}

const clearPrevPoolList = () => (dispatch) => {
  dispatch({
    type: tradingPairActionTypes.SET_HPOOL_TOKENS_LIST,
    payload: [],
  })
  dispatch({
    type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
    payload: null,
  })
}

export const setTradingPairsInformation = () => async (dispatch) => {
  // TODO: pass network here
  try {
    dispatch(clearPrevPoolList())
    dispatch(startAction())

    const extraPairs = await dispatch(getHethPairsAction())
    const poolList = await loadPoolList()

    let mergedPoolList = poolList
    mergedPoolList = [...mergedPoolList, ...extraPairs]
    mergedPoolList = mergedPoolList.flat().filter((pool) => !!pool)

    const getTokenIndex = (hpoolList, tokenAddress) => hpoolList.findIndex((hpool) => hpool.address === tokenAddress)
    const tokenAddressFromPathname = getSearchParam(vpoolAddressUrlParam)

    // Temporariliy hide pools hidden on Hord app. To be removed at some point
    const tempUpdatedPoolList = mergedPoolList?.filter((pool) => !pool?.name?.startsWith('Hord.app'))

    if (tempUpdatedPoolList?.length > 0) {
      dispatch({
        type: tradingPairActionTypes.SET_HPOOL_TOKENS_LIST,
        payload: tempUpdatedPoolList,
      })

      const tokenIndex = getTokenIndex(tempUpdatedPoolList, tokenAddressFromPathname)
      if (tokenIndex > 0) {
        dispatch({
          type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
          payload: tempUpdatedPoolList[tokenIndex],
        })
      } else {
        dispatch({
          type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
          payload: tempUpdatedPoolList[0],
        })
      }
      dispatch(stopAction())
    }
    dispatch(stopAction())
  } catch (error) {
    console.log({ err: error.message })
  }
}

export const changeCurrentHPoolToken = (poolAddress, passedContract) => async (dispatch, getState) => {
  const hPoolToken =
    passedContract || getState().tradingPair?.hPoolTokensList?.find((hPoolToken) => hPoolToken?.address === poolAddress)
  dispatch({
    type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
    payload: hPoolToken,
  })
}

export const setLatestTradePrice = (price) => (dispatch) => {
  dispatch({
    type: tradingPairActionTypes.SET_LATEST_TRADE_PRICE,
    payload: price,
  })
}
