import styled, { css } from 'styled-components'
import { Props } from './index'

export const Tr = styled.tr`
  margin-bottom: 1rem;
  border-radius: 0 1rem 1rem 1rem;
`
export const Td = styled.td`
  font-weight: bold;
  cursor: pointer;
  font-size: 1.4rem;
`
export const ContainerFlex = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, auto);
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
`

export const BusdWrapper = styled.span`
  justify-self: flex-end;
`

export const Span = styled.span<Partial<Props>>`
  ${({ theme, data }) => css`
    color: ${data ? (data.side === 'sell' ? theme.colors.red : theme.colors.green) : ''};
    font-weight: bold;
    cursor: pointer;
    white-space: pre;
    font-size: 1.4rem;
  `}
`
