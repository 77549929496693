import clsx from 'clsx'
import useAvailablePairs from 'hooks/useAvailablePairs'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ICondition, multiFilterArray } from 'utils/array'
import { arraySort } from 'utils/arraySort'
import { getValueByMultiProps } from 'utils/contractReader'
import { isMatched } from 'utils/validator'
import { ITransactionData } from '../../../views/dashboard/components/blocks/Transactions/ITransactions'
import Icon from '../Icon'
import ShowAt from '../ShowAt/ShowAt'
import SkeletonWrapper from '../SkeletonWrapper/SkeletonWrapper'
import TransactionOrder from '../TransactionOrder'
import * as S from './styles'

type Props = {
  data?: ITransactionData[]
  remove?: (id: string) => void
  filters?: {
    hiddenPairs: boolean
    onlyBuy: boolean
    onlySell: boolean
    status: string
  }
}

const cols = [
  {
    title: 'Time',
    prop: 'date',
  },
  {
    title: 'Pair',
    prop: 'pair',
  },
  {
    title: 'Buy/Sell',
    prop: 'side',
  },
  {
    title: 'Price',
    prop: 'price',
  },
  {
    title: 'Amount',
    prop: 'amount',
  },
  {
    title: 'Total',
    prop: 'total',
  },
]

const TransactionTable = ({ remove, filters }: Props) => {
  const [isAvailablePool] = useAvailablePairs()
  const {
    executedOrders = [],
    account,
    poolsList,
    currentHPoolTokenContract,
    completedOrdersLoadingState,
  } = useSelector((state: any) => ({
    poolsList: state.tradingPair.hPoolTokensList || [],
    currentHPoolTokenContract: state.contracts.currentHPoolTokenContract?.address,
    executedOrders: state.orderbook.executedOrders,
    account: state.wallet.account,
    completedOrdersLoadingState: state.orderbook.loadingsState.pendingOrders,
  }))
  const [sortProp, setSortProp] = useState(cols?.[0].prop)
  const [isAsc, setIsAsc] = useState(false)

  const targetedOrder = clsx({ sell: filters.onlySell, buy: filters.onlyBuy })

  const onlyUsersTxs = (tx) => !!account && (isMatched(tx.maker, account) || isMatched(tx.taker, account))

  const getPoolContractAddress = (pool) => getValueByMultiProps(pool, ['contract_address', 'address'])

  const findPoolByAddress = (address: string) =>
    poolsList.find((pool: any) => isMatched(getPoolContractAddress(pool), address))

  const invertOrderType = (orderType) => (orderType === 'buy' ? 'sell' : 'buy')

  const processExecutedOrders = (order) => {
    const { transaction_hash: txHash, coinAddress, taker, side, id } = order ?? {}

    const matchedPool = findPoolByAddress(coinAddress) ?? {}

    const { symbol: coin, hpool_token_icon: coinImage } = matchedPool
    return { ...order, coinImage, coin, txHash, completed: true, side }
  }

  const processedExecutedOrders = useMemo(
    () => executedOrders?.filter(onlyUsersTxs)?.map(processExecutedOrders),
    [executedOrders.length, account, poolsList?.length],
  )

  const filterConditions: ICondition[] = [
    {
      filterAt: filters.onlyBuy && !filters.onlySell,
      filterFunc: (item) => item.side === 'buy',
    },
    {
      filterAt: filters.onlySell && !filters.onlyBuy,
      filterFunc: (item) => item.side === 'sell',
    },
    {
      filterAt: filters.hiddenPairs,
      filterFunc: (item) => isMatched(item.coinAddress, currentHPoolTokenContract),
    },
    {
      filterAt: true,
      filterFunc: (item) => isAvailablePool(item.coinAddress),
    },
  ]

  const filteredOrders = useMemo(
    () => multiFilterArray(processedExecutedOrders, filterConditions),
    [filters, processedExecutedOrders.length, currentHPoolTokenContract],
  )

  const sortedDataSrc = useMemo(
    () =>
      arraySort(filteredOrders, sortProp, {
        isAsc,
        isString: typeof filteredOrders?.[0]?.[sortProp] === 'string',
      }),
    [filteredOrders, sortProp, isAsc],
  )

  const handleColClick = (col) => {
    setSortProp(col.prop)

    if (sortProp === col.prop) {
      setIsAsc(!isAsc)
    } else setIsAsc(true)
  }
  const hasRecords = filteredOrders?.length && poolsList?.length

  return (
    <S.Wrapper>
      <S.Table>
        <ShowAt at={hasRecords}>
          <S.Thead>
            <S.Tr>
              {cols.map((col) => (
                <S.Th key={col.prop} onClick={() => handleColClick(col)}>
                  <S.Title>{col.title}</S.Title>
                  <S.IconWrapper>
                    <Icon name="ArrowTop" style={{ transform: col.prop === sortProp && isAsc && 'rotate(180deg)' }} />
                  </S.IconWrapper>
                </S.Th>
              ))}
              <S.Th />
            </S.Tr>
          </S.Thead>
        </ShowAt>
        <SkeletonWrapper marginTop="0.5rem" count={3} loading={completedOrdersLoadingState} minHeight="3.5vh">
          {hasRecords ? (
            <S.Tbody>
              {sortedDataSrc.length > 0 &&
                sortedDataSrc.map((item) => (
                  <TransactionOrder key={item?.ts} data={item} remove={() => remove(item.id)} />
                ))}
            </S.Tbody>
          ) : (
            <S.EmptyDataWrapper>You have no trade history yet.</S.EmptyDataWrapper>
          )}
        </SkeletonWrapper>
      </S.Table>
    </S.Wrapper>
  )
}

export default TransactionTable
