/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { changeCurrentHPoolToken } from 'store/actions/tradingPairActions'
import { IPairs } from 'views/dashboard/components/blocks/Navbar'
import { fromObjToParam } from 'utils/urlParams'
import { vpoolAddressUrlParam } from 'utils/constants/vpool'
import { getNetworkById } from 'utils/web3Service'
import { getStableCoin } from 'utils/constants/chains'
import { requireNetworkSwitch } from 'utils/networks'
import { switchNetwork } from '../../../store/actions/walletActions'
import Dropdown from '../Dropdown'
import Icon from '../Icon'
import * as S from './styles'
import ThreeDotsLoader from '../ThreeDotsLoader'
import Skeleton from '../Skeleton/Skeleton'
import PairDropdownContent from './PairDropdownContent'

export type NavbarPairProps = {
  coin?: string
  pairs?: IPairs[]
  changeCurrentHPoolToken: (hPoolTokenName: string, passedContract: any) => void
  currentSelectedToken: any
  connected: boolean
  switchNetwork: (payload: object) => void
  loading: boolean
  discount?: number
}

const NavbarPair = ({
  coin,
  pairs,
  // currentHPoolTokenName,
  // currentHPoolTokenImage,
  changeCurrentHPoolToken,
  connected,
  switchNetwork,
  loading,
  currentSelectedToken = {},
  discount,
}: NavbarPairProps) => {
  const {
    name: currentHPoolTokenName,
    image: currentHPoolTokenImage,
    chain_id: selectedTokenChain,
  } = currentSelectedToken || {}
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [state, setState] = useState(currentSelectedToken)
  const [dropdownState, setDropdownState] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const { network } = useSelector((state: any) => state.wallet)
  const {
    stableCoin: { image: stableCoinImage, symbol: stableCoinSymbol },
  } = getNetworkById(selectedTokenChain) || { stableCoin: {} }

  useEffect(() => {
    setState(currentSelectedToken)
  }, [currentSelectedToken?.name])

  const handleDropdownClick = useCallback(
    (newDropdownState) => {
      if (connected) {
        setDropdownState(newDropdownState)
      }
    },
    [setDropdownState, connected],
  )

  const handleSearchChange = (inputValue) => {
    setSearchInput(inputValue)
  }

  const filteredPairs = pairs?.filter(({ name }) => name.toLowerCase().includes(searchInput.toLowerCase()))

  const handleChange = useCallback(
    (select: string, address: string, index: number) => {
      const currentPairChainId = filteredPairs[index]?.chain_id
      setDropdownState(false)
      setState(filteredPairs[index])
      changeCurrentHPoolToken(address, filteredPairs[index])
      requireNetworkSwitch(currentPairChainId)
      const newUrl = pathname + fromObjToParam({ [vpoolAddressUrlParam]: address })
      push(newUrl)
    },
    [changeCurrentHPoolToken, setDropdownState, setState, filteredPairs],
  )

  return (
    <Skeleton loading={loading} minHeight="2rem" minWidth="20rem">
      <S.Wrapper className="navbar-pair">
        <S.WrapperCoin>
          {loading && <ThreeDotsLoader style={{ top: '0', left: '38px', zIndex: 1000 }} />}
          {currentHPoolTokenImage && !loading ? <S.PairImage src={currentHPoolTokenImage} /> : <S.EmptyImg />}
          <S.PairIconWrapper>
            <Icon
              style={{ width: '100%', background: '#fff', borderRadius: '50%' }}
              url={stableCoinImage}
              ext="png"
              folder="images"
            />
          </S.PairIconWrapper>
        </S.WrapperCoin>
        <S.WrapperCoinText>
          <S.Container>
            <S.Name>{state?.name}/</S.Name>
          </S.Container>
          <Dropdown title={stableCoinSymbol} active={dropdownState} setDropdownState={handleDropdownClick} bg="#9D7BE6">
            <>
              <S.DropdownTitle>Hord pool tokens</S.DropdownTitle>
              <PairDropdownContent pairs={filteredPairs} handleChange={handleChange} discount={discount} />
            </>
          </Dropdown>
        </S.WrapperCoinText>
      </S.Wrapper>
    </Skeleton>
  )
}

const mapStateToProps = (state) => ({
  currentSelectedToken: state.tradingPair.currentHPoolToken,
  connected: !!state.wallet.account,
  loading: state.ui.loading,
})

export default connect(mapStateToProps, {
  changeCurrentHPoolToken,
  switchNetwork,
})(NavbarPair)
