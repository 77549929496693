import Web3 from 'web3'
import configJson from '../config/config.json'
import ERC20 from './contracts/ERC20.json'

let contracts

export const createContract = (address, abi) => {
  if (!window._web3) {
    window._web3 = new Web3(configJson.rpcUrl)
  }
  return new window._web3.eth.Contract(abi, address)
}

export const createStableCoinContract = (symbol, address) => ({
  abi: ERC20.abi,
  networks: {
    [window.chainId]: {
      Proxy: address,
    },
  },
})

export const loadContracts = async () => {
  contracts = await fetch(`${configJson.apiPath}/config/contracts`).then((res) => res.json())
  contracts.BUSD = {
    abi: ERC20.abi,
    networks: {
      [configJson.network]: {
        Proxy: configJson.busd,
      },
    },
  }

  const loadedContracts = {}
  Object.keys(contracts).forEach((key) => {
    const { abi, networks } = contracts[key]
    const address = networks[configJson.network]?.Proxy || networks[configJson.network]?.address
    if (address && abi) {
      try {
        const contract = createContract(address, abi)
        loadedContracts[key] = {
          address: contract._address,
          ...contract.methods,
        }
      } catch (err) {
        console.warn(err)
      }
    }
  })

  window.contracts = loadedContracts
  return loadedContracts
}

export const getContractsMeta = async () => {
  if (!contracts) {
    await loadContracts()
  }
  return contracts
}
