import { SET_PENDING_ORDERS } from 'store/actionTypes/orderbookActionTypes'
import callTx from 'utils/txService'
import * as transactionActionTypes from '../actionTypes/transactionActionTypes'
import { setBalance } from './balanceActions'
import { setBuyOrders, setSellOrders } from './orderbookActions'
import { createNotification } from './uiActions'

export const setOpenOrders = (orders) => async (dispatch, getState) => {
  const signerAddress = getState().wallet.account
  const filteredOpenOrders = orders.filter((order) => order.owner.toLowerCase() === signerAddress.toLowerCase())
  dispatch({
    type: transactionActionTypes.SET_OPEN_ORDERS,
    payload: filteredOpenOrders,
  })
}

// Sets a loading state for an order when user tries to cancel it - displays spinner
export const setOrderLoading = (targetedOrderId) => async (dispatch, getState) => {
  const { openOrders } = getState().transactions
  const { pendingOrders } = getState().orderbook

  const newPendingOrders = pendingOrders.map((order) => ({
    ...order,
    loading: +order.id === +targetedOrderId,
  }))
  // dispatch({ type: SET_PENDING_ORDERS, payload: newPendingOrders })

  dispatch({
    type: transactionActionTypes.SET_OPEN_ORDERS,
    payload: newPendingOrders,
  })
}

export const cancelOpenOrder = (orderId) => async (dispatch, getState) => {
  try {
    const parsedInt = parseInt(orderId, 16)
    dispatch(setOrderLoading(parsedInt))
    const signerAddress = getState().wallet.account
    const busdContract = getState().contracts.BUSD
    const matchingMarketContract = getState().contracts.MatchingMarket
    await callTx(matchingMarketContract, 'cancel', [orderId], { from: signerAddress })
    // update tradingPairs
    setTimeout(() => {
      const { currentHPoolToken } = getState().tradingPair
      dispatch(setBuyOrders(currentHPoolToken))
      dispatch(setSellOrders(currentHPoolToken))
      dispatch(setBalance(busdContract, 'busdBalance'))
    }, 3000)
  } catch (error) {
    dispatch(setOrderLoading(orderId))
    dispatch(createNotification('error', error.message, 4000))
    console.warn(error)
  }
}
