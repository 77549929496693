import * as React from 'react'

const PortfolioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M7.59844 1.30787L2.04406 5.56622C1.5435 5.94998 1.81489 6.75 2.44563 6.75H13.5544C14.1851 6.75 14.4565 5.94998 13.9559 5.56622L8.40156 1.30787C8.16466 1.12624 7.83534 1.12624 7.59844 1.30787Z"
      stroke="#928F9C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.25 15.75L13.75 15.75" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.25 9.75V12.75" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 9.75V12.75" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.75 9.75V12.75" stroke="#928F9C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default PortfolioIcon
