/* eslint-disable import/no-cycle */
import Dec from 'decimal.js'
import { ASSETS, DEFAULT_ASSET } from './constants/assets'
import { getTokenDecimals, newContractReader } from './contractReader'
import { ethToWei } from './web3Service'

export const assetAmountInEth = (amount) => {
  const decimals = 18
  return Dec(amount?.toString() || 0)
    .div(10 ** decimals)
    .toString()
}

const getAssetAllowanceForAddress = async (owner, spender, contract) => {
  const data = await contract.allowance(owner, spender).call()

  return assetAmountInEth(data)
}

export const isAddressApprovedOnAsset = async (account, addressToCheck, contract, amount) => {
  const allowance = await getAssetAllowanceForAddress(account, addressToCheck, contract)
  return Dec(allowance).gte(amount)
}

export const getTokenAllownce = async (wallet, tokenAddress, allowedTokenAddress) => {
  if (!wallet) return 0
  let result = await newContractReader({
    contractName: 'Erc20',
    functionName: 'allowance',
    params: [wallet, tokenAddress],
    contractAddress: allowedTokenAddress,
  })
  result = +assetAmountInEth(result) || 0
  return result
}

export const newApproveSpendOnAsset = async (from, spenderAddress, spentTokenAddress, passedNum) => {
  const tokenDecimals = await getTokenDecimals(spentTokenAddress)
  const num = BigInt(ethToWei(passedNum, tokenDecimals)).toString()
  return newContractReader({
    contractName: 'ERC20',
    functionName: 'approve',
    contractAddress: spentTokenAddress,
    sendParams: { from },
    params: [spenderAddress, num],
  })
}

export const getAssetInfo = (assetSymbol) =>
  ASSETS.find((t) => t.symbol.toLowerCase() === assetSymbol?.toLowerCase()) ||
  console.trace(`Asset "${assetSymbol}" not found `) || { ...DEFAULT_ASSET }

export const assetAmountInWei = (amount = 0, assetSymbol = 'ETH') => {
  const { decimals } = getAssetInfo(assetSymbol)

  return Dec(amount?.toString() || 0)
    .mul(10 ** decimals)
    .floor()
    .toFixed()
    .toString()
}

window.assetAmountInWei = assetAmountInWei
