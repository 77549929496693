import * as React from 'react'
import clsx from 'clsx'
import { Flex } from 'styles/layouts'
import ShowAt from 'components/general/ShowAt/ShowAt'
import { newDisplayFloats } from 'services/format'
import Icon from 'components/general/Icon'
import './index.scss'

interface IPriceChangeTag {
  icon?: string
  value?: string | number
  bg?: string
}

const PriceChangeTag = ({ icon, value, bg }: IPriceChangeTag) => (
  <ShowAt at={!!value}>
    <Flex fullWidth={false} className="nav-item-tag" p="0.2rem 0.8rem" borderRadius={8} bg={bg}>
      <Icon className={clsx('tag-icon', { 'tag-icon-rotate': value < 0 })} name={icon} />
      {newDisplayFloats(Math.abs(+value), 2) || 0}%
    </Flex>
  </ShowAt>
)

export default PriceChangeTag
