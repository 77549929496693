import styled, { css } from 'styled-components'

import { InputProps } from '.'

const inputModifier = {
  Flex: () => css`
    display: flex;
    flex-direction: row;
  `,
}

export const Wrapper = styled.div`
`
export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
`
export const Label = styled.label`
  opacity: 0.5;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`
export const Box = styled.div<Partial<InputProps>>`
  ${({ theme, inputInfo, fullWidth }) => css`
    background: #6C6F7C;
    align-items: center;
    border-radius: 0.8rem;
    width: ${fullWidth ? '100%' : '20rem'};
    ${inputInfo && inputModifier.Flex()}
  `}
`
export const Span = styled.span`
  color: white;
  opacity: 0.5;
  margin-right: 1.8rem;
  font-weight: 500;
`

export const Input = styled.input`
  width: 0;
  flex: 1;
  padding: 1rem;
  color: white;
  font-size: 1.6rem;

  &::placeholder {
    color: #fff;
    opacity: 0.6;
  }
`
