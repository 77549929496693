export const CONNECT_WALLET_START = 'CONNECT_WALLET_START'
export const CONNECT_WALLET_END = 'CONNECT_WALLET_END'

export const UPDATE_BALANCE = 'UPDATE_BALANCE'

export const CONNECT_WALLET_PROVIDER = 'CONNECT_WALLET_PROVIDER'
export const CONNECT_WALLET_PROVIDER_SUCCESS = 'CONNECT_WALLET_PROVIDER_SUCCESS'
export const CONNECT_WALLET_PROVIDER_FAILURE = 'CONNECT_WALLET_PROVIDER_FAILURE'

export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT'

export const SET_STABLE_COIN_INFO = 'SET_STABLE_COIN_INFO'
