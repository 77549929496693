import { getTokenBalance } from 'services/tokensService'
import { contractReader, getValueByMultiProps } from '../../utils/contractReader'
import { WeiToEth } from '../../utils/web3Service'
import * as balanceActionTypes from '../actionTypes/balanceActionTypes'

export const setBalance = (contract, tokenName) => async (dispatch, getState) => {
  let balance = 0
  const signerAddress = getState().wallet.account
  if (signerAddress) {
    const tokenAddress = getValueByMultiProps(contract, ['_address', 'address'])
    balance = await getTokenBalance(signerAddress, tokenAddress)
    // if (!weiAmountOfTokens?.error) {
    //   balance = Number(WeiToEth(weiAmountOfTokens))
    // }
  }
  dispatch({
    type: balanceActionTypes.SET_BALANCE,
    payload: { balance, tokenName },
  })
}

export const unsetBalances = () => ({
  type: balanceActionTypes.REMOVE_BALANCES,
})
