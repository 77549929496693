import * as React from 'react'
import ShowAt from 'components/general/ShowAt/ShowAt'
import { newDisplayFloats } from 'services/format'
import { Flex, Text } from 'styles/layouts'

interface IDiscountTag {
  bg?: string
  className?: string
  title: string
  value: number | string
}

const DiscountTag = ({ bg, className, title, value }: IDiscountTag) => (
  <ShowAt at={value > 0}>
    <Flex fullWidth={false} p="0.2rem 0.8rem" borderRadius={8} className={className} column bg={bg}>
      <Text xsm opacity={0.8}>
        {title}
      </Text>
      {newDisplayFloats(+value, 2) || 0}%
    </Flex>
  </ShowAt>
)

export default DiscountTag
