import clsx from 'clsx'
import styled from 'styled-components'

export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '500px',
  tabletM: '650px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

interface IBox {
  p?: string
  m?: string
  px?: string
  py?: string
  mx?: string
  my?: string
  mt?: string
  mb?: string
  bg?: string
  border?: string
  borderRadius?: number
  opacity?: number
  mw?: string
  deviceProps?: string
  onScreen?: 'mobileS' | 'mobileM' | 'mobileL' | 'tabletS' | 'tabletM' | 'tablet' | 'laptop' | 'laptopL' | 'desktop'
}

export const Box = styled.div`
  ${({
  bg,
  p,
  px = '0',
  py = '0',
  m,
  mx = '0',
  my = '0',
  border,
  borderRadius,
  mt,
  opacity,
  mb,
  mw,
  onScreen,
  deviceProps,
}: IBox) => `
    padding: ${p || `${py} ${px}`};
    margin: ${m || `${my} ${mx}`};
    margin-top: ${mt};
    margin-bottom: ${mb};
    background-color: ${bg};
    border: ${border};
    border-radius: ${borderRadius}px;
    opacity: ${opacity};
    max-width: ${mw};
    @media only screen and (max-width: ${size?.[onScreen]}){
      ${deviceProps}
    }
`}
`

interface IFlex extends IBox {
  column?: boolean
  overHidden?: boolean
  gap?: string
  justifyContent?: 'center' | 'space-between' | 'space-around' | 'flex-end'
  alignItems?: string
  borderRadius?: number
  fullWidth?: boolean
  wrap?: boolean
}

export const Flex = styled.div`
  display: flex;
  ${({
  column,
  wrap,
  gap,
  justifyContent,
  alignItems,
  overHidden,
  fullWidth = true,
  bg,
  p,
  px = '0',
  py = '0',
  m,
  mx = '0',
  my = '0',
  border,
  borderRadius,
  mt,
  opacity,
  mb,
}: IFlex) => `
  width: ${fullWidth && '100%'};
  flex-direction: ${(column && 'column') || 'row'};
  gap : ${gap};
  justify-content:${justifyContent};
  flex-wrap:${wrap && 'wrap'};
  align-items:${alignItems};
  overflow: ${overHidden && 'hidden'};
  padding: ${p || `${py} ${px}`};
  margin: ${m || `${my} ${mx}`};
  margin-top: ${mt};
  margin-bottom: ${mb};
  background-color: ${bg};
  border: ${border};
  border-radius: ${borderRadius}px;
  opacity: ${opacity};
`}
`

export enum FontSize {
  xxsm = 10,
  xsm = 12,
  sm = 14,
  reg = 16,
  md = 18,
  lg20 = 20,
  lg = 21,
  lg22 = 22,
  lg24 = 24,
  lg25 = 25,
  xlg = 25,
  xlg32 = 32,
  _2xlg = 35,
  _3xlg = 40,
  _4xlg = 42,
}
export interface IFontSize {
  xxsm?: boolean
  xsm?: boolean
  sm?: boolean
  reg?: boolean
  md?: boolean
  lg20?: boolean
  lg?: boolean
  xlg?: boolean
  xlg32?: boolean
  lg22?: boolean
  lg24?: boolean
  lg25?: boolean
  _2xlg?: boolean
  _3xlg?: boolean
  _4xlg?: boolean
}

export interface IText extends IFontSize {
  color?: string
  className?: string
  opacity?: number
  underline?: boolean
  pre?: boolean
  centered?: boolean
  bold?: boolean
  uppercase?: boolean
}

export const Text = styled.span`
  ${({
  xxsm,
  xsm,
  sm,
  md,
  lg,
  reg,
  color,
  xlg,
  lg20,
  lg22,
  lg24,
  lg25,
  xlg32,
  _2xlg,
  _3xlg,
  _4xlg,
  opacity = 1,
  underline,
  pre,
  centered,
  bold,
  uppercase
}: IText) => `
  font-size: ${FontSize?.[clsx({ xxsm, xsm, sm, reg, md, lg, xlg, lg20, lg22, lg24, lg25, xlg32, _2xlg, _3xlg, _4xlg })]}px;
  color: ${color};
  display: block;
  opacity: ${opacity};
  text-decoration : ${(underline && 'underline') || ''};
  white-space: ${pre && 'pre'};
  text-align: ${centered && 'center'};
  font-weight: ${bold && 'bold'};
  text-transform: ${uppercase && 'uppercase'}
`}
`
