import clsx from 'clsx'
import React, { useCallback } from 'react'
import './index.scss'

export interface ITab {
  label: string
  timeRange: number
  id: number
  stages: number
  active?: boolean
  parseDate: (_date: any) => string
  onClick?: (id) => void
}

export const Tab = ({ label, id, timeRange, stages, active, onClick = () => {} }: ITab) => {
  const tabClassName = clsx('time-range-tab', { active })
  const handleClick = useCallback(() => {
    onClick(id)
  }, [id])

  return (
    <span className={tabClassName} onMouseDown={handleClick}>
      {label}
    </span>
  )
}
