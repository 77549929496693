import React from 'react'
import ReactApexChart from 'react-apexcharts'
import './ApexChart.scss'

export default class CustomChart extends React.Component {
  constructor(props) {
    super(props)
    this.removeLastCandel = this.removeLastCandel.bind(this)
    this.updateChartData = this.updateChartData.bind(this)
    this.handleMinMaxRange = this.handleMinMaxRange.bind(this)
    this.threeshould = 20
    this.state = {
      series: [
        {
          data: [] || this.props.data,
        },
      ],

      options: {
        // tooltip: {
        // show: false,
        // enabled: false,
        // },
        // legends: {
        //   horizontalAlign: 'left',
        // },

        tools: {
          show: false,
        },
        grid: {
          borderColor: '#222123',
          position: 'back',
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            opposite: true,
            lines: {
              show: true,
            },
          },
        },

        stroke: {
          width: 1.5,
          colors: ['#2962FF'],
          curve: 'smooth',
          lineCap: 'round',
        },
        chart: {
          type: 'candlestick',
          background: '#1E1628',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 500,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 2000,
            },
          },
          selection: {
            enabled: false,
          },
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          stroke: {},
          events: {
            mounted: () => {
              this.props.onMount()
            },
          },
        },
        plotOptions: {
          candlestick: {
            colors: {
              upward: '#25A59A',
              downward: '#EF5350',
            },
            wick: {
              useFillColor: true,
            },
          },
        },
        dataLabels: {
          // enabled: false,
        },
        xaxis: {
          type: 'datetime',
          crosshairs: {
            show: true,
          },
          labels: {
            datetimeUTC: false,
            style: {
              colors: '#ddd',
            },
          },
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
          tickAmount: 6,
          labels: {
            // align: 'right',
            formatter(value) {
              return value.toFixed(2)
            },
            style: {
              colors: '#ddd',
            },
          },
        },
        // annotations: {
        //   yaxis: [
        //     {
        //       y: 0.3208,
        //       position: 'left',
        //       borderColor: '#00E396',
        //       label: {
        //         borderColor: '#00E396',
        //         style: {
        //           color: '#fff',
        //           background: '#00E396',
        //         },
        //         text: '6610.0',
        //       },
        //     },
        //   ],
        // },
        tooltip: {
          shared: true,
          enabled: true,
          // followCursor: true,
          // custom({ seriesIndex, dataPointIndex, w }) {
          //   const open = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
          //   const high = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
          //   const low = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
          //   const close = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
          //   return (
          //     `${['<div class="apexcharts-tooltip-candlestick">', '<div>Início: <span class="value"> <b>'].join(
          //       ' ',
          //     )}${open}</b></span></div>` +
          //     `<div>Máx: <span class="value"> <b>${high}</b></span></div>` +
          //     `<div>Min: <span class="value"> <b>${low}</b></span></div>` +
          //     `<div>Fim: <span class="value"> <b>${close}</b></span></div>` +
          //     '</div>'
          //   )
          // },
          // custom: [
          //   function ({ seriesIndex, dataPointIndex, w }) {
          //     return 'A'
          //   },
          //   function ({ seriesIndex, dataPointIndex, w }) {
          //     const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
          //     const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
          //     const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
          //     const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
          //     return [o]
          //   },
          // ],
        },
      },
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {})
    const elem = document.getElementById('chart')
    elem.addEventListener('wheel', (ev) => {
      const { screenX, wheelDelta, deltaX } = ev
      this.setState({ screenX, wheelDelta, deltaX })
    })
  }

  updateChartData(data = []) {
    this.setState({ series: data })
  }

  componentDidUpdate(prevPrpos, prevState) {
    const { data: prevData } = prevPrpos
    const { data: currentData } = this.props
    const lastPrevElem = currentData?.[0]?.y?.reduce((prev, curr) => [prev, curr, ''].join(''), '')
    const lastCurrentElem = prevData?.[0]?.y?.reduce((prev, curr) => [prev, curr, ''].join(''), '')
    const hasChanged = lastPrevElem !== lastCurrentElem
  }

  componentWillUnmount() {}

  removeLastCandel() {
    let newData = [...this.state.series?.[0].data]
    newData.pop()
    newData = {
      data: [...newData],
    }
    this.setState({ series: [newData] })
  }

  handleMinMaxRange() {
    const { options } = this.state
    const { min, max } = this.props
    return {
      ...options,
      yaxis: { ...options.yaxis, min, max },
    }
  }

  render() {
    return (
      <div id="chart" style={{ width: '100%', height: '100%' }} onScroll={this.handleScroll}>
        <ReactApexChart
          options={this.handleMinMaxRange(this.state.options)}
          series={[{ data: this.props.data || [] }]}
          type="candlestick"
        />
      </div>
    )
  }
}
