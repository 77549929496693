import React from 'react'
import moment from 'moment'
import { formatNumericValues } from 'services/format'
import { Text } from 'styles/layouts'

export default [
  {
    id: 'price',
    title: 'Price',
    render: (record, price) => {
      const textColor = record?.side === 'sell' ? '#FF5353' : '#16C79A'
      return (
        <Text sm color={textColor}>
          {formatNumericValues(price, 2)}
        </Text>
      )
    },
  },
  {
    id: 'ethValue',
    title: 'ETH',
    render: (record, ethValue) => <Text sm>{formatNumericValues(ethValue, 4)}</Text>,
  },
  {
    id: 'ts',
    title: 'Time',
    render: (record, timestamp) => <Text sm>{moment(+timestamp).fromNow(true)}</Text>,
  },
]
