import React from 'react'
import { Flex, Text } from 'styles/layouts'
import { FOOTER_LINKS } from 'utils/constants/general'
import './Footer.scss'

const Footer = () => (
  <footer>
    <Flex justifyContent="flex-end" className="footer-wrapper">
      {/* <div className="reserved-policy-wrapper">
      <div className="reserved">{trans('footer.reserved')}</div>
      {FOOTER_TERMS_POLICY.map(({ name, link, className }) => (
        <a
          key={name}
          className={['footer-external-link', className].join(' ')}
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          {trans(name)}
        </a>
      ))}
    </div> */}
      <Flex p="2rem 1.5rem 1.5rem 1.5rem" fullWidth={false} gap="0.5rem" className="footer-links-wrapper">
        <Text className="footer-wrapper-reserved" xsm color="#fff" opacity={0.3}>
          All rights reserved |
        </Text>
        {FOOTER_LINKS.map(({ name, link }) => (
          <a key={name} className="footer-link" target="_blank" rel="noopener noreferrer" href={link}>
            <Text className="footer-link-text" underline xsm color="#fff" opacity={0.7}>
              {name}
            </Text>
          </a>
        ))}
      </Flex>
    </Flex>
  </footer>
)

export default Footer
