import React from 'react'
import './index.scss'

interface IBackdrop {
  show: boolean
  onClick: () => void
}

const Backdrop = ({ show, onClick }: IBackdrop) => (
  <div className={show ? 'backdrop' : 'backdrop hidden'} onClick={onClick} />
)

export default Backdrop
