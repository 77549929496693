import styled from 'styled-components'

export const Section = styled.section`
  background: #1E1628;
  border-radius: 2.5rem;
  padding: 3rem;
  margin-top: 3rem;
  display: flex;
  gap: 1.5rem;
`

// Header
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0 2.4rem 0;
  border-bottom: 1px solid #6C6F7C;

  @media only screen and (max-width: 650px) {
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding-bottom: 3rem;

    & ul {
      margin-bottom: 2rem;
    }
  }

  & .react-tabs__tab {
    opacity: 0.6;
    transition: opacity 0.1s ease-in-out;
    padding: 0.6rem 1.2rem;
    border-radius: 0.8rem;

    &:hover {
      opacity: 1;
    }
  }

  & .react-tabs__tab--selected {
    opacity: 1;
    color: white;
    background: #926CE3;
  }
  & .react-tabs__tab:not(:last-child) {
    margin-right: 1.2rem;
  }
`
export const Tab = styled.ul`
  li {
    font-size: 1.4rem;
    font-weight: 800;
    display: inline-block;
  }

  li :not(:last-child) {
    margin-right: 2rem;
  }
`
export const WrapperActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
export const ContainerActions = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 0.8rem 2.1rem 0.8rem 0.8rem;

  @media only screen and (max-width: 395px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const BuySellWrapper = styled.div`
  display: flex;

  @media only screen and (max-width: 395px) {
    margin-top: 1rem;
    align-self: center;
  }
`

export const ContainerTransactions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
