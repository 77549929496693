import styled, { css } from 'styled-components'

import { NavbarItemPops } from '.'

export const Wrapper = styled.div`
  flex: 1;
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }

  & div {
    @media only screen and (max-width: 650px) {
      margin-bottom: 0.5rem;
    }

    @media only screen and (max-width: 450px) {
      transform: translate(calc(50% - 6px));
    }
  }
`
export const Label = styled.span`
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.6;
  font-weight: 400;
`
export const Info = styled.p<Partial<NavbarItemPops>>`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: 700;
    margin: 0;
  `}
`

export const EthValue = styled.span`
  font-size: 1.2rem;
  opacity: 0.6;
`;

export const VolumeHigh = styled.div`
  p {
    ${({ theme }) => css`
      color: ${theme.colors.green};
    `}
  }
`

export const VolumeLow = styled.div`
  p {
    ${({ theme }) => css`
      color: ${theme.colors.primary};
    `}
  }
`
