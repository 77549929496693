import moment from 'moment'
import { connect } from 'react-redux'
import { ITransactionData } from '../../../views/dashboard/components/blocks/Transactions/ITransactions'

import Icon from '../Icon'
import * as S from './styles'

type Props = {
  data?: ITransactionData
  remove?: () => void
  currentHPoolTokenName: string
  currentHPoolTokenImage: string
}

const TransactionOrder = ({ data, remove, currentHPoolTokenName, currentHPoolTokenImage }: Props) => {
  const { price } = data ?? { price: 0 }
  const totalInStableCoin = price * data.total
  const leftAmt = data.total

  return (
    <S.Tr>
      <S.Td>
        <S.Tag>Date</S.Tag>
        <S.ContainerFlex>
          <>{moment(+data.timestamp * 1000).fromNow()}</>
        </S.ContainerFlex>
      </S.Td>

      <S.Td>
        <S.Tag>Pair</S.Tag>
        <S.ContainerFlex>
          {/* <S.Image src={data.coinImage} /> */}
          <span>
            {data.coin} / {data?.stableCoin?.symbol}
          </span>
        </S.ContainerFlex>
      </S.Td>

      <S.Td>
        <S.Tag>Side</S.Tag>
        <S.ContainerFlex>
          <span
            style={{
              marginRight: '1rem',
              fontWeight: 500,
              color: data.side === 'sell' ? '#FF5353' : '#16C79A',
            }}
          >
            {data.side === 'sell' ? 'Sell' : 'Buy'}
          </span>
        </S.ContainerFlex>
      </S.Td>

      <S.Td>
        <S.Tag>Price</S.Tag>
        <span>${(price || 0).toFixed(2)}</span>
      </S.Td>

      <S.Td>
        <S.Tag>Amount</S.Tag>
        <span>{+leftAmt}</span>
      </S.Td>

      <S.Td>
        <S.Tag>Total</S.Tag>
        <span>${(+data.total * price || 0).toFixed(2)}</span>
      </S.Td>

      {/* <S.Td>
        <S.Tag>Actions</S.Tag>
        <S.ContainerActions>
          <div onClick={remove}>{data.status && <Icon name="Close" />}</div>
          <Icon name="Options" />
        </S.ContainerActions>
      </S.Td> */}
    </S.Tr>
  )
}

const mapStateToProps = (state) => ({
  currentHPoolTokenName: state.tradingPair.currentHPoolToken?.name,
  currentHPoolTokenImage: state.tradingPair.currentHPoolToken?.image,
})

export default connect(mapStateToProps)(TransactionOrder)
