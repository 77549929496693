import React from 'react'
import './sidebarIcons.scss'

const VikingDao = () => (
  <svg className="vdao-svg" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M13 7C15.5561 5.97662 16.9765 3 16.9765 3C16.9765 3 17.4055 9.2041 14.5984 11"
      stroke="#928F9C"
      strokeWidth="1.34256"
    />
    <path
      d="M5 7C2.44393 5.97662 1.02351 3 1.02351 3C1.02351 3 0.594535 9.2041 3.40157 11"
      stroke="#928F9C"
      strokeWidth="1.34256"
    />
    <path
      d="M8.80979 8.58541C8.86966 8.40115 9.13034 8.40115 9.19021 8.58541L9.62864 9.93475C9.65541 10.0172 9.73221 10.0729 9.81885 10.0729H11.2376C11.4314 10.0729 11.5119 10.3209 11.3552 10.4348L10.2074 11.2687C10.1373 11.3196 10.1079 11.4099 10.1347 11.4923L10.5731 12.8416C10.633 13.0259 10.4221 13.1791 10.2654 13.0652L9.11756 12.2313C9.04746 12.1804 8.95254 12.1804 8.88244 12.2313L7.73462 13.0652C7.57788 13.1791 7.36699 13.0259 7.42686 12.8416L7.86528 11.4923C7.89206 11.4099 7.86273 11.3196 7.79263 11.2687L6.64481 10.4348C6.48807 10.3209 6.56862 10.0729 6.76237 10.0729H8.18115C8.26779 10.0729 8.34459 10.0172 8.37136 9.93475L8.80979 8.58541Z"
      fill="#928F9C"
      className="vdao-star"
    />
    <circle cx="9" cy="11" r="6" stroke="#928F9C" strokeWidth="1.71429" />
  </svg>
)

export default VikingDao
