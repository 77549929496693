import React from 'react'

const LogoIconWide = () => (
  <svg width="44" height="53" viewBox="0 0 44 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5551 46.6123C12.5551 46.6123 13.4782 48.232 14.586 52.0114C10.3395 47.3321 4.61583 46.0723 4.61583 46.0723C9.78555 45.7124 0 40.6732 0 40.6732C3.87729 40.1333 -0.369269 38.1537 3.87729 37.4338C9.41628 36.354 2.2156 32.3946 4.61583 31.8547C8.67776 30.7749 8.67775 23.936 10.3394 19.9767C5.90826 16.1973 2.03097 7.1988 6.0929 0C8.12386 7.1988 10.5241 10.9782 16.4323 12.4179C19.5711 10.2583 23.2638 9.53843 26.7718 10.2583C27.8796 9.7184 29.5413 10.2583 30.6491 10.7982C31.5723 11.3381 33.0493 12.238 33.0493 13.4978C33.234 13.6777 33.4186 13.8577 33.6032 14.0377C37.1113 12.9579 40.25 10.2583 42.8349 5.57907C44.1273 11.3381 42.281 16.3773 37.8498 19.9767C38.9576 22.6762 39.1422 27.3555 38.2191 30.055C40.0654 31.4948 40.9885 36.8939 38.4037 37.4338C38.4037 37.7937 38.4037 38.1537 38.4037 38.3336V38.6936L38.5883 38.8735C38.9576 39.0535 39.3268 39.4135 39.5115 39.7734C39.8807 40.3133 40.0654 41.0332 39.8807 41.7531C39.6961 42.293 39.5115 42.6529 39.1422 43.0129L38.9576 43.1928V43.3728C39.5115 47.1522 40.8039 48.232 43.2041 50.9315L43.758 51.4715L43.0195 51.6514C40.0654 52.3713 36.5573 52.1913 34.1571 50.0317L33.7879 49.6718L33.234 50.7516H32.1262C30.0952 50.9316 28.0642 50.5716 26.2179 49.4918C25.8486 49.3118 25.2947 48.9519 24.9255 48.7719L24.1869 48.232L24.3716 49.3118C24.3716 49.4918 24.3716 49.6718 24.3716 49.8517L24.5562 50.5716L23.8177 50.2117C21.4174 49.3118 18.8326 48.052 16.9863 46.0723C16.9496 46.0331 16.9475 46.035 16.8016 46.0723C16.8016 46.0723 13.4782 46.6123 12.5551 46.6123Z"
      fill="#27282D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3574 28.2553C31.9423 27.5354 36.9274 26.9955 37.112 28.6153C36.9274 30.235 32.1269 28.0753 29.3574 28.2553Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.3562 28.2567C30.6486 27.8968 32.258 27.6494 33.972 27.5368C35.6542 27.4773 36.003 27.8968 36.003 28.6167C36.003 28.9766 35.8375 29.1791 35.4682 29.1791C33.8065 28.9991 31.1323 28.1422 29.3562 28.2567Z"
      fill="#FF9D9C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.2958 26.815C26.0331 25.1953 22.7097 25.7352 14.032 26.4551C23.4483 24.4754 31.0182 24.2955 34.8955 24.8354C34.7109 18.3564 32.4953 15.117 29.1719 12.7773C33.4185 14.757 37.1111 18.8963 37.2958 26.815Z"
      fill="#8D6752"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.635 23.3967C31.7577 22.8567 29.1729 23.0367 26.2188 23.3967C29.5421 21.7769 33.6041 21.597 35.635 21.7769V23.3967Z"
      fill="#8D6752"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8476 32.0343C17.9095 33.1142 21.9715 31.6744 26.218 28.075C22.3407 25.9153 17.3556 26.8152 11.0781 30.7745C14.0323 31.1345 17.171 30.4146 20.1251 28.6149C18.2788 30.5946 16.2478 31.8544 13.8476 32.0343Z"
      fill="#A84337"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8341 34.5542C22.1575 38.1536 23.8191 37.0738 23.8191 31.1348C22.5267 32.9345 20.865 34.0143 18.6494 34.5542H18.8341Z"
      fill="#A84337"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3715 33.293C9.23278 34.7327 7.01717 39.232 4.61694 41.5716C7.38644 44.0911 11.4484 44.811 15.141 44.631C14.5871 41.0317 14.5871 37.2523 15.3257 33.8329C13.2947 35.6326 11.2637 38.872 11.4484 41.7515C10.1559 39.232 10.8945 35.6326 12.3715 33.293Z"
      fill="#A84337"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2779 14.0379C19.2011 14.2179 20.3089 14.0379 21.4167 14.2178C22.1552 14.2178 22.7091 14.3978 23.4477 14.3978C26.5864 15.2977 27.8789 18.8971 25.294 21.0567C24.5555 21.7766 23.6323 22.1365 22.5245 22.3165C21.4167 22.6764 20.1243 22.6764 18.8318 22.4965C14.7699 21.9566 11.2619 18.8971 8.86163 15.6576C7.5692 13.8579 6.64603 11.6983 6.09213 9.35866C5.53823 7.19902 5.53824 5.57928 5.9075 3.59961C6.64604 5.57928 7.19992 7.19901 8.49236 8.81875C10.8926 11.8782 14.216 13.498 18.2779 14.0379Z"
      fill="#A7A2A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0791 15.2956C37.8486 14.2158 40.0642 12.596 41.9105 9.89648C41.7259 12.956 40.4335 15.8355 37.8486 17.8152C37.664 17.9951 37.2947 18.1751 37.1101 18.3551C36.5562 17.2753 36.0023 16.3754 35.0791 15.2956Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0791 15.2956C37.8486 14.2158 40.0642 12.596 41.9105 9.89648C40.8027 12.596 38.6841 14.9912 36.0992 16.6109C35.7839 16.1823 35.5682 15.8871 35.0791 15.2956Z"
      fill="#A7A2A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5244 10.9785C17.5405 16.7376 18.279 12.7782 22.1563 18.7172C16.4327 14.9379 14.0324 19.0772 10.5244 10.9785Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.7856 20.336C24.3705 20.336 26.5861 17.8164 26.5861 17.8164C28.0632 23.2155 20.6778 23.9354 17.5391 23.7554C20.1239 23.0355 21.601 21.9557 21.7856 20.336Z"
      fill="#A7A2A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1096 16.7361C23.6325 16.7361 22.3401 16.0163 21.6016 15.1164C26.5867 12.5968 28.8022 16.7361 27.3252 19.6157C26.2174 17.456 25.2942 17.456 23.0786 17.9959C23.6325 17.636 25.1096 16.7361 25.1096 16.7361Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1865 26.6579C36.9358 26.7625 37.2965 26.8152 37.2965 26.8152C37.2965 22.136 35.8194 18.1767 32.6807 15.1172C35.0809 18.5366 36.3733 22.496 36.004 26.6353L36.1865 26.6579Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.90648 3.7793C7.75281 18.5369 17.5384 18.5369 21.7849 19.2567C23.8159 19.6167 23.262 22.6762 20.1232 22.6762C11.8147 22.4962 4.24478 12.0579 5.90648 3.7793Z"
      fill="#979399"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.0941 12.057C31.3865 12.7769 32.1251 13.6767 31.7558 14.0366C31.3865 14.5766 30.0941 14.3966 28.8017 13.6767C27.5092 12.9568 26.7707 12.057 27.14 11.697C27.3246 11.3371 28.8017 11.5171 30.0941 12.057Z"
      fill="#8D6752"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.0176 13.1358C30.833 13.3158 30.0944 13.3158 29.5405 12.9558C28.802 12.5959 28.4327 12.2359 28.6174 11.876C29.0856 11.4196 31.379 12.4313 31.0176 13.1358Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7578 42.6535C33.4195 41.9337 35.2658 41.5737 37.2968 42.1136C39.1431 42.4736 39.1431 40.3139 37.6661 40.3139C36.189 40.134 33.7888 40.8538 31.7578 42.6535Z"
      fill="#FF9D9C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4955 40.6747C32.4955 40.4947 32.1262 40.3147 31.5723 40.1348C32.3109 40.1348 33.234 40.1348 33.9726 40.3148C33.4187 40.8547 32.4955 41.2146 31.3877 41.5746C31.757 41.2146 32.1262 40.8547 32.4955 40.6747Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2342 37.2528C34.8959 36.8928 36.1883 36.8928 36.9268 37.4327C36.9268 38.1526 35.819 38.6925 33.6034 38.3325C34.1573 37.9726 34.7112 37.7927 35.2651 37.7927C33.7881 37.4327 31.3878 37.9726 28.0645 39.5923C31.5725 36.8928 32.4956 36.3529 34.1573 36.7128C33.7881 36.8928 33.4188 37.0728 33.2342 37.2528Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5117 40.1332C27.7811 39.8178 27.9895 39.6516 28.2688 39.4585C31.6241 37.7936 33.9739 37.4336 35.2663 37.7936C34.7124 37.7936 34.1585 37.9735 33.6046 38.3334C35.6356 38.6934 36.928 38.1535 36.928 37.4336C37.4819 37.7935 37.4819 38.5134 36.928 39.2333C37.1126 38.8734 36.928 38.6934 36.928 38.3334C36.7434 39.4133 35.6356 39.5933 33.2353 39.0533C33.6046 38.5134 33.9739 38.5134 34.5278 38.6934C33.9739 37.9735 32.6814 38.3335 30.6505 39.9532C30.8351 39.5933 31.0197 39.2333 31.2044 39.0533C30.2812 39.7732 28.9888 39.9532 27.5117 40.1332Z"
      fill="#EB453F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1878 43.0127C36.7417 43.1927 37.0367 43.2452 37.4802 43.3727C37.8495 46.6121 39.1419 49.1317 41.3575 50.7514C37.6648 50.9314 35.2646 49.4917 34.1568 46.7921C33.4183 47.332 32.8644 48.0519 32.4951 49.3117C27.51 49.4917 24.3713 46.9721 22.7096 41.573C22.5249 44.2725 22.5249 46.4322 23.0788 47.8719C16.432 45.1724 16.8013 38.1535 16.8013 34.9141C20.3093 38.1535 24.7405 40.4931 27.51 41.213C28.0639 43.7326 28.4332 44.8124 28.4332 44.8124C29.541 43.9126 29.9103 43.3727 29.9103 43.3727C31.3873 43.7326 32.8644 43.7326 34.3414 43.5526C34.7107 44.4525 35.2646 45.1724 35.8185 45.7123C35.8185 44.6325 35.8185 43.7326 36.1878 43.0127Z"
      fill="#EB453F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4738 42.145C39.1355 42.3308 39.1355 40.2871 37.6584 40.2871C37.4738 40.2871 37.2891 40.2871 37.1045 40.2871C38.2123 40.4729 38.3969 41.7734 37.4738 42.145Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3579 50.7524C38.2192 49.8525 36.5575 47.333 36.1882 43.0137C36.7421 43.1936 36.9035 43.2228 37.4807 43.3736C37.8499 46.4331 39.1424 48.9527 41.3579 50.7524Z"
      fill="#FEFEFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4656 32.0335C34.5276 28.0741 39.5127 32.0335 38.5895 35.6329C38.4049 36.1728 36.7432 34.733 35.82 35.4529C33.789 36.8927 33.789 34.1931 35.0815 34.1931C34.1583 33.8332 33.6044 34.733 33.2351 35.4529C32.8659 36.1728 32.1273 36.3528 31.5734 35.4529C31.2042 34.733 31.9427 32.5734 33.2351 32.2134C31.2042 32.0335 29.9118 34.3731 29.9118 35.9928C28.2501 34.5531 28.2501 33.4732 28.4347 32.3934C27.3269 32.9333 27.3269 35.6329 27.6961 36.7127C26.5883 36.5327 25.6652 34.913 25.6652 33.2933C25.4806 29.6939 28.9886 27.7142 32.4966 29.6939C31.3888 30.2338 30.6503 31.1336 30.4656 32.0335Z"
      fill="#FF9D9C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.8203 30.7031C37.8513 31.5837 39.1437 33.5209 38.5898 35.6343C38.5898 35.8104 38.2205 35.8104 37.8513 35.6343C37.6666 33.8732 37.1127 32.112 35.8203 30.7031Z"
      fill="#FEFEFE"
    />
  </svg>
)

export default LogoIconWide
