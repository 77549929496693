import Web3 from 'web3'
import Dec from 'decimal.js'
import { WEB_SOCKET_DISCONNECTED } from 'views/dashboard/components/blocks/Transactions/EventsListeners'
import configJson from '../config/config.json'
import { wait } from './utilsService'
import { getNetwork, web3WalletConnector } from './walletService'
import { eventSetup } from './events'
import { networks } from '../config/networks.json'

export const getNetworkById = (netId) => networks.find((network) => +network.chainId === +netId)

export const storeNetworkId = (netId) => localStorage.setItem('chainId', netId)

export const storedSelectedNetworkId = localStorage.getItem('chainId')
export const storedNetworkInfo = getNetworkById(storedSelectedNetworkId) || networks[0]

export const loadCurrentWeb3Instance = async () => {
  if (!window._web3) {
    await wait(150)
    return loadCurrentWeb3Instance()
  }
  return window._web3
}

export const getCurrentChainReq = async () => {
  await loadCurrentWeb3Instance()
  return window._web3.eth.getChainId()
}

export const setupWeb3 = (provider) => {
  if (!provider) {
    web3WalletConnector.disconnect()
  }
  const rpcProvider = provider || networks[0].rpcUrl
  window._web3 = new Web3(rpcProvider)
  window.networkId = configJson.network
}

export const setupWssWeb3Provider = (networkId, onConnect = () => {}) => {
  if (!networkId) return
  const { wssUrl } = getNetworkById(networkId) ?? {}
  if (!wssUrl) return

  const wsProvider = new Web3.providers.WebsocketProvider(wssUrl)

  wsProvider.on('close', async (code) => {
    console.log('ws closed', code)
    wsProvider.disconnect()
    await wait(1000) // wait before reconnect
    console.log('reconnecting...') // TODO: restart all subscribtions here
    setupWssWeb3Provider(() => {
      eventSetup.trigger(WEB_SOCKET_DISCONNECTED)
    })
  })
  wsProvider.on('connect', () => {
    console.log('wss connected')
    window.wsWeb3 = new Web3(wsProvider)
    onConnect()
  })
  wsProvider.on('error', (err) => {
    console.log('wss error ', err)
  })
}

export const setWeb3toInPageProvider = async (retryNum = 0) => {
  const exists = window.ethereum || window.web3?.currentProvider

  if (!exists) {
    if (retryNum < 5) {
      await wait(300)
      return setWeb3toInPageProvider(retryNum + 1)
    }

    throw new Error('errors.no_wallet')
  }

  const testWeb3 = new Web3(window.ethereum || window.web3.currentProvider)
  const testWeb3Network = await getNetwork(testWeb3)

  if (configJson.network !== testWeb3Network) throw new Error('errors.wrong_network')

  window._web3 = new Web3(window.ethereum || window.web3.currentProvider)
  return testWeb3Network
}

export const ethToWei = (_ethVal, decimals = 18) =>
  Dec(_ethVal?.toString() || 0)
    .mul(10 ** decimals)
    .floor()
    .toFixed()
    .toString()

export const WeiToEth = (value) => window._web3.utils.fromWei(value?.toString?.(), 'ether')
