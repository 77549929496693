export const isBoolean = (variable: any) => typeof variable === 'boolean'

export const isMatched = (value1: string, value2: string) => {
  if (!value1 || !value2) return

  return value1?.toLowerCase?.() === value2?.toLowerCase?.() || value1 === value2
}

export const compareStrings = (str1, str2) => str1?.toLowerCase?.() === str2?.toLowerCase?.() || str1 === str2

export const removeEmptyElems = (elem: any) => !!elem || Number.isFinite(elem)

const toLowerCase = (value: string) => value?.toLowerCase?.()

export const isStringIncluded = (stringsArray: string[], stringValue: string) =>
  stringsArray.map(toLowerCase).includes(stringValue?.toLowerCase())
