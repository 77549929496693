import Dec from 'decimal.js'

export const displayFloats = (num = 0, maxDigits = 2) => +num.toFixed(maxDigits)

export const newDisplayFloats = (num = 0, maxDigits = 2) => {
  if (!num.toString().includes('.')) return num
  const [nonDecimalPart, decimalPart] = num.toString().split('.')
  const afterCountingDigits = decimalPart.substring(0, maxDigits)
  const result = [nonDecimalPart, afterCountingDigits].join('.')
  return +result
}

export const specifyDecimals = (value, decimals) =>
  // @ts-ignore
  Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)

export const formatNumber = (value = 0, decimals = 0, removeTrailingZeroes = true, overideDecimals = false) => {
  try {
    value = displayFloats(value, decimals)

    if (!Number.isFinite(parseFloat(value.toString()))) return '0'
    // const decimalsToUse = overideDecimals ? overiddenDecimals : decimals
    const sign = parseFloat(value.toString()) < 0 ? '-' : ''
    const num = Math.abs(parseFloat(value.toString()))

    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]
    let i

    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break
      }
    }

    const decNumber = new Dec(num / si[i].value) // in order to avoid result of 1600 => 2k, we depend on decNumber d array (it has number broken down)

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const result = sign + new Dec(num / si[i].value).toFixed(decNumber?.d?.length).replace(rx, '$1') + si[i].symbol
    return result
  } catch (e) {
    return value.toString()
  }
}

export const parseLocalStrings = (stringNumber = '') => {
  if (typeof stringNumber === 'number') return stringNumber
  let result = stringNumber
  if (stringNumber.includes(',')) result = result.replaceAll(',', '')
  return +result
}

const handleDigits = (value = '') => value.split('.')

export const formatNumericValues = (value = '', decimals = 0) => {
  if (!value || !+value) return 0
  if (decimals && Number.isFinite(+value)) value = (+value)?.toFixed(decimals)

  if (value?.includes?.('.')) {
    const [numberValue, afterDigit] = handleDigits(value)
    return [formatNumericValues(numberValue), afterDigit].join('.')
  }
  const result = (+parseLocalStrings(value)).toLocaleString() || ''
  if (result === '0') return ''
  return result
}
