import React from 'react'

interface IShowAt {
  at: boolean
  children: JSX.Element[] | JSX.Element | React.ReactNode
}

export default function ShowAt({ at, children }: IShowAt) {
  return <>{!!at && children}</>
}
