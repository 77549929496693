import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isMatched } from 'utils/validator'
import Dinero from 'dinero.js'
import Heading from '../../../../../components/general/Heading'
import OrderBookTable from '../../../../../components/general/OrderBookTable'

import { IOrderBookData } from '../Graph/IGraph'
import * as S from './styles'

type IOrderBook = {
  orderBookAsks: IOrderBookData[]
  orderBookBids: IOrderBookData[]
  latestTransaction: number
  latestTransactionType: string
}

const OrderBook = ({ orderBookBids, orderBookAsks, latestTransaction, latestTransactionType, ...rest }: IOrderBook) => {
  const sizeState = 0.01
  const {
    currentPairAddress,
    poolsList,
    pendingOrders = [],
    executedOrdersSrc,
  } = useSelector((state: any) => ({
    pendingOrders: state.orderbook.pendingOrders,
    executedOrdersSrc: state.orderbook.executedOrdersSrc,
    account: state.wallet.account,
    poolsList: state.tradingPair.hPoolTokensList || [],
    currentPairAddress: state.tradingPair?.currentHPoolToken?.address || '',
  }))

  const getPoolContractAddress = (pool) => pool?.contract_address

  const ordersByCurrentPair = useMemo(
    () => pendingOrders.filter((order) => isMatched(order.coinAddress, currentPairAddress)),
    [pendingOrders, currentPairAddress],
  )

  const findPoolByAddress = (address: string) =>
    poolsList.find((pool: any) => isMatched(getPoolContractAddress(pool), address))

  const getOrderTotalAmt = (order) => {
    const initAmt = +order.total

    const deductedUsingCompletedOrders = initAmt - (executedOrdersSrc?.[+order.id] || 0)
    const amountPreviouslyMatched = executedOrdersSrc?.[+order.id]

    return deductedUsingCompletedOrders
  }

  const removeOrdersWithNoTotalLeft = (order) => true || order.total > 0

  const processCurrentOrderInfo = (order) => ({
    ...order,
    symbol: findPoolByAddress(order.coinAddress)?.hpool_token_symbol,
    total: getOrderTotalAmt(order),
    originalAmt: order.total,
  })

  const bids = useMemo(
    () =>
      ordersByCurrentPair
        ?.filter((order) => order.side === 'buy' && !!getOrderTotalAmt(order))
        .map(processCurrentOrderInfo)
        .filter(removeOrdersWithNoTotalLeft),
    [ordersByCurrentPair, poolsList?.length, executedOrdersSrc],
  )
  const asks = useMemo(
    () =>
      ordersByCurrentPair
        ?.filter((order) => order.side === 'sell' && !!getOrderTotalAmt(order))
        .map(processCurrentOrderInfo)
        .filter(removeOrdersWithNoTotalLeft),
    [ordersByCurrentPair, poolsList?.length, executedOrdersSrc],
  )

  const getDecimalPlaces = () => sizeState.toString().split('.')[1].length || 0

  const updateDataSize = (orderBookData) =>
    orderBookData.map((order) => ({
      ...order,
      price: order.price.toFixed(getDecimalPlaces()),
      amount: order.amount.toFixed(getDecimalPlaces()),
      total: order.total.toFixed(getDecimalPlaces()),
    }))

  // const lastOrderBook = (data) => data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
  //
  // const last = useMemo<IOrderBookData>(() => lastOrderBook(data), [data])

  return (
    <S.Wrapper className="orderbook">
      <S.WrapperTitle>
        <Heading title="Orderbook" />
      </S.WrapperTitle>
      <OrderBookTable
        orderBookAsks={asks}
        orderBookBids={bids}
        latestTransaction={Dinero({ amount: Math.round(latestTransaction * 100) || 0 }).toFormat('0,0.00')}
        latestTransactionType={latestTransactionType}
        actionTabProps={rest}
      />
    </S.Wrapper>
  )
}

export default OrderBook
