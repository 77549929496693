import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { ICondition, multiFilterArray } from 'utils/array'
import { isMatched } from 'utils/validator'
import useAvailablePairs from 'hooks/useAvailablePairs'
import { arraySort } from 'utils/arraySort'
import { ITransactionData } from 'views/dashboard/components/blocks/Transactions/ITransactions'
import { getValueByMultiProps } from 'utils/contractReader'
import OpenOrder from '../OpenOrder'
import Icon from '../Icon'
import * as S from './styles'
import SkeletonWrapper from '../SkeletonWrapper/SkeletonWrapper'
import ShowAt from '../ShowAt/ShowAt'

export interface OpenOrderProps extends ITransactionData {
  orderId: string | number
  amount: string
  total: number
  tradeAmount: number
  totalStableCoin: number
  originalAmt: number
  fee: number
  side: 'sell' | 'buy'
  date: Date
  coin: 'string'
  coinImage: 'image'
  loading: boolean
}

type Props = {
  data?: any[]
  remove?: (id: string) => void
  filters?: {
    hiddenPairs: boolean
    onlyBuy: boolean
    onlySell: boolean
    status: string
  }
}

const cols = [
  {
    title: 'Time',
    prop: 'date',
  },
  {
    title: 'Pair',
    prop: 'coin',
  },
  {
    title: 'Buy/Sell',
    prop: 'side',
  },
  {
    title: 'Price',
    prop: 'price',
  },
  {
    title: 'Amount',
    prop: 'amount',
  },
]

const OpenOrderTable = ({ data, filters }: Props) => {
  const [isAvailablePool] = useAvailablePairs()
  const {
    pendingOrders = [],
    executedOrders,
    account,
    poolsList,
    currentHPoolTokenContract,
    pendingOrdersLoadingState,
    executedOrdersSrc,
  } = useSelector((state: any) => ({
    pendingOrders: state.orderbook.pendingOrders,
    executedOrders: state.orderbook.executedOrders,
    account: state.wallet.account,
    currentHPoolTokenContract: state.contracts.currentHPoolTokenContract?.address,
    poolsList: state.tradingPair.hPoolTokensList || [],
    pendingOrdersLoadingState: state.orderbook.loadingsState.pendingOrders,
    executedOrdersSrc: state.orderbook.executedOrdersSrc,
  }))
  const [sortProp, setSortProp] = useState(cols?.[0].prop)
  const [isAsc, setIsAsc] = useState(false)

  // Tried adding this to use useCallback but it didnt seem to work
  // Maybe i implemented wrong
  const handleColClick = (col) => {
    setSortProp(col.prop)

    if (sortProp === col.prop) {
      setIsAsc(!isAsc)
    } else setIsAsc(true)
  }
  const getPoolContractAddress = (pool) => getValueByMultiProps(pool, ['contract_address', 'address'])

  const findPoolByAddress = (address: string) =>
    poolsList.find((pool: any) => isMatched(getPoolContractAddress(pool), address))

  const onlyUsersTxsWithAmount = (tx) => (isMatched(tx.maker, account) || isMatched(tx.taker, account)) && tx.total

  const getOrderTotalAmt = (order) => {
    const initAmt = +order.total
    const deductedUsingCompletedOrders = initAmt - (executedOrdersSrc?.[+order.id] || 0)

    return deductedUsingCompletedOrders
  }

  const processExecutedOrders = (order) => {
    const { transaction_hash: txHash, coinAddress, taker, side } = order ?? {}

    const matchedPool = findPoolByAddress(coinAddress) ?? {}

    const totalAfterDeductingCompleteOrders = getOrderTotalAmt(order)

    // at executed orders the order type will be according to the last activity happened
    // => I'm the maker with (Buy) another one is taker (Sell) completed order will be (Sell)
    // in other words if ur not the taker invert the order type
    // Note: this rule does not apply to OpenOrders cuz it's one sided

    const { symbol: coin, hpool_token_icon: coinImage } = matchedPool

    return {
      ...order,
      coinImage,
      coin,
      txHash,
      completed: true,
      total: totalAfterDeductingCompleteOrders,
      originalAmt: order.total,
    }
  }

  const formattedPendingOrders = useMemo(
    () => pendingOrders?.filter(onlyUsersTxsWithAmount).map(processExecutedOrders) ?? [],
    [pendingOrders, poolsList?.length, account, executedOrdersSrc, executedOrdersSrc],
  )

  const filterConditions: ICondition[] = [
    {
      filterAt: filters.onlyBuy && !filters.onlySell,
      filterFunc: (item) => item.side === 'buy',
    },
    {
      filterAt: filters.onlySell && !filters.onlyBuy,
      filterFunc: (item) => item.side === 'sell' && !filters.onlySell,
    },
    {
      filterAt: filters.hiddenPairs,
      filterFunc: (item) => isMatched(item.coinAddress, currentHPoolTokenContract),
    },
    {
      filterAt: true,
      filterFunc: (item) => item.total > 0,
    },
    {
      filterAt: true,
      filterFunc: (item) => isAvailablePool(item.coinAddress),
    },
  ]

  const filteredRecords = useMemo(
    () => multiFilterArray(formattedPendingOrders, filterConditions),
    [filters, formattedPendingOrders],
  )

  const sortedDataSrc = useMemo(
    () =>
      arraySort(filteredRecords, sortProp, {
        isAsc,
        isString: typeof filteredRecords?.[0]?.[sortProp] === 'string',
      }),
    [filteredRecords, filters, sortProp, isAsc],
  )

  const hasRecords = filteredRecords?.length && poolsList?.length

  return (
    <S.Wrapper>
      <S.Table>
        <ShowAt at={hasRecords}>
          <S.Thead>
            <S.Tr>
              {cols?.map((col) => (
                <S.Th key={col.prop} onClick={() => handleColClick(col)}>
                  <S.Title>{col.title}</S.Title>
                  <S.IconWrapper>
                    <Icon name="ArrowTop" style={{ transform: col.prop === sortProp && isAsc && 'rotate(180deg)' }} />
                  </S.IconWrapper>
                </S.Th>
              ))}
            </S.Tr>
          </S.Thead>
        </ShowAt>
        <SkeletonWrapper marginTop="0.5rem" count={3} loading={pendingOrdersLoadingState} minHeight="3.5vh">
          {hasRecords ? (
            <S.Tbody>
              {sortedDataSrc.map((order, index) => (
                <OpenOrder
                  key={order.ts}
                  order={order}
                  index={index}
                  loading={order.loading}
                  findPoolByAddress={findPoolByAddress}
                />
              ))}
            </S.Tbody>
          ) : (
            <S.EmptyDataWrapper>You do not have any open orders yet.</S.EmptyDataWrapper>
          )}
        </SkeletonWrapper>
      </S.Table>
    </S.Wrapper>
  )
}

const mapStateToProps = (state) => ({
  currentHPoolTokenName: state.tradingPair.currentHPoolToken?.name,
  currentHPoolTokenImage: state.tradingPair.currentHPoolToken?.image,
})

export default connect(mapStateToProps, undefined)(OpenOrderTable)
