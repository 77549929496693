import clsx from 'clsx'
import './Tooltip.scss'

type Props = {
  children?: JSX.Element
  text?: string
  show?: boolean
  title?: string
  className?: string
}

export default function Tooltip({ children, text, show, title, className }: Props) {
  return (
    <div className={clsx('tooltip-wrapped', className)}>
      {show && (
        <div className="tooltip">
          <div className="tooltip-text-wrapper">
            {title && <span className="tooltip-title">{title}</span>}
            <span className="tooltip-text">{text}</span>
          </div>
        </div>
      )}
      {children}
    </div>
  )
}
