import configJson from '../config/config.json'

export const getConfigRequest = () => {
  const config = {
    method: 'GET',
    headers: { Authorization: `Bearer GUEST` },
  }

  const params = {
    chain_id: window.chainId || configJson.network,
    is_vpool: true,
  }

  const stringParams = new URLSearchParams(params).toString()

  return fetch(`${configJson.apiPath}/config?${stringParams}`, config)
    .then((res) => res.json())
    .then((res) => res)
}
