import React from 'react'
import clsx from 'clsx'
import './ThreeDotsLoader.scss'

export type ThreeDotsLoaderProps = {
  big?: boolean
  style?: object
}

const ThreeDotsLoader = ({ big = false, style }: ThreeDotsLoaderProps) => (
  <div className={clsx('three-dots-loader-wrapper', { big })} style={style}>
    <div className="c-three-dots-loader" />
  </div>
)

export default ThreeDotsLoader
