import clsx from 'clsx'
import React from 'react'
import ShowAt from '../ShowAt/ShowAt'
import './Skeleton.scss'

export const skeletonBg = 'rgba(255, 255, 255, 0.097)'

export interface ISkeleton extends React.CSSProperties {
  className?: string
  children?: JSX.Element | JSX.Element[]
  mockChildren?: JSX.Element | JSX.Element[]
  loading: boolean
  noAnimation?: boolean
  hideAt?: boolean
  style?: React.CSSProperties
}

export default function Skeleton({
  className,
  hideAt,
  children,
  loading,
  mockChildren,
  minHeight,
  minWidth,
  noAnimation,
  ...rest
}: ISkeleton) {
  const baseCLassName = 'skeleton w-100'
  const mergedClassName = clsx({ [baseCLassName]: loading && !noAnimation }, className)
  const styleObj = (loading && { minHeight, minWidth, ...rest }) || {}
  return (
    <>
      {loading ? (
        <ShowAt at={!hideAt}>
          <div className={mergedClassName} style={{ ...styleObj, ...rest?.style }}>
            {mockChildren}
          </div>
        </ShowAt>
      ) : (
        children
      )}
    </>
  )
}
