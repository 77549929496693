export const getSearchParam = (id: string) => {
  const urlSearch = new URLSearchParams(document.location.search)
  return urlSearch.get(id)
}

export const fromObjToParam = (obj: any = {}) => {
  let result = ''
  Object.entries(obj).forEach(([key, value], index) => {
    const hasAnd = (!!index && '&') || ''
    result += `${hasAnd}${key}=${value}`
  })
  return (!!result && ['?', result].join('')) || ''
}

export const setParam = (obj: any) => {
  document.location.search = fromObjToParam(obj)
}
