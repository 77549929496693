import React, { useMemo } from 'react'
import ShowAt from '../ShowAt/ShowAt'
import Skeleton, { ISkeleton } from '../Skeleton/Skeleton'

export interface ISkeletonWrapper extends ISkeleton {
  loading: boolean
  children?: JSX.Element | JSX.Element[]
  count?: number
  wrapperClass?: string
  minHeight?: string
}

export default function SkeletonWrapper({ loading, children, count = 3, wrapperClass, ...rest }: ISkeletonWrapper) {
  const mockArray = useMemo(() => new Array(count).fill({}).map(() => ({ id: Math.random() })), [count])
  return (
    <>
      {loading ? (
        <ShowAt at={loading}>
          <div className={wrapperClass}>
            {mockArray.map((elem) => (
              <Skeleton key={elem.id} {...rest} loading />
            ))}
          </div>
        </ShowAt>
      ) : (
        children
      )}
    </>
  )
}
