import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setStableCoinInfo } from 'store/actions/walletActions'
import { getNetworkById } from 'utils/web3Service'
import config from '../config/config.json'

export const ETH_CHAINS = [1, 5]

export default function useCurrentStableCoin() {
  const { currentAppNetwork, currentStableCoin } = useSelector((state: any) => ({
    currentAppNetwork: state.wallet.network,
    currentStableCoin: state.wallet.stableCoinInfo,
  }))

  return [currentStableCoin, currentAppNetwork]
}
