import styled from 'styled-components'

export const Wrapper = styled.div``

// Table Styles
export const Table = styled.table`
  width: 100%;
  text-align: left;
  border-radius: 15px;
  border-collapse: collapse;
`
export const Thead = styled.thead`
  font-size: 1.2rem;
  color: #939496;

  @media only screen and (max-width: 600px) {
    display: none;
  }

  tr {
    display: grid;
    grid-template-columns: 1.5fr 2fr repeat(4, 1fr);
    padding: 2.5rem 0 1.5rem 0;
    border-radius: 15px 15px 0 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
`
export const Tbody = styled.tbody`
  color: white;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
  display: block;
  tr {
    display: grid;
    grid-template-columns: 1.5fr 2fr repeat(4, 1fr);
    align-items: center;

    @media only screen and (max-width: 600px) {
      grid-template-columns: 2fr 2fr;
      grid-column-gap: 1rem;
      grid-row-gap: 2rem;
      font-size: 1.6rem;
      align-items: flex-start;

      &:first-child {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
      }

      &:last-child {
        display: none;
      }

      &:nth-last-child(2) {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
  }
`

export const Th = styled.th`
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
`

export const Title = styled.div`
  cursor: pointer;
`

export const Tr = styled.tr``

export const EmptyDataWrapper = styled.div`
  text-align: center;
  margin: 2rem;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 1.9rem;
  width: 1.9rem;
`
