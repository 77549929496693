import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import contractReducer from './reducers/contractsReducer'
import uiReducer from './reducers/ui/uiReducer'
import balanceReducer from './reducers/balancesReducer'
import walletReducer from './reducers/wallet/walletReducer'
import orderbookReducer from './reducers/orderbookReducer'
import inputReducer from './reducers/inputReducer'
import tradingPairReducer from './reducers/tradingPairReducer'
import generalReducer from './reducers/generalReducer'
import transactionReducer from './reducers/transactionReducer'
import { IWalletReducer } from './reducers/wallet/initialState'
import { IUiReducer } from './reducers/ui/initialState'
import ethStakingReducer from './reducers/ethStakingReducer'

export interface IStoreState {
  ui: IUiReducer
  wallet: IWalletReducer
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const store = createStore(
    combineReducers({
      contracts: contractReducer,
      balances: balanceReducer,
      ui: uiReducer,
      wallet: walletReducer,
      orderbook: orderbookReducer,
      input: inputReducer,
      tradingPair: tradingPairReducer,
      transactions: transactionReducer,
      general: generalReducer,
      ethStaking: ethStakingReducer
    }),
    composeWithDevTools(applyMiddleware(thunk)),
  )

  return store
}
