import { SET_HETH_LATEST_STAT } from "store/actionTypes/ethStakingActionTypes";
import { initialGeneralState } from "store/actions/ethStaklng/initalState";

export default (state = initialGeneralState, action) => {
  switch (action?.type) {
    case SET_HETH_LATEST_STAT: {
      return {
        ...state,
        hethInfo: action.payload,
      }
    }
    default:
      return state;
  }
}