import React from 'react'
import { RouteProps, Route } from 'react-router-dom'
import config from 'config/config.json'

export interface Link {
  id?: string
  text?: string
  to?: string
}

const { hiddenPaths = [] } = config as any

export const isHiddenRoute = (link: string) => (hiddenPaths?.length ? hiddenPaths.includes(link) : false)

export default function ProtectedRoute({ path, ...rest }: RouteProps) {
  const hiddenRoute = isHiddenRoute(path)

  return hiddenRoute ? <Route component={<></>} /> : <Route path={path} {...rest} />
}
