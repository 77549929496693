import styled from 'styled-components'
import { generateMedia } from 'styled-media-query'

const customMedia = generateMedia({
  custom: '1265px',
  custom2: '1000px',
  custom3: '650px',
  custom4: '450px',
})
export const Wrapper = styled.section`
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const ContainerPair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;
  background: #926CE3;
  border-radius: 24px;
  padding: 3rem 1.8rem;
  min-width: 22.4rem;
  width: fit-content;
  min-height: 8.1rem;
  ${customMedia.lessThan('custom')`
    margin-right: unset;
    margin-bottom: 2rem;
    padding: .75rem 2rem;
    align-self: center;
    `}
`
export const ContainerInfo = styled.div`
  background: #1E1628;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 0.85rem 0;
  width: -webkit-fill-available;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;

  ${customMedia.lessThan('custom3')`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  `}

  ${customMedia.lessThan('custom4')`
    grid-template-columns: 1fr;
    text-align: center;
  `}
`
export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${customMedia.lessThan('custom')`
    flex-direction: column;
`}
`
export const WrapperLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

export const AccountAddress = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0 1rem;
`

export const VolumeHigh = styled.div`
  p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #16c79a;
  }
`

export const VolumeLow = styled.div`
  p {
    font-size: 1.5rem;
    font-weight: 500;
    color: #ff0087;
  }
`
export const WrapperVolume = styled.div`
  div {
    display: flex;
    flex-direction: row;
  }
`

export const Span = styled.span`
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.5;
  margin-right: 1rem;
`

// Dropdown
export const WrapperDropdownContent = styled.a`
  padding: 0.5rem;

  & :not(:last-child) {
    margin-bottom: 1rem;
  }
  & :hover {
    opacity: 0.7;
  }
`

export const DropdownTitle = styled.p`
  font-size: 1.3rem;
  font-weight: 600;
`

export const DropdownDescription = styled.p`
  font-size: 1.2rem;
  opacity: 0.6;
`
