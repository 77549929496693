import * as React from 'react'
import DropdownItem from 'components/general/DropdownItem'
import * as S from '../styles'

const PairDropdownContent = ({ pairs, handleChange, discount }) => (
  <>
    {pairs.length > 0 ? (
      pairs.map(({ image, name, address, id, chain_id: chainId }, index) => (
        <DropdownItem
          key={id}
          index={index}
          title={name}
          searchTerm={address}
          handleAction={handleChange}
          image={image}
          chainId={chainId}
          discount={discount}
        />
      ))
    ) : (
      <S.EmptySearch>Nothing to show</S.EmptySearch>
    )}
  </>
)

export default PairDropdownContent
