import React from 'react'
import { Flex } from 'styles/layouts'
import { newDisplayFloats } from 'services/format'
import Skeleton from '../Skeleton/Skeleton'
import * as S from './styles'
import ShowAt from '../ShowAt/ShowAt'

export type NavbarItemPops = {
  label?: string
  info?: string | number
  type?: string
  infoStyle?: object
  loading?: boolean
  tag?: React.ReactNode
  ethValue?: string | number
  isEth?: boolean
}
const NavbarItem = ({ label, info, infoStyle, loading, tag, ethValue, isEth }: NavbarItemPops) => (
  <S.Wrapper>
    <Skeleton loading={loading} minHeight="2rem" minWidth="100%">
      <Flex gap="0.3rem">
        <S.Label>{label}</S.Label>
      </Flex>
      <Flex alignItems="center" gap="1.8rem">
        <S.Info style={infoStyle}>{info}</S.Info>
        {tag}
      </Flex>
      <ShowAt at={isEth}>
        <S.EthValue>{newDisplayFloats(+ethValue, 5) || 0} ETH</S.EthValue>
      </ShowAt>
    </Skeleton>
  </S.Wrapper>
)

export default NavbarItem
