import ERC20 from 'utils/contracts/ERC20.json'
import { createContract, getValueByMultiProps, newContractReader, newGetContractAddress } from 'utils/contractReader'
import * as contractActionTypes from '../actionTypes/contractActionTypes'
import { createNotification } from './uiActions'
import { setBalance, unsetBalances } from './balanceActions'
import { loadContracts } from '../../utils/contractsRegistryService'

export const connectToContracts = () => async (dispatch, getState) => {
  try {
    const contracts = await loadContracts()

    dispatch({
      type: contractActionTypes.SET_CONTRACTS,
      payload: contracts,
    })
    dispatch(setBalance(contracts.BUSD, 'busdBalance'))
  } catch (error) {
    dispatch(createNotification('error', 'Something went wrong loading contract', 4000))
    console.warn(error)
  }
}

export const connectToCurrentHPoolTokenContract = (token) => async (dispatch) => {
  try {
    const tokenAddress = getValueByMultiProps(token, ['contract_address', 'address'])

    const contract = new window._web3.eth.Contract(ERC20.abi, tokenAddress)
    const payload = {
      ...contract.methods,
      address: tokenAddress,
    }
    dispatch({
      type: contractActionTypes.SET_CURRENT_HPOOL_TOKEN_CONTRACT,
      payload,
    })
    dispatch(setBalance(payload, 'currentHPoolTokenBalance'))
  } catch (error) {
    dispatch(createNotification('error', error.message, 4000))
    console.warn(error)
  }
}

export const resetCurrentPoolTokenContract = () => (dispatch) => {
  dispatch({ type: contractActionTypes.RESET_CURRENT_HPOOL_TOKEN_CONTRACT })
}

export const disconnectToContracts = () => async (dispatch) => {
  dispatch({
    type: contractActionTypes.REMOVE_CONTRACTS,
  })
  dispatch(unsetBalances())
}
